import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Form, Divider } from "antd";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import UseForm from "../../hooks/useForm";
import EditAgencyTeamFormSection from "../../components/agentTeam/edit/EditAgencyTeamFormSection";

export default function AgentTeamEditPage({ user }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    form: editAgentTeamFrom,
    formHelpers: editAgentTeamFormHelpers,
    formFields: editAgentTeamFormFields,
  } = UseForm({
    NAME: "name",
    LEADER: "leader",
    STATUS: "status",
  });

  const {
    form: oldEditAgentTeamFrom,
    formFields: oldEditAgentTeamFromFields,
  } = UseForm({
    NAME: "name",
    LEADER: "leader",
    STATUS: "status",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("AgentTeamEditPage mounted...");
    setAgentTeamFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startLoading = () => {
    setLoading(true);
  }

  const stopLoading = () => {
    setLoading(false);
  }

  const setAgentTeamFormData = async () => {
    startLoading();
    api.agentTeams
      .getAgentTeam(id)
      .then(response => {
        const data = response.data;

        editAgentTeamFrom.setFieldValue(editAgentTeamFormFields.NAME, data.name);
        editAgentTeamFrom.setFieldValue(editAgentTeamFormFields.LEADER, data.leader.id);
        editAgentTeamFrom.setFieldValue(editAgentTeamFormFields.STATUS, data.status);

        oldEditAgentTeamFrom.setFieldValue(oldEditAgentTeamFromFields.NAME, data.name);
        oldEditAgentTeamFrom.setFieldValue(oldEditAgentTeamFromFields.LEADER, data.leader.id);
        oldEditAgentTeamFrom.setFieldValue(oldEditAgentTeamFromFields.STATUS, data.status);

        stopLoading();
      })
      .catch(error => {
        stopLoading();
        console.log("some errors while receiving agent team data", error);
      });
  }

  const submit = async (id, payload) => {
    startLoading();
    api.agentTeams
      .updateAgentTeam(id, payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano zespół "${payload.name}"`
        );
        stopLoading();
        navigate(`/agent-teams/${id}`);
      })
      .catch((error) => {
        stopLoading();

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        if (error?.response.status === 400) {
          editAgentTeamFrom.setApiErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas aktualizacji zespołu "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas aktualizacji zespołu "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas aktualizacji zespołu "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  return (
    <>
      <h1 id={TRANSLATIONS.AGENT_TEAM_EDIT__TITLE.key}>
        {translate(TRANSLATIONS.AGENT_TEAM_EDIT__TITLE.key)}
      </h1>

      <Divider />

      <Form
        id="agent_team_edit_form"
        name="agent_team_edit_form"
        form={editAgentTeamFrom}
        autoComplete="off"
        layout="vertical"
        preserve={false}
        disabled={+loading}
        onFinish={(values) => submit(id, {
          name: editAgentTeamFrom.getFieldValue(editAgentTeamFormFields.NAME).trim(),
          status: editAgentTeamFrom.getFieldValue(editAgentTeamFormFields.STATUS),
          leader: editAgentTeamFrom.getFieldValue(editAgentTeamFormFields.LEADER),
        })}
      >
        <EditAgencyTeamFormSection
          form={editAgentTeamFrom}
          formHelpers={editAgentTeamFormHelpers}
          formFields={editAgentTeamFormFields}
        />

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            id={TRANSLATIONS.AGENT_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL.key}
            type="success"
            htmlType="submit"
            size="large"
            disabled={
              JSON.stringify({
                name: editAgentTeamFrom.getFieldValue(editAgentTeamFormFields.NAME),
                leader: editAgentTeamFrom.getFieldValue(editAgentTeamFormFields.LEADER),
                status: editAgentTeamFrom.getFieldValue(editAgentTeamFormFields.STATUS),
              })
              ===
              JSON.stringify({
                name: oldEditAgentTeamFrom.getFieldValue(oldEditAgentTeamFromFields.NAME),
                leader: oldEditAgentTeamFrom.getFieldValue(oldEditAgentTeamFromFields.LEADER),
                status: oldEditAgentTeamFrom.getFieldValue(oldEditAgentTeamFromFields.STATUS),
              })}
          >
            {translate(TRANSLATIONS.AGENT_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL.key)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
