import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, Divider } from "antd";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

export default function InsurerCreatePage({ user }) {
  const navigate = useNavigate();
  const [apiValidationErrors, setApiValidationErrors] = useState({});
  const [guiValidationErrors, setGuiValidationErrors] = useState({
    key: [],
    name: [],
    nip: [],
    regon: [],
  });

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const nameInputRef = useRef(null);
  const [insurer, setInsurer] = useState({
    key: "",
    name: "",
    nip: "",
    regon: "",
  });

  useEffect(() => {
    console.log("InsurerCreatePage mounted...");
    nameInputRef.current.focus();
  }, []);

  const submit = async (payload) => {
    setLoading(true);
    api.insurers
      .createInsurer(payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano ubezpieczyciela "${payload.name}"`
        );
        setLoading(false);
        navigate(`/admin/insurers/`);
      })
      .catch((error) => {
        setLoading(false);

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        // BUG: nie wyświetla notyfikacji po przekierowaniu na 500 albo 403
        if (error?.response.status === 400) {
          setApiValidationErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania ubezpieczyciela "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas dodawania ubezpieczyciela "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania ubezpieczyciela "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        },
      },
    });
  };

  const setInsurerKey = (value) => {
    setInsurer((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.key = value;
      return newState;
    });
  };
  const setInsurerName = (value) => {
    setInsurer((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.name = value;
      return newState;
    });
  };
  const setInsurerRegon = (value) => {
    setInsurer((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.regon = value;
      return newState;
    });
  };
  const setInsurerNip = (value) => {
    setInsurer((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.nip = value;
      return newState;
    });
  };

  return (
    <>
      {/* <h1 id={TRANSLATIONS.AGENT_TEAM_CREATE__TITLE.key}>
      
        {translate(TRANSLATIONS.AGENT_TEAM_CREATE__TITLE.key)}
      </h1> */}
      <h1>{"Dodaj ubezpieczyciela"}</h1>

      <Divider />

      <Form
        form={form}
        id="insurer-create-form"
        name="insurer-create-form"
        autoComplete="off"
        layout="vertical"
        preserve={false}
        disabled={+loading}
        onFinish={(values) =>
          submit({
            key: insurer.key,
            name: insurer.name,
            nip: insurer.nip,
            regon: insurer.regon,
          })
        }
      >
        {/* NOTE: .name */}
        <Form.Item
          id="name"
          name="name"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Nazwa"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurer.name) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.name.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.name = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.name?.length > 0 ||
            guiValidationErrors?.name?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.name?.length > 0
              ? apiValidationErrors.name.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            ref={nameInputRef}
            id="name"
            name="name"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerName(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.name = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        {/* NOTE: .key */}
        <Form.Item
          id="key"
          name="key"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Klucz"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurer.key) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.key.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.key = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.key?.length > 0 ||
            guiValidationErrors?.key?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.key?.length > 0
              ? apiValidationErrors.key.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            id="key"
            name="key"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerKey(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.key = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        {/* NOTE: .nip */}
        <Form.Item
          id="nip"
          name="nip"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"NIP"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurer.nip) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.nip.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.nip = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.nip?.length > 0 ||
            guiValidationErrors?.nip?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.nip?.length > 0
              ? apiValidationErrors.nip.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            id="nip"
            name="nip"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerNip(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.nip = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        {/* NOTE: .regon */}
        <Form.Item
          id="regon"
          name="regon"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"REGON"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurer.regon) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.regon.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.regon = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.regon?.length > 0 ||
            guiValidationErrors?.regon?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.regon?.length > 0
              ? apiValidationErrors.regon.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            id="regon"
            name="regon"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerRegon(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.regon = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key}
            type="success"
            htmlType="submit"
            size="large"
          >
            {translate(
              TRANSLATIONS.AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key
            )}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
