import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, Divider, Select } from "antd";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

export default function InsurerDepotCreatePage({ user }) {
  const navigate = useNavigate();
  const [apiValidationErrors, setApiValidationErrors] = useState({});
  const [guiValidationErrors, setGuiValidationErrors] = useState({
    key: [],
    name: [],
    insurer: [],
    address: [],
    city: [],
    postal_code: [],
    contact_person_1: [],
    contact_person_2: [],
    contact_person_3: [],
    contact_person_4: [],
    contact_person_5: [],
  });

  const [insurers, setInsurers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const nameInputRef = useRef(null);
  const [insurerDepot, setInsurerDepot] = useState({
    key: "",
    name: "",
    insurer: null,
    address: "",
    city: "",
    postal_code: "",
    contact_person_1: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    },
    contact_person_2: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    },
    contact_person_3: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    },
    contact_person_4: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    },
    contact_person_5: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    },
  });

  useEffect(() => {
    console.log("InsurerDepotCreatePage mounted...");
    api.insurers
      .getInsurers({
        page: 1,
        size: 100,
        order_by: "name",
      })
      .then((response) => {
        const data = response.data;
        const res = data.results.map((result) => ({
          value: result.id,
          label: result.name,
        }));
        setInsurers(res);
      });
    nameInputRef.current.focus();
  }, []);

  const submit = async (payload) => {
    setLoading(true);
    api.insurers
      .createInsurerDepot(payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano oddział ubezpieczyciela "${payload.name}"`
        );
        setLoading(false);
        navigate(`/admin/insurers/`);
      })
      .catch((error) => {
        setLoading(false);

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        // BUG: nie wyświetla notyfikacji po przekierowaniu na 500 albo 403
        if (error?.response.status === 400) {
          setApiValidationErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania oddziału ubezpieczyciela "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas dodawania oodziału ubezpieczyciela "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania oddziału ubezpieczyciela "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        },
      },
    });
  };

  const setInsurerDepotKey = (value) => {
    setInsurerDepot((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.key = value;
      return newState;
    });
  };
  const setInsurerDepotName = (value) => {
    setInsurerDepot((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.name = value;
      return newState;
    });
  };
  const setInsurerDepotInsurer = (value) => {
    setInsurerDepot((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.insurer = value;
      return newState;
    });
  };
  const setInsurerDepotAddress = (value) => {
    setInsurerDepot((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.address = value;
      return newState;
    });
  };
  const setInsurerDepotCity = (value) => {
    setInsurerDepot((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.city = value;
      return newState;
    });
  };
  const setInsurerDepotPostalCode = (value) => {
    setInsurerDepot((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.postal_code = value;
      return newState;
    });
  };

  return (
    <>
      {/* <h1 id={TRANSLATIONS.AGENT_TEAM_CREATE__TITLE.key}>
      
        {translate(TRANSLATIONS.AGENT_TEAM_CREATE__TITLE.key)}
      </h1> */}
      <h1>{"Dodaj oddział ubezpieczyciela"}</h1>

      <Divider />

      <Form
        form={form}
        id="insurer-depot-create-form"
        name="insurer-depot-create-form"
        autoComplete="off"
        layout="vertical"
        preserve={false}
        disabled={+loading}
        onFinish={(values) =>
          submit({
            key: insurerDepot.key,
            name: insurerDepot.name,
            insurer: insurerDepot.insurer,
            address: insurerDepot.address,
            city: insurerDepot.city,
            postal_code: insurerDepot.postal_code,
            // TODO: impl
            contact_person_1: null,
            contact_person_2: null,
            contact_person_3: null,
            contact_person_4: null,
            contact_person_5: null,
          })
        }
      >
        {/* NOTE: .insurer */}
        <Form.Item
          id="insurer"
          name="insurer"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Ubezpieczyciel"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurerDepot.insurer) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.insurer.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.insurer = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.insurer?.length > 0 ||
              guiValidationErrors?.insurer?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.insurer?.length > 0
              ? apiValidationErrors.insurer.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
        >
          <Select
            id="insurer"
            placeholder={"Ubezpieczyciel"}
            defaultValue={insurerDepot.insurer}
            style={{ width: 500 }}
            value={insurerDepot.insurer}
            onChange={(insurerId) => {
              setInsurerDepotInsurer(insurerId);
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.insurer = [];
                return newState;
              });
            }}
            allowClear
            options={insurers}
            size="large"
          />
        </Form.Item>

        {/* NOTE: .name */}
        <Form.Item
          id="name"
          name="name"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Nazwa"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurerDepot.name) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.name.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.name = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.name?.length > 0 ||
              guiValidationErrors?.name?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.name?.length > 0
              ? apiValidationErrors.name.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            ref={nameInputRef}
            id="name"
            name="name"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerDepotName(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.name = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        {/* NOTE: .key */}
        <Form.Item
          id="key"
          name="key"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Klucz"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurerDepot.key) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.key.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.key = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.key?.length > 0 ||
              guiValidationErrors?.key?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.key?.length > 0
              ? apiValidationErrors.key.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            id="key"
            name="key"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerDepotKey(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.key = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        {/* NOTE: .address */}
        <Form.Item
          id="address"
          name="address"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Adres"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurerDepot.address) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.address.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.address = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.address?.length > 0 ||
              guiValidationErrors?.address?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.address?.length > 0
              ? apiValidationErrors.address.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            id="address"
            name="address"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerDepotAddress(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.address = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        {/* NOTE: .city */}
        <Form.Item
          id="city"
          name="city"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Miasto"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurerDepot.city) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.city.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.city = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.city?.length > 0 ||
              guiValidationErrors?.city?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.city?.length > 0
              ? apiValidationErrors.city.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            id="city"
            name="city"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerDepotCity(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.city = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        {/* NOTE: .postal_code */}
        <Form.Item
          id="postal_code"
          name="postal_code"
          label={
            // <label
            //   id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
            //   style={{ fontWeight: "bold", fontSize: 20 }}
            // >
            //   {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
            // </label>
            <label>{"Kod pocztowy"}</label>
          }
          required
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!insurerDepot.postal_code) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.postal_code.push("Pole wymagane");
                    return newState;
                  });
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.postal_code = [];
                    return newState;
                  });
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.postal_code?.length > 0 ||
              guiValidationErrors?.postal_code?.length > 0
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.postal_code?.length > 0
              ? apiValidationErrors.postal_code.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            id="postal_code"
            name="postal_code"
            size="large"
            allowClear
            onChange={(e) => {
              setInsurerDepotPostalCode(e.currentTarget.value.trim());
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.postal_code = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        <h2>TODO: contact person 1-5 (1,2 wymagane)</h2>
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key}
            type="success"
            htmlType="submit"
            size="large"
          >
            {translate(
              TRANSLATIONS.AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key
            )}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
