import { Row, Col, Form, Select } from "antd";
import { MaskedInput } from "antd-mask-input";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {function} props.formHelpers.validateAgainstRegex
 * @param {object} props.formFields
 * @param {string} props.formFields.RISK_NAME
 * @param {string} props.formFields.CAR_TYPE
 * @param {string} props.formFields.DATA_TYPE
 * @param {string} props.formFields.DATA_VALUE
 * @param {object} props.config
 * @param {object} props.config.RISKS_AND_CLAUSES_DATA_TYPES
 * @returns {JSX.Element}
 * */
export default function GeneralContractRisksFormSection({
  form,
  formHelpers,
  formFields,
  config,
}) {
  // TODO: do helpera wyciągnąć, można wrzucić do helpera z max lengthem
  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form.Item id="general_contract_risks">
            <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={6}>
          <Form.Item
            id={formFields.RISK_NAME}
            name={formFields.RISK_NAME}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Ryzyko
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.RISK_NAME,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.RISK_NAME) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.RISK_NAME)
                ? formHelpers.getFieldErrors(formFields.RISK_NAME).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              placeholder="Wybierz ryzyko"
              options={[]}
              filterOption={handleFilterOption}
              onChange={(value) => {
                form.setFieldValue(formFields.RISK_NAME, value);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={6}>
          <Form.Item
            id={formFields.CAR_TYPE}
            name={formFields.CAR_TYPE}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Rodzaj pojazdu
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.CAR_TYPE,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.CAR_TYPE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.CAR_TYPE)
                ? formHelpers.getFieldErrors(formFields.CAR_TYPE).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              placeholder="Wybierz typ pojazdu"
              options={[]}
              filterOption={handleFilterOption}
              onChange={(value) => {
                form.setFieldValue(formFields.CAR_TYPE, value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={6}>
          <Form.Item
            id={formFields.DATA_TYPE}
            name={formFields.DATA_TYPE}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Typ
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.DATA_TYPE,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.DATA_TYPE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.DATA_TYPE)
                ? formHelpers.getFieldErrors(formFields.DATA_TYPE).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              placeholder="Wybierz typ"
              options={config.RISKS_AND_CLAUSES_DATA_TYPES}
              filterOption={handleFilterOption}
              onChange={(value) => {
                form.setFieldValue(formFields.DATA_TYPE, value);
                form.setFieldValue(formFields.DATA_VALUE, null);
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={6}>
          <Form.Item
            id={formFields.DATA_VALUE}
            name={formFields.DATA_VALUE}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Wartość
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.DATA_VALUE,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.DATA_VALUE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.DATA_VALUE)
                ? formHelpers.getFieldErrors(formFields.DATA_VALUE).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.DATA_VALUE}
              name={formFields.DATA_VALUE}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.DATA_VALUE)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: Number,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: ".",
                mapToRadix: [","],
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.DATA_VALUE);
                form.setFieldsValue({ [formFields.DATA_VALUE]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
}
