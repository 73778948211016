import { useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../../infra/api";
import { dispatch } from "use-bus";

import {
  Button,
  Space,
  Form,
  Input,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import UseForm from "../../../hooks/useForm";

/**
 * @param {object} props.config
 * @param {object} props.config.createContactPersonFormActive
 * @param {object} props.config.setCreateContactPersonFormActive
 * @param {object} props.config.contactPersonElementNum
 * @param {object} props.config.setContactPersonElementNum
 * @returns {JSX.Element}
 * */
export default function CreateContactPersonSection({ config }) {
  const {
    form,
    formHelpers,
    formFields,
  } = UseForm({
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    EMAIL: "email",
    PHONE: "phone",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const deactiveForm = () => {
    config.setCreateContactPersonFormActive(false);
    config.setContactPersonElementNum(null);
  };

  const resetFormFields = () => {
    form.resetFields();
    formHelpers.setApiErrors({});
    formHelpers.setGuiErrors({});
  };

  const submit = async payload => {
    startLoading();
    api.personalDatas
      .createPersonalData(payload)
      .then(response => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano osobę do kontatku "${payload.first_name} ${payload.last_name}"`
        );

        dispatch({
          type: "CUSTOMER_EMPLOYEE_CONTACT_PERSON_CREATED",
          payload: {
            num: config.contactPersonElementNum,
            data: response.data
          }
        });

        resetFormFields();
        deactiveForm();
        stopLoading();
      })
      .catch(error => {
        stopLoading();
        if (error?.response.status === 400) {
          formHelpers.setApiErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwera",
            `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania osoby do kontatku "${payload.first_name} ${payload.last_name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  return (
    <Form
      id="create_contact_person_form"
      name="create_contact_person_form"
      form={form}
      autoComplete="off"
      layout="vertical"
      scrollToFirstError
      onValuesChange={(changedField) => {
        const field = Object.keys(changedField)[0].toUpperCase();
        formHelpers.resetFieldApiError(formFields[field]);
      }}
      onFinish={(values) => {
        const payload = {
          first_name: values[formFields.FIRST_NAME],
          last_name: values[formFields.LAST_NAME],
          email: values[formFields.EMAIL],
          phone: values[formFields.PHONE],
        };
        submit(payload);
      }}
    >
      <Form.Item
        id={formFields.FIRST_NAME}
        name={formFields.FIRST_NAME}
        defaultValue={null}
        label={
          <label
            id={formFields.FIRST_NAME}
            style={{ fontWeight: "bold" }}
          >
            Imię
          </label>
        }
        required
        rules={[
          {
            validator: (_, value) => {
              return formHelpers.validateRequiredGuiField(
                formFields.FIRST_NAME,
                value,
                "Pole wymagane"
              );
            }
          },
          {
            validator: (_, value) => {
              if (value) {
                return formHelpers.validateAgainstRegex(
                  /^.{0,50}$/,
                  formFields.FIRST_NAME,
                  value,
                  "Imię może zawierać maksymalnie 50 znaków"
                );
              }
              return Promise.resolve();
            }
          }
        ]}
        validateStatus={
          formHelpers.errorFields.includes(formFields.FIRST_NAME)
            ? "error"
            : null
        }
        help={
          formHelpers.errorFields.includes(formFields.FIRST_NAME)
            ? formHelpers.getFieldErrors(formFields.FIRST_NAME).join(", ")
            : null
        }
        normalize={(value) => value.trimStart()}
      >
        <Input
          id={formFields.FIRST_NAME}
          name={formFields.FIRST_NAME}
          size="large"
          allowClear
          disabled={!config.createContactPersonFormActive}
          loading={+loading}
          count={{
            show: true,
            max: 50,
          }}
          onChange={(e) => {
            const finalValue = e.target.value.length > 50 ? e.target.value.trim().slice(0, 50) : e.target.value;
            form.setFieldValue(formFields.FIRST_NAME, finalValue);
          }}
        />
      </Form.Item>

      <Form.Item
        id={formFields.LAST_NAME}
        name={formFields.LAST_NAME}
        defaultValue={null}
        label={
          <label
            id={formFields.LAST_NAME}
            style={{ fontWeight: "bold" }}
          >
            Nazwisko
          </label>
        }
        required
        rules={[
          {
            validator: (_, value) => {
              return formHelpers.validateRequiredGuiField(
                formFields.LAST_NAME,
                value,
                "Pole wymagane"
              );
            }
          },
          {
            validator: (_, value) => {
              if (value) {
                return formHelpers.validateAgainstRegex(
                  /^.{0,50}$/,
                  formFields.LAST_NAME,
                  value,
                  "Nazwisko może zawierać maksymalnie 50 znaków"
                );
              }
              return Promise.resolve();
            }
          }
        ]}
        validateStatus={
          formHelpers.errorFields.includes(formFields.LAST_NAME)
            ? "error"
            : null
        }
        help={
          formHelpers.errorFields.includes(formFields.LAST_NAME)
            ? formHelpers.getFieldErrors(formFields.LAST_NAME).join(", ")
            : null
        }
        normalize={(value) => value.trimStart()}
      >
        <Input
          id={formFields.LAST_NAME}
          name={formFields.LAST_NAME}
          size="large"
          allowClear
          disabled={!config.createContactPersonFormActive}
          loading={+loading}
          count={{
            show: true,
            max: 50,
          }}
          onChange={(e) => {
            const finalValue = e.target.value.length > 50 ? e.target.value.trim().slice(0, 50) : e.target.value;
            form.setFieldValue(formFields.LAST_NAME, finalValue);
          }}
        />
      </Form.Item>

      <Form.Item
        id={formFields.EMAIL}
        name={formFields.EMAIL}
        defaultValue={null}
        label={
          <label
            id={formFields.EMAIL}
            style={{ fontWeight: "bold" }}
          >
            E-mail
          </label>
        }
        required
        rules={[
          {
            validator: (_, value) => {
              return formHelpers.validateRequiredGuiField(
                formFields.EMAIL,
                value,
                "Pole wymagane"
              );
            }
          },
          {
            validator: (_, value) => {
              if (value) {
                return formHelpers.validateAgainstRegex(
                  /^[a-zA-Z\d._-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/g,
                  formFields.EMAIL,
                  value,
                  "Należy wprowadzić poprawny adres e-mail"
                );
              }
              return Promise.resolve();
            }
          }
        ]}
        validateStatus={
          formHelpers.errorFields.includes(formFields.EMAIL)
            ? "error"
            : null
        }
        help={
          formHelpers.errorFields.includes(formFields.EMAIL)
            ? formHelpers.getFieldErrors(formFields.EMAIL).join(", ")
            : null
        }
        normalize={(value) => value.trimStart()}
      >
        <Input
          id={formFields.EMAIL}
          name={formFields.EMAIL}
          size="large"
          allowClear
          disabled={!config.createContactPersonFormActive}
          loading={+loading}
          count={{
            show: true,
            max: 50,
          }}
          onChange={(e) => {
            const finalValue = e.target.value.length > 50 ? e.target.value.trim().slice(0, 50) : e.target.value;
            form.setFieldValue(formFields.EMAIL, finalValue);
          }}
        />
      </Form.Item>

      <Form.Item
        id={formFields.PHONE}
        name={formFields.PHONE}
        defaultValue={null}
        label={
          <label
            id={formFields.PHONE}
            style={{ fontWeight: "bold" }}
          >
            Telefon kontatowy
          </label>
        }
        rules={[
          {
            validator: (_, value) => {
              if (value) {
                const finalValue = value.replace(/\s/g, "");
                return formHelpers.validateAgainstRegex(
                  /^\d{9}$/g,
                  formFields.PHONE,
                  finalValue,
                  "Telefon musi być w formacie 000 000 000"
                );
              }
              formHelpers.resetFieldGuiError(formFields.PHONE);
              return Promise.resolve();
            },
          },
        ]}
        validateStatus={
          formHelpers.errorFields.includes(formFields.PHONE) ? "error" : null
        }
        help={
          formHelpers.errorFields.includes(formFields.PHONE)
            ? formHelpers.getFieldErrors(formFields.PHONE).join(", ")
            : null
        }
      >
        <MaskedInput
          id={formFields.PHONE}
          name={formFields.PHONE}
          allowClear
          disabled={!config.createContactPersonFormActive}
          loading={+loading}
          size="large"
          status={
            formHelpers.errorFields.includes(formFields.PHONE)
              ? "error"
              : null
          }
          count={{
            show: true,
            max: 11,
          }}
          maskOptions={{
            mask: "000 000 000",
            lazy: true,
            max: 11,
          }}
          onChange={(e) => {
            const finalValue = e.target.value.replace(/\s/g, "");
            form.setFieldValue(formFields.PHONE, finalValue);
          }}
        />
      </Form.Item >

      <Form.Item style={{ textAlign: "center" }}>
        <Space>
          <Button
            type="lightdark"
            ghost
            htmlType="button"
            size="large"
            disabled={!config.createContactPersonFormActive}
            loading={+loading}
            onClick={() => {
              stopLoading();
              resetFormFields();
              deactiveForm();
            }}
          >
            Anuluj
          </Button>

          <Button
            type="success"
            size="large"
            htmlType="submit"
            disabled={!config.createContactPersonFormActive}
            loading={+loading}
          >
            Utwórz
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
