import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Form, Input, Divider, Spin } from "antd";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import DebounceSelectPerson from "../../components/commons/DebounceSelectPerson";

export default function BrokerTeamEditPage({ user }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiValidationErrors, setApiValidationErrors] = useState({});
  const [guiValidationErrors, setGuiValidationErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [editBrokerTeamFrom] = Form.useForm();
  const [brokerTeamOld, setBrokerTeamOld] = useState(null);
  const [brokerTeam, setBrokerTeam] = useState(null);

  useEffect(() => {
    console.log("BrokerTeamEditPage mounted...");
    setBrokerTeamFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startLoading = () => {
    setLoading(true);
  }

  const stopLoading = () => {
    setLoading(false);
  }

  const setBrokerTeamFormData = async () => {
    startLoading();
    api.brokerTeams
      .getBrokerTeam(id)
      .then(response => {
        const data = response.data;
        const name = data.name;
        const status = data.status;
        const leader = data.leader;
        const leaderId = leader.id
        const leaderLabel = `${leader.first_name} ${leader.last_name} (${leader.email})`;

        setBrokerTeamNameOld(name);
        setBrokerTeamStatusOld(status);
        setBrokerTeamLeaderOld(leaderId, leaderLabel);
        setBrokerTeamName(name);

        setBrokerTeamStatus(status);
        setBrokerTeamLeader(leaderId, leaderLabel);
        setBrokerTeamFormValues(name, leaderId, leaderLabel);

        stopLoading();
      })
      .catch(error => {
        stopLoading();
        console.log("some errors while receiving broker team data", error);
      });
  }

  const submit = async (id, payload) => {
    startLoading();
    api.brokerTeams
      .updateBrokerTeam(id, payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano zespół "${payload.name}"`
        );
        stopLoading();
        navigate(`/broker-teams/${id}`);
      })
      .catch((error) => {
        stopLoading();

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        if (error?.response.status === 400) {
          setApiValidationErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas aktualizacji zespołu "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas aktualizacji zespołu "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas aktualizacji zespołu "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  const setBrokerTeamFormValues = (name, leaderId, leaderLabel) => {
    editBrokerTeamFrom.setFieldsValue({
      name: name,
      leader: {
        label: leaderLabel,
        value: leaderId,
      }
    });
  }

  const setBrokerTeamNameOld = (value) => {
    setBrokerTeamOld((oldState) => {
      console.log("oldstate", oldState);
      const newState = Object.assign({
        name: "",
        leader: {
          label: "",
          value: null,
        },
        status: "",
      }, oldState);
      newState.name = value;
      return newState;
    });
  };

  const setBrokerTeamLeaderOld = (id, name) => {
    setBrokerTeamOld((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.leader.label = name;
      newState.leader.value = id;
      return newState;
    });
  }

  const setBrokerTeamStatusOld = (value) => {
    setBrokerTeamOld((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.status = value;
      return newState;
    });
  };

  const setBrokerTeamName = (value) => {
    setBrokerTeam((oldState) => {
      console.log("oldstate", oldState);
      const newState = Object.assign({
        name: "",
        leader: {
          label: "",
          value: null,
        },
        status: "",
      }, oldState);
      newState.name = value;
      return newState;
    });
  };

  const setBrokerTeamLeader = (id, name) => {
    setBrokerTeam((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.leader.label = name;
      newState.leader.value = id;
      return newState;
    });
  }

  const setBrokerTeamStatus = (value) => {
    setBrokerTeam((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.status = value;
      return newState;
    });
  };

  const fetchBrokers = async (searchValue) => {
    return await fetchUsers(
      [
        "SYS_ADMIN_BROKER",
        "BROKER_LIDER",
        "BROKER_MERCHANT",
        "BROKER_SERVICE",
        "BROKER_CORRESPONDENT",
        "BROKER"
      ],
      searchValue
    );
  }

  async function fetchUsers(searchRoles, searchValue) {
    let results = [];
    if (searchValue.length > 0) {
      await api.personalDatas
        .getPersonalData(`user_role=${searchRoles.join(",")}&search=${searchValue}`)
        .then((response) => { results = response.data.results; })
        .catch((error) => console.log("fetchUsers error", error));
    }
    return results;
  }

  return (
    <>
      {(!brokerTeam || !brokerTeamOld) &&
        <Spin tip="Wczytywanie danych..." size="large" spinning={loading}>
          <div />
        </Spin>
      }

      {(brokerTeam || brokerTeamOld) &&
        <>
          <h1 id={TRANSLATIONS.BROKER_TEAM_EDIT__TITLE.key}>
            {translate(TRANSLATIONS.BROKER_TEAM_EDIT__TITLE.key)}
          </h1>

          <Divider />

          <Form
            form={editBrokerTeamFrom}
            id="broker-team-edit-form"
            name="broker-team-edit-form"
            autoComplete="off"
            layout="vertical"
            preserve={false}
            disabled={+loading}
            onFinish={(values) => submit(id, {
              name: brokerTeam.name,
              status: brokerTeam.status,
              leader: brokerTeam.leader.value,
            })}
          >
            <Form.Item
              id="name"
              name="name"
              label={
                <label
                  id={TRANSLATIONS.BROKER_TEAM_EDIT__FORM_NAME_LABEL.key}
                  style={{ fontWeight: "bold", fontSize: 20 }}
                >
                  {translate(TRANSLATIONS.BROKER_TEAM_EDIT__FORM_NAME_LABEL.key)}
                </label>
              }
              required
              rules={[
                // TODO: zrobić na useMemo()
                {
                  message: "Pole wymagane",
                  validator: (_, value) => {
                    if (!brokerTeam.name) {
                      setGuiValidationErrors((oldState) => {
                        const newState = Object.assign({}, oldState);
                        newState.name = "Pole wymagane";
                        return newState;
                      })
                      return Promise.reject();
                    } else {
                      setGuiValidationErrors((oldState) => {
                        const newState = Object.assign({}, oldState);
                        newState.name = null;
                        return newState;
                      })
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              initialValue={brokerTeam.name}
              validateStatus={
                apiValidationErrors?.name?.length > 0 || guiValidationErrors?.name
                  ? "error"
                  : null
              }
              help={
                apiValidationErrors?.name?.length > 0
                  ? apiValidationErrors.name.join(",")
                  : null
              }
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 10 },
              }}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 10 },
              }}
              normalize={(value) => value.trimStart()}
            >
              <Input
                id="name"
                name="name"
                size="large"
                allowClear
                onChange={(e) => {
                  setBrokerTeamName(e.currentTarget.value.trim())
                  setApiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.name = [];
                    return newState;
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              id="leader"
              name="leader"
              label={
                <label
                  id={TRANSLATIONS.BROKER_TEAM_EDIT__FORM_LEADER_LABEL.key}
                  style={{ fontWeight: "bold", fontSize: 20 }}
                >
                  {translate(TRANSLATIONS.BROKER_TEAM_EDIT__FORM_LEADER_LABEL.key)}
                </label>
              }
              required
              rules={[
                // TODO: zrobić na useMemo()
                {
                  message: "Pole wymagane",
                  validator: (_, value) => {
                    if (!brokerTeam.leader.value) {
                      setGuiValidationErrors((oldState) => {
                        const newState = Object.assign({}, oldState);
                        newState.leader = "Pole wymagane";
                        return newState;
                      })
                      return Promise.reject();
                    } else {
                      setGuiValidationErrors((oldState) => {
                        const newState = Object.assign({}, oldState);
                        newState.leader = null;
                        return newState;
                      })
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              validateStatus={
                apiValidationErrors?.leader?.length > 0 || guiValidationErrors?.leader
                  ? "error"
                  : null
              }
              help={
                apiValidationErrors?.leader?.length > 0
                  ? apiValidationErrors.leader.join(",")
                  : null
              }
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 10 },
              }}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 10 },
              }}
            >
              <DebounceSelectPerson
                fetchOptions={fetchBrokers}
                onSelect={(option) => setBrokerTeamLeader(option.value, option.label)}
                onClear={() => {
                  setBrokerTeamLeader(null, "");
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.leader = [];
                    return newState;
                  });
                }}
              />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                id={TRANSLATIONS.BROKER_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL.key}
                type="success"
                htmlType="submit"
                size="large"
                disabled={JSON.stringify(brokerTeam) === JSON.stringify(brokerTeamOld)}
              >
                {translate(TRANSLATIONS.BROKER_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL.key)}
              </Button>
            </Form.Item>
          </Form>
        </>
      }
    </>
  );
}
