import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import api from "../../infra/api";
import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import useQueryParams from "../../hooks/useQueryParams.hook";

import { Divider, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import GeneralContractsFilters from "../../components/generalContract/GeneralContractsFilters";
import GeneralContractsTable from "../../components/generalContract/GeneralContractsTable";

export default function GeneralContractsPage({ user }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { queryParams, setQueryParams } = useQueryParams();
  const [filters, setFilters] = useState({
    name_contains: "",
  });
  const [generalContracts, setGeneralContracts] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    current: 1,
    next: null,
    prev: null,
  });
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState("-id");

  const fetchGeneralContracts = async ({
    page,
    size,
    order_by,
    filters,
  }) => {
    setLoading(true);
    const queryFilters = `name_contains=${filters.name_contains ?? ""}`;
    api.generalContracts
      .getGeneralContracts({
        page,
        size,
        order_by,
        queryFilters,
      })
      .then(response => {
        const data = response.data;
        const pag = data.pagination;
        const res = data.results.map((result) => ({
          ...result,
          key: result.id,
        }));

        setPagination(pag);
        setGeneralContracts(res);
        setPageNumber(pag.current);
        setLoading(false);
      })
      .catch(error => {
        console.log("general contracts page error", error);
      });
  };

  useEffect(() => {
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setFilters((oldFilters) => {
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains = paramsFromUrl.name_contains ?? "";
      const data = {
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      fetchGeneralContracts(data);
      return newFilters;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, searchParams]);

  useEffect(() => {
    console.log("GeneralContractsPage mounted ...");
  }, []);

  return (
    <div>
      <h1 id={TRANSLATIONS.GENERAL_CONTRACT_LIST__TITLE.key}>
        {translate(TRANSLATIONS.GENERAL_CONTRACT_LIST__TITLE.key)}
      </h1>

      <Divider />

      <GeneralContractsFilters
        user={user}
        options={{
          pageNumber,
          pageSize,
          orderBy,
          filters,
          setFilters,
        }}
      />

      <Row
        justify="space-between"
        style={{
          marginTop: 30,
          marginBottom: 15,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Col>
          {/* Dostępny dla konkretnych ról SYS_ADMIN_AGENCY SYS_ADMIN_BROKER */}
          <Button
            id={TRANSLATIONS.GENERAL_CONTRACT_LIST__ADD_NEW_GENERAL_CONTRACT_BUTTON_LABEL.key}
            type="info"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => navigate("/general-contracts/create")}
          >
            {translate(TRANSLATIONS.GENERAL_CONTRACT_LIST__ADD_NEW_GENERAL_CONTRACT_BUTTON_LABEL.key)}
          </Button>
        </Col>
      </Row>

      <GeneralContractsTable
        user={user}
        tableStateChanged={(newPageNumber, newPageSize, newOrderBy) => {
          setPageNumber(newPageNumber);
          setPageSize(newPageSize);
          setOrderBy(newOrderBy);
          setQueryParams("/general-contracts", {
            page: newPageNumber,
            size: newPageSize,
            order_by: newOrderBy,
            name_contains: filters.name_contains,
          });
        }}
        options={{
          pagination,
          pageSize,
          items: generalContracts,
        }}
        loading={loading}
      />
    </div>
  );
}
