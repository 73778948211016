import { useState } from "react";

import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import { Button, Divider, Form, Space, Steps } from "antd";
import UseForm from "../../hooks/useForm";
import GeneralContractDataFormSection from "../../components/generalContract/create/GeneralContractDataFormSection";
import RisksFormSection from "../../components/generalContract/create/RisksFormSection";
import ClausesFormSection from "../../components/generalContract/create/ClausesFormSection";
import CustomersFormSection from "../../components/generalContract/create/CustomersFormSection";


export default function GeneralContractCreatePage({ user }) {
  const {
    form: generalContractDataForm,
    formHelpers: generalContractDataFormHelpers,
    formFields: generalContractDataFormFields,
  } = UseForm({
    NUMBER: "number",
    INSURER: "insurer",
    INSURER_DEPOT: "insurer_depot",
    START_DATE: "start_date",
    START_DATE_AS_STRING: "start_date_as_string",
    HANDLING_PERSON_INSURER: "handling_person_insurer",
    HANDLING_PERSON_INSURER_NAME: "first_name",
    HANDLING_PERSON_INSURER_LAST_NAME: "last_name",
    HANDLING_PERSON_INSURER_PHONE: "phone",
    HANDLING_PERSON_INSURER_EMAIL: "email",
  });

  const {
    form: generalContractRisksForm,
    formHelpers: generalContractRisksFormHelpers,
    formFields: generalContractRisksFormFields,
  } = UseForm({
    RISK_NAME: "risk_name",
    CAR_TYPE: "car_type",
    DATA_TYPE: "data_type",
    DATA_VALUE: "data_value",
  });

  const {
    form: generalContractClausesForm,
    formHelpers: generalContractClausesFormHelpers,
    formFields: generalContractClausesFormFields,
  } = UseForm({
    CLAUSE_NAME: "clause_name",
    CAR_TYPE: "car_type",
    DATA_TYPE: "data_type",
    DATA_VALUE: "data_value",
  });

  const {
    form: generalContractCustomerForm,
    formHelpers: generalContractCustomerFormHelpers,
    formFields: generalContractCustomerFormFields,
  } = UseForm({
    CUSTOMER: "customer",
    HANDLING_PERSON_CUSTOMER: "handling_person_customer",
    CUSTOMER_RETURN_ACCOUNT_NUMBER: "customer_return_account_number",
    CUSTOMER_SALARY: "customer_salary",
    HAS_NOTE: "has_note",
    LEAD_BROKER: "lead_broker",
    LEAD_BROKER_SALARY: "lead_broker_salary",
    BROKERAGE: "brokerage",
    HANDLING_PERSON_AGENT: "handling_person_agent",
    TRAVELING_AGENT: "traveling_agent",
    TRAVELING_AGENT_SALARY: "traveling_agent_salary",
    PROVISION_AGENCY: "provision_agency",
    OTHER_SALARY: "other_salary",
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [items, setItems] = useState(
    [
      {
        description: "Dane",
        status: "process",
      },
      {
        description: "Ryzyka",
        status: "wait",
      },
      {
        description: "Klauzule",
        status: "wait",
      },
      {
        description: "Dane Klienta",
        status: "wait",
      },
    ].map((item, index) => ({ key: index, ...item }))
  );

  const RISKS_AND_CLAUSES_DATA_TYPES = [
    {
      value: "PERCENTAGE",
      label: "Procentowe",
    },
    {
      value: "CURRENCY",
      label: "Kwotowe",
    },
  ];
  const [insurers, setInsurers] = useState([]);
  const [insurerDepots, setInsurerDepots] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerPersons, setCustomerPersons] = useState([]);
  const [customerBrokers, setCustomerBrokers] = useState([]);
  const [customerAgents, setCustomerAgents] = useState([]);
  const config = {
    insurers,
    setInsurers,
    insurerDepots,
    setInsurerDepots,
    RISKS_AND_CLAUSES_DATA_TYPES,
    customers,
    setCustomers,
    customerPersons,
    setCustomerPersons,
    customerBrokers,
    setCustomerBrokers,
    customerAgents,
    setCustomerAgents,
  };

  const setStepStatus = (step, status) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[step].status = status;
      return newItems;
    });
  };

  const stepFieldsNameMapping = {
    0: generalContractDataFormFields,
    1: generalContractRisksFormFields,
    2: generalContractClausesFormFields,
    3: generalContractCustomerFormFields,
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        },
      },
    });
  };

  const resetStepFieldsApiErrors = (
    step,
    changedField,
    formHelpers,
    formFields
  ) => {
    if (stepHasErrors(currentStep, changedField)) {
      const field = Object.keys(changedField)[0].toUpperCase();
      formHelpers.resetFieldApiError(formFields[field]);
      setStepStatus(step, "process");
    }
  };

  const stepHasErrors = (step, response) => {
    for (const [field] of Object.entries(response)) {
      if (stepFieldsNameMapping[step][field.toUpperCase()]) {
        return true;
      }
    }
    return false;
  };

  const previousStep = (step, formHelpers) => {
    if (formHelpers.errorFields.length === 0) {
      setStepStatus(step, "wait");

      const previousStep = step - 1;
      setStepStatus(previousStep, "process");
      setCurrentStep(previousStep);
    } else {
      setStepStatus(step, "error");
    }
  };

  const nextStep = (step, formHelpers) => {
    if (formHelpers.errorFields.length === 0) {
      setStepStatus(step, "finish");

      const nextStep = step + 1;
      setStepStatus(nextStep, "process");
      setCurrentStep(nextStep);
    } else {
      setStepStatus(step, "error");
    }
  };

  const finishStep = (step, formHelpers) => {
    if (formHelpers.errorFields.length > 0) {
      setStepStatus(step, "error");
    } else {
      const payload = {
      };
      console.log("form payload", payload);
      setStepStatus(currentStep, "finish");
      submit(payload);
    }
  };

  const submit = (payload) => {
    console.log("form submitted, need impl");
  };

  return (
    <>
      <h1 id={TRANSLATIONS.GENERAL_CONTRACT_CREATE__TITLE.key}>
        {translate(TRANSLATIONS.GENERAL_CONTRACT_CREATE__TITLE.key)}
      </h1>

      <Divider />

      <Steps
        size="large"
        labelPlacement="vertical"
        current={currentStep}
        initial={0}
        items={items}
        style={{ paddingBottom: 30 }}
        onChange={(current) => setCurrentStep(current)}
      />

      {/* NOTE: general contract data section */}
      {currentStep === 0 && (
        <Form
          id="general_contract_data_form"
          name="general_contract_data_form"
          form={generalContractDataForm}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onValuesChange={(changedField) => resetStepFieldsApiErrors(
            currentStep,
            changedField,
            generalContractDataFormHelpers,
            generalContractDataFormFields
          )}
          onFinish={() => nextStep(currentStep, generalContractDataFormHelpers)}
          onFinishFailed={() => setStepStatus(currentStep, "error")}
        >
          <GeneralContractDataFormSection
            form={generalContractDataForm}
            formHelpers={generalContractDataFormHelpers}
            formFields={generalContractDataFormFields}
            config={config}
          />

          <Form.Item>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                }
                type="lightdark"
                size="large"
                ghost
                disabled={currentStep === 0}
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                )}
              </Button>

              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                }
                type="success"
                size="large"
                htmlType="submit"
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}

      {/* NOTE: general contract risks section */}
      {currentStep === 1 && (
        <Form
          id="risks_form"
          name="risks_form"
          form={generalContractRisksForm}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onValuesChange={(changedField) => resetStepFieldsApiErrors(
            currentStep,
            changedField,
            generalContractRisksFormHelpers,
            generalContractRisksFormFields
          )}
          onFinish={() => nextStep(currentStep, generalContractRisksFormHelpers)}
          onFinishFailed={() => setStepStatus(currentStep, "error")}
        >
          <RisksFormSection
            form={generalContractRisksForm}
            formHelpers={generalContractRisksFormHelpers}
            formFields={generalContractRisksFormFields}
            config={config}
          />

          <Form.Item>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                }
                type="lightdark"
                size="large"
                ghost
                onClick={() => previousStep(
                  currentStep,
                  generalContractRisksFormHelpers
                )}
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                )}
              </Button>

              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                }
                type="success"
                size="large"
                htmlType="submit"
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}

      {/* NOTE: general contract clauses section */}
      {currentStep === 2 && (
        <Form
          id="clauses_form"
          name="clauses_form"
          form={generalContractClausesForm}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onValuesChange={(changedField) => resetStepFieldsApiErrors(
            currentStep,
            changedField,
            generalContractClausesFormHelpers,
            generalContractClausesFormFields
          )}
          onFinish={() => nextStep(currentStep, generalContractClausesFormHelpers)}
          onFinishFailed={() => setStepStatus(currentStep, "error")}
        >
          <ClausesFormSection
            form={generalContractClausesForm}
            formHelpers={generalContractClausesFormHelpers}
            formFields={generalContractClausesFormFields}
            config={config}
          />

          <Form.Item>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                }
                type="lightdark"
                size="large"
                ghost
                onClick={() => previousStep(
                  currentStep,
                  generalContractClausesFormHelpers
                )}
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                )}
              </Button>

              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                }
                type="success"
                size="large"
                htmlType="submit"
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}

      {/* NOTE: general contract customer section */}
      {currentStep === 3 && (
        <Form
          id="customer_form"
          name="customer_form"
          form={generalContractCustomerForm}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onValuesChange={(changedField) => resetStepFieldsApiErrors(
            currentStep,
            changedField,
            generalContractCustomerFormHelpers,
            generalContractCustomerFormFields
          )}
          onFinish={() => nextStep(currentStep, generalContractCustomerFormHelpers)}
          onFinishFailed={() => setStepStatus(currentStep, "error")}
        >
          <CustomersFormSection
            form={generalContractCustomerForm}
            formHelpers={generalContractCustomerFormHelpers}
            formFields={generalContractCustomerFormFields}
            config={config}
          />

          <Form.Item>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                }
                type="lightdark"
                size="large"
                ghost
                onClick={() => previousStep(
                  currentStep,
                  generalContractCustomerFormHelpers
                )}
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                    .key
                )}
              </Button>

              <Button
                id={
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                }
                type="success"
                size="large"
                htmlType="submit"
              >
                {translate(
                  TRANSLATIONS.GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL.key
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
