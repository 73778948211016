import React, { useEffect, useState } from "react";

import { Button, Input } from "antd";

import { dispatch } from "use-bus";

export default function BusTest() {
  useEffect(() => {
    console.log("BusTest mounted.");
  }, []);

  const [input, setInput] = useState("");
  return (
    <div>
      <h3>BusTest - value in bus component: {input}</h3>

      <Input value={input} onChange={(e) => setInput(e.target.value)} />
      {/* NOTE: type to nazwa eventu na ktory gdzies sluchamy
                key to payload - dowolny obiekt
                dziala XD
      */}
      <Button
        onClick={() =>
          dispatch({
            type: "BUS_TEST",
            payload: input
          })
        }
      >
        Emit
      </Button>
    </div>
  );
}
