import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import api from "../../infra/api";
import UseForm from "../../hooks/useForm";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import { Button, Form, Divider, Input, Steps, Space, Select, Row, Col } from "antd";

import { CUSTOMER_TYPES } from "../../components/customer/commons/CustomerTypes";
import { CUSTOMER_ROLES } from "../../components/customer/commons/CustomerRoles";
import {
  getAvailableRoles,
  getRequiredFields,
} from "../../components/customer/commons/CustomerConfig";

import CustomerDataFormSection from "../../components/customer/create/CustomerDataFormSection";
import ContactPersonsSection from "../../components/customer/create/ContactPersonsSection";
import AgencyTeamFormSection from "../../components/customer/create/AgencyTeamFormSection";
import BrokerTeamFormSection from "../../components/customer/create/BrokerTeamFormSection";
import CreateContactPersonSection from "../../components/customer/create/CreateContactPersonSection";

export default function CustomerCreatePage({ user }) {
  const {
    form: customerDataForm,
    formHelpers: customerDataFormHelpers,
    formFields: customerDataFormFields,
  } = UseForm({
    TYPE: "type",
    ROLE: "role",
    NAME: "name",
    REGON: "regon",
    NIP: "nip",
    PESEL: "pesel",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    ADDRESS: "address",
    POSTAL_CODE: "postal_code",
    CITY: "city",
    PHONE_1: "phone_1",
    PHONE_2: "phone_2",
    EMAIL: "email",
    CUSTOMER_GROUP: "customer_group",
    AGREEMENT_ALL: "agreement_all",
    AGREEMENT_1: "agreement_1",
    AGREEMENT_2: "agreement_2",
    AGREEMENT_3: "agreement_3",
  });

  const [createContactPersonFormActive, setCreateContactPersonFormActive] = useState(false);
  const contactPersonsFields = {};
  for (let i = 1; i <= 10; i++) {
    contactPersonsFields[`PD_ID_${i}`] = `pd_id_${i}`;
    contactPersonsFields[`DESCRIPTION_${i}`] = `description_${i}`;
    contactPersonsFields[`IS_SIGNABLE_${i}`] = `is_signable_${i}`;
  }
  const {
    form: contactPersonsForm,
    formHelpers: contactPersonsFormHelpers,
    formFields: contactPersonsFormFields,
  } = UseForm({
    ...contactPersonsFields,
  });

  const {
    form: brokerTeamForm,
    formHelpers: brokerTeamFormHelpers,
    formFields: brokerTeamFormFields,
  } = UseForm({
    BROKER_TEAM: "broker_team",
    BROKER_KEEPER: "keeper_broker_side",
    BROKER_CORRESPONDENT: "correspondent_broker_side",
  });

  const {
    form: agencyTeamForm,
    formHelpers: agencyTeamFormHelpers,
    formFields: agencyTeamFormFields,
  } = UseForm({
    AGENT_TEAM: "agent_team",
    AGENT_KEEPER: "keeper_agent_side",
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customerTypes] = useState(CUSTOMER_TYPES);
  const [customerRoles, setCustomerRoles] = useState([]);
  const [customerRequiredFields, setCustomerRequiredFields] = useState([]);
  const [disabledField, setDisabledField] = useState(null);
  const [regon, setRegon] = useState("");
  const [pesel, setPesel] = useState("");
  const [customerExists, setCustomerExists] = useState(true);
  const [existingCustomerData, setExistingCustomerData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [items, setItems] = useState(
    [
      {
        description: translate(
          TRANSLATIONS.CUSTOMER_CREATE__FORM_CUSTOMER_DATA_LABEL.key
        ),
        status: "process",
      },
      {
        description: translate(
          TRANSLATIONS.CUSTOMER_CREATE__FORM_CUSTOMER_CONTACT_PERSONS_LABEL.key
        ),
        status: "wait",
      },
      {
        description: translate(
          TRANSLATIONS.CUSTOMER_CREATE__FORM_BROKER_TEAM_LABEL.key
        ),
        status: "wait",
      },
      {
        description: translate(
          TRANSLATIONS.CUSTOMER_CREATE__FORM_AGENT_TEAM_LABEL.key
        ),
        status: "wait",
      },
    ].map((item, index) => ({ key: index, ...item }))
  );

  const [customerGroups, setCustomerGroups] = useState([]);
  const [customerContactPersonsElements, setCustomerContactPersonsElements] = useState([1]);
  const [contactPersonElementNum, setContactPersonElementNum] = useState(null);
  const [customerContactPersons, setCustomerContactPersons] = useState([]);
  const [brokerTeams, setBrokerTeams] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [agentTeams, setAgentTeams] = useState([]);
  const [agents, setAgents] = useState([]);
  const config = {
    customerTypes,
    customerRoles,
    customerRequiredFields,
    disabledField,
    customerGroups,
    setCustomerGroups,
    customerContactPersonsElements,
    setCustomerContactPersonsElements,
    createContactPersonFormActive,
    setCreateContactPersonFormActive,
    contactPersonElementNum,
    setContactPersonElementNum,
    customerContactPersons,
    setCustomerContactPersons,
    brokerTeams,
    setBrokerTeams,
    brokers,
    setBrokers,
    agentTeams,
    setAgentTeams,
    agents,
    setAgents,
  };

  const setStepStatus = (step, status) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[step].status = status;
      return newItems;
    });
  };

  const stepFieldsNameMapping = {
    0: customerDataFormFields,
    1: contactPersonsFormFields,
    2: brokerTeamFormFields,
    3: agencyTeamFormFields,
  };

  // TODO: przenieść do helpera
  const findStepByFieldName = (fieldName) => {
    for (const [step, fields] of Object.entries(stepFieldsNameMapping)) {
      if (fields[fieldName.toUpperCase()]) {
        return parseInt(step);
      }
    }
    return -1;
  };

  const findFirstFailedStep = (apiErrors) => {
    for (const [field] of Object.entries(apiErrors)) {
      const step = findStepByFieldName(field);
      if (step !== -1) {
        return step;
      }
    }
    throw new Error("No failed step found");
  };

  const stepHasErrors = (step, response) => {
    for (const [field] of Object.entries(response)) {
      if (stepFieldsNameMapping[step][field.toUpperCase()]) {
        return true;
      }
    }
    return false;
  };

  const previousStep = (step, formHelpers) => {
    if (formHelpers.errorFields.length === 0) {
      setStepStatus(step, "wait");

      const previousStep = step - 1;
      setStepStatus(previousStep, "process");
      setCurrentStep(previousStep);
    } else {
      setStepStatus(step, "error");
    }
  };

  const nextStep = (step, formHelpers) => {
    if (formHelpers.errorFields.length === 0) {
      setStepStatus(step, "finish");

      const nextStep = step + 1;
      setStepStatus(nextStep, "process");
      setCurrentStep(nextStep);
    } else {
      setStepStatus(step, "error");
    }
  };

  const finishStep = (step, formHelpers) => {
    if (formHelpers.errorFields.length > 0) {
      setStepStatus(step, "error");
    } else {
      const payload = {
        type: customerDataForm.getFieldValue(customerDataFormFields.TYPE),
        role: customerDataForm.getFieldValue(customerDataFormFields.ROLE),
        name: customerDataForm.getFieldValue(customerDataFormFields.NAME),
        regon: customerDataForm.getFieldValue(customerDataFormFields.REGON),
        customer_group: customerDataForm.getFieldValue(
          customerDataFormFields.CUSTOMER_GROUP
        ),
        broker_team: brokerTeamForm.getFieldValue(
          brokerTeamFormFields.BROKER_TEAM
        ),
        brokers: brokerTeamForm.getFieldValue(brokerTeamFormFields.BROKER_TEAM)
          ? [
            {
              id: brokerTeamForm.getFieldValue(
                brokerTeamFormFields.BROKER_KEEPER
              ),
              role: "KEEPER",
            },
            {
              id: brokerTeamForm.getFieldValue(
                brokerTeamFormFields.BROKER_CORRESPONDENT
              ),
              role: "CORRESPONDENT",
            },
          ]
          : [],
        agent_team: agencyTeamForm.getFieldValue(
          agencyTeamFormFields.AGENT_TEAM
        ),
        agents: [
          {
            id: agencyTeamForm.getFieldValue(agencyTeamFormFields.AGENT_KEEPER),
            role: "KEEPER",
          },
        ],
        agreement_1: customerDataForm.getFieldValue(
          customerDataFormFields.AGREEMENT_1
        ),
        agreement_2: customerDataForm.getFieldValue(
          customerDataFormFields.AGREEMENT_2
        ),
        agreement_3: customerDataForm.getFieldValue(
          customerDataFormFields.AGREEMENT_2
        ),
        contact_persons: customerContactPersonsElements.map((num) => ({
          personal_data: contactPersonsForm.getFieldValue(contactPersonsFormFields[`PD_ID_${num}`]),
          description: contactPersonsForm.getFieldValue(contactPersonsFormFields[`DESCRIPTION_${num}`]),
          is_signable: contactPersonsForm.getFieldValue(contactPersonsFormFields[`IS_SIGNABLE_${num}`]),
        })),
        data: {
          nip: customerDataForm.getFieldValue(customerDataFormFields.NIP),
          pesel: customerDataForm.getFieldValue(customerDataFormFields.PESEL),
          first_name: customerDataForm.getFieldValue(
            customerDataFormFields.FIRST_NAME
          ),
          last_name: customerDataForm.getFieldValue(
            customerDataFormFields.LAST_NAME
          ),
          address: customerDataForm.getFieldValue(
            customerDataFormFields.ADDRESS
          ),
          postal_code: customerDataForm.getFieldValue(
            customerDataFormFields.POSTAL_CODE
          ),
          city: customerDataForm.getFieldValue(customerDataFormFields.CITY),
          phone_1: customerDataForm.getFieldValue(
            customerDataFormFields.PHONE_1
          ),
          phone_2: customerDataForm.getFieldValue(
            customerDataFormFields.PHONE_2
          ),
          email: customerDataForm.getFieldValue(customerDataFormFields.EMAIL),
        },
      };
      console.log("form payload", payload);
      setStepStatus(currentStep, "finish");
      submit(payload);
    }
  };

  const submit = async (payload) => {
    setLoading(true);
    await api.customers
      .createCustomer(payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano klienta "${payload.name}"`
        );
        setLoading(false);
        navigate(`/customers/${response.data.id}`);
      })
      .catch((error) => {
        setLoading(false);

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        if (error?.response.status === 400) {
          const errorData = error.response.data;
          customerDataFormHelpers.setApiErrors(errorData);
          contactPersonsFormHelpers.setApiErrors(errorData);
          brokerTeamFormHelpers.setApiErrors(errorData);
          agencyTeamFormHelpers.setApiErrors(errorData);

          const firstFailedStep = findFirstFailedStep(errorData);
          for (const [key] of Object.entries(items)) {
            if (stepHasErrors(key, errorData)) {
              setStepStatus(key, "error");
            } else if (key > firstFailedStep) {
              setStepStatus(key, "wait");
            } else if (key < firstFailedStep) {
              setStepStatus(key, "finish");
            }
          }

          setCurrentStep(firstFailedStep);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania klienta "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas dodawania klienta "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania klienta "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const checkIfCustomerExists = async (formField, value) => {
    setLoading(true);
    setCustomerExists(true);
    await api.customers
      .searchCustomers(`${formField}=${value}`)
      .then((response) => {
        const results = response.data.results;
        if (response.data.results.length > 0) {
          setLoading(false);
          setExistingCustomerData(results[0]);
        } else {
          setLoading(false);
          setCustomerExists(false);
          setDisabledField(formField);
          customerDataForm.setFieldValue({ [formField]: value });
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        },
      },
    });
  };

  const resetStepFieldsApiErrors = (
    step,
    changedField,
    formHelpers,
    formFields
  ) => {
    if (stepHasErrors(currentStep, changedField)) {
      const field = Object.keys(changedField)[0].toUpperCase();
      formHelpers.resetFieldApiError(formFields[field]);
      setStepStatus(step, "process");
    }
  };

  return (
    <>
      <h1 id={TRANSLATIONS.CUSTOMER_CREATE__TITLE.key}>
        {translate(TRANSLATIONS.CUSTOMER_CREATE__TITLE.key)}
      </h1>

      <Divider />

      {customerExists && (
        <>
          <h3>
            Wybierz typ klienta, a następnie sprawdź po numerze REGON lub PESEL
            czy klient jest już kartotece
          </h3>
          <Form
            id="check_existing_customer_form"
            name="check_existing_customer_form"
            form={customerDataForm}
            autoComplete="off"
            layout="vertical"
            preserve={false}
            disabled={loading}
            onFinish={(values) => {
              if (values.pesel) {
                checkIfCustomerExists(
                  customerDataFormFields.PESEL,
                  values.pesel
                );
              } else {
                checkIfCustomerExists(
                  customerDataFormFields.REGON,
                  values.regon
                );
              }
            }}
          >
            {/* select customer TYPE */}
            <Form.Item
              id={customerDataFormFields.TYPE}
              name={customerDataFormFields.TYPE}
              initialValue={null}
              label={
                <label
                  id={TRANSLATIONS.CUSTOMER_TYPE__LABEL.key}
                  style={{ fontWeight: "bold" }}
                >
                  {translate(TRANSLATIONS.CUSTOMER_TYPE__LABEL.key)}
                </label>
              }
              required
              rules={[
                {
                  validator: (_, value) => {
                    return customerDataFormHelpers.validateRequiredGuiField(
                      customerDataFormFields.TYPE,
                      value,
                      "Pole wymagane"
                    );
                  },
                },
              ]}
              validateStatus={
                customerDataFormHelpers.errorFields.includes(
                  customerDataFormFields.TYPE
                )
                  ? "error"
                  : null
              }
              help={
                customerDataFormHelpers.errorFields.includes(
                  customerDataFormFields.TYPE
                )
                  ? customerDataFormHelpers
                    .getFieldErrors(customerDataFormFields.TYPE)
                    .join(", ")
                  : null
              }
            >
              <Select
                allowClear
                showSearch
                size="large"
                disabled={loading}
                onChange={(value) => {
                  setExistingCustomerData(null);
                  setRegon(null);
                  setPesel(null);
                  setCustomerRoles(() =>
                    CUSTOMER_ROLES.filter((role) =>
                      getAvailableRoles(value).includes(role.value)
                    )
                  );
                  setCustomerRequiredFields(getRequiredFields(value));
                  customerDataForm.setFieldValue({
                    [customerDataFormFields.TYPE]: value,
                  });
                }}
                placeholder="Wybierz typ klienta"
                options={CUSTOMER_TYPES}
                filterOption={handleFilterOption}
              />
            </Form.Item>

            {customerDataForm.getFieldValue(customerDataFormFields.TYPE) &&
              customerDataForm.getFieldValue(customerDataFormFields.TYPE) !==
              "PHISICAL_PERSON_WITHOUT_ECONOMIC_ACTIVITY" && (
                // search customer REGON
                <Form.Item
                  id={customerDataFormFields.REGON}
                  name={customerDataFormFields.REGON}
                  initialValue={null}
                  label={
                    <label
                      id={TRANSLATIONS.CUSTOMER_REGON__LABEL.key}
                      style={{ fontWeight: "bold" }}
                    >
                      {translate(TRANSLATIONS.CUSTOMER_REGON__LABEL.key)}
                    </label>
                  }
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        return customerDataFormHelpers.validateRequiredGuiField(
                          customerDataFormFields.REGON,
                          value,
                          "Pole wymagane"
                        );
                      },
                    },
                    {
                      validator: (_, value) => {
                        if (value) {
                          return customerDataFormHelpers.validateAgainstRegex(
                            /^\d{9}$|^\d{14}$/g,
                            customerDataFormFields.REGON,
                            value,
                            "REGON musi składać się z 9 lub 14 cyfr"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  validateStatus={
                    existingCustomerData
                      ? "warning"
                      : customerDataFormHelpers.errorFields.includes(
                        customerDataFormFields.REGON
                      )
                        ? "error"
                        : null
                  }
                  help={
                    existingCustomerData ? (
                      <span style={{ fontWeight: "bold" }}>
                        <span>Klient o numerze REGON </span>
                        <Link
                          to={`/customers/${existingCustomerData.id}`}
                          style={{ fontWeight: "bold" }}
                        >
                          {existingCustomerData.regon}
                        </Link>
                        <span> jest w kartotece</span>
                      </span>
                    ) : customerDataFormHelpers.errorFields.includes(
                      customerDataFormFields.REGON
                    ) ? (
                      customerDataFormHelpers
                        .getFieldErrors(customerDataFormFields.REGON)
                        .join(", ")
                    ) : null
                  }
                >
                  <Input.Search
                    id={customerDataFormFields.REGON}
                    name={customerDataFormFields.REGON}
                    enterButton={
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        Sprawdź
                      </Button>
                    }
                    autoComplete="off"
                    size="large"
                    allowClear
                    maxLength={14}
                    showCount
                    value={regon}
                    disabled={loading}
                    onChange={(e) => {
                      setExistingCustomerData(null);
                      const finalValue =
                        e.target.value.length > 50
                          ? e.target.value.slice(0, 50)
                          : e.target.value;
                      setRegon(finalValue);
                    }}
                  />
                </Form.Item>
              )}

            {customerDataForm.getFieldValue(customerDataFormFields.TYPE) ===
              "PHISICAL_PERSON_WITHOUT_ECONOMIC_ACTIVITY" && (
                // search customer PESEL
                <Form.Item
                  id={customerDataFormFields.PESEL}
                  name={customerDataFormFields.PESEL}
                  initialValue={null}
                  label={
                    <label
                      id={TRANSLATIONS.CUSTOMER_PESEL__LABEL.key}
                      style={{ fontWeight: "bold" }}
                    >
                      {translate(TRANSLATIONS.CUSTOMER_PESEL__LABEL.key)}
                    </label>
                  }
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        return customerDataFormHelpers.validateRequiredGuiField(
                          customerDataFormFields.PESEL,
                          value,
                          "Pole wymagane"
                        );
                      },
                    },
                    {
                      validator: (_, value) => {
                        if (value) {
                          return customerDataFormHelpers.validateAgainstRegex(
                            /^\d{11}$/g,
                            customerDataFormFields.PESEL,
                            value,
                            "PESEL musi składać się z 11 cyfr"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  validateStatus={
                    existingCustomerData
                      ? "warning"
                      : customerDataFormHelpers.errorFields.includes(
                        customerDataFormFields.PESEL
                      )
                        ? "error"
                        : null
                  }
                  help={
                    existingCustomerData ? (
                      <span style={{ fontWeight: "bold" }}>
                        <span>Klient o numerze PESEL </span>
                        <Link
                          to={`/customers/${existingCustomerData.id}`}
                          style={{ fontWeight: "bold" }}
                        >
                          {existingCustomerData.data.pesel}
                        </Link>
                        <span> jest w kartotece</span>
                      </span>
                    ) : customerDataFormHelpers.errorFields.includes(
                      customerDataFormFields.PESEL
                    ) ? (
                      customerDataFormHelpers
                        .getFieldErrors(customerDataFormFields.PESEL)
                        .join(", ")
                    ) : null
                  }
                >
                  <Input.Search
                    id={customerDataFormFields.PESEL}
                    name={customerDataFormFields.PESEL}
                    enterButton={
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        Sprawdź
                      </Button>
                    }
                    autoComplete="off"
                    size="large"
                    allowClear
                    maxLength={11}
                    showCount
                    value={pesel}
                    disabled={loading}
                    onChange={(e) => {
                      setExistingCustomerData(null);
                      const finalValue =
                        e.target.value.length > 50
                          ? e.target.value.slice(0, 50)
                          : e.target.value;
                      setPesel(finalValue);
                    }}
                  />
                </Form.Item>
              )}
          </Form>
        </>
      )}

      {!customerExists && (
        <>
          <Steps
            size="large"
            labelPlacement="vertical"
            current={currentStep}
            initial={0}
            items={items}
            style={{ paddingBottom: 30 }}
          />

          {/* NOTE: customer data section */}
          {currentStep === 0 && (
            <Form
              id="customer_data_form"
              name="customer_data_form"
              form={customerDataForm}
              autoComplete="off"
              layout="vertical"
              scrollToFirstError
              onValuesChange={(changedField) => resetStepFieldsApiErrors(
                currentStep,
                changedField,
                customerDataFormHelpers,
                customerDataFormFields
              )}
              onFinish={() => nextStep(currentStep, customerDataFormHelpers)}
              onFinishFailed={() => setStepStatus(currentStep, "error")}
            >
              <CustomerDataFormSection
                form={customerDataForm}
                formHelpers={customerDataFormHelpers}
                formFields={customerDataFormFields}
                config={config}
              />

              <Form.Item>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <Button
                    id={
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                        .key
                    }
                    type="lightdark"
                    size="large"
                    ghost
                    disabled={currentStep === 0}
                  >
                    {translate(
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                        .key
                    )}
                  </Button>

                  <Button
                    id={
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL.key
                    }
                    type="success"
                    size="large"
                    htmlType="submit"
                  >
                    {translate(
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL.key
                    )}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}

          {/* NOTE: customer contact persons section */}
          {currentStep === 1 && (
            <Row gutter={40}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                <h2 style={{ textAlign: "center" }}>Lista osób kontaktowych</h2>

                <Divider />

                <Form
                  id="contact_persons_form"
                  name="contact_persons_form"
                  form={contactPersonsForm}
                  autoComplete="off"
                  layout="vertical"
                  scrollToFirstError
                  onValuesChange={(changedField) => resetStepFieldsApiErrors(
                    currentStep,
                    changedField,
                    contactPersonsFormHelpers,
                    contactPersonsFormFields
                  )}
                  onFinish={() => nextStep(currentStep, contactPersonsFormHelpers)}
                  onFinishFailed={() => setStepStatus(currentStep, "error")}
                >
                  <ContactPersonsSection
                    form={contactPersonsForm}
                    formHelpers={contactPersonsFormHelpers}
                    formFields={contactPersonsFormFields}
                    config={config}
                  />

                  <Form.Item>
                    <Space
                      direction="horizontal"
                      style={{ width: "100%", justifyContent: "center" }}
                    >
                      <Button
                        id={
                          TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                            .key
                        }
                        type="lightdark"
                        size="large"
                        ghost
                        onClick={() => previousStep(
                          currentStep,
                          contactPersonsFormHelpers
                        )}
                      >
                        {translate(
                          TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                            .key
                        )}
                      </Button>

                      <Button
                        id={
                          TRANSLATIONS.CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL.key
                        }
                        type="success"
                        size="large"
                        htmlType="submit"
                      >
                        {translate(
                          TRANSLATIONS.CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL.key
                        )}
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                <div style={{ position: "sticky", top: 10 }}>
                  <h2 style={{ textAlign: "center" }}>Dodanie nowej osoby kontaktowej</h2>

                  <Divider />

                  <CreateContactPersonSection config={config} />
                </div>
              </Col>
            </Row>
          )}

          {/* NOTE: broker team data section */}
          {currentStep === 2 && (
            <Form
              id="broker_team_form"
              name="broker_team_form"
              form={brokerTeamForm}
              autoComplete="off"
              layout="vertical"
              scrollToFirstError
              onValuesChange={(changedField) => resetStepFieldsApiErrors(
                currentStep,
                changedField,
                brokerTeamFormHelpers,
                brokerTeamFormFields
              )}
              onFinish={() => nextStep(currentStep, brokerTeamFormHelpers)}
              onFinishFailed={() => setStepStatus(currentStep, "error")}
            >
              <BrokerTeamFormSection
                form={brokerTeamForm}
                formHelpers={brokerTeamFormHelpers}
                formFields={brokerTeamFormFields}
                config={config}
              />

              <Form.Item>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <Button
                    id={
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                        .key
                    }
                    type="lightdark"
                    size="large"
                    ghost
                    onClick={() => previousStep(
                      currentStep,
                      brokerTeamFormHelpers
                    )}
                  >
                    {translate(
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                        .key
                    )}
                  </Button>

                  <Button
                    id={
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL.key
                    }
                    type="success"
                    size="large"
                    htmlType="submit"
                  >
                    {translate(
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL.key
                    )}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}

          {/* NOTE: agency team data section */}
          {currentStep === 3 && (
            <Form
              id="agency_team_form"
              name="agency_team_form"
              form={agencyTeamForm}
              autoComplete="off"
              layout="vertical"
              scrollToFirstError
              disabled={+loading}
              loading={+loading}
              onValuesChange={(changedField) => resetStepFieldsApiErrors(
                currentStep,
                changedField,
                agencyTeamFormHelpers,
                agencyTeamFormFields
              )}
              onFinish={() => finishStep(currentStep, agencyTeamFormHelpers)}
              onFinishFailed={() => setStepStatus(currentStep, "error")}
            >
              <AgencyTeamFormSection
                form={agencyTeamForm}
                formHelpers={agencyTeamFormHelpers}
                formFields={agencyTeamFormFields}
                config={config}
              />

              <Form.Item>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <Button
                    id={
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                        .key
                    }
                    type="lightdark"
                    size="large"
                    ghost
                    onClick={() => previousStep(
                      currentStep,
                      agencyTeamFormHelpers
                    )}
                  >
                    {translate(
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL
                        .key
                    )}
                  </Button>

                  <Button
                    id={
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_CREATE_BUTTON_LABEL.key
                    }
                    type="success"
                    size="large"
                    htmlType="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    {translate(
                      TRANSLATIONS.CUSTOMER_CREATE__FORM_CREATE_BUTTON_LABEL.key
                    )}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          )}
        </>
      )
      }
    </>
  );
}
