import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import {
  Button,
  Modal,
  Space,
  Tooltip,
  Form,
  Input,
  Divider,
} from "antd";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export default function CreateCoBrokerModal({ user }) {
  const navigate = useNavigate();
  const [apiValidationErrors, setApiValidationErrors] = useState({});

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const companyNameInput = useRef(null);
  const broker = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: ""
  });

  const focusFormInput = open => {
    if (open) {
      companyNameInput.current.focus();
    }
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const submit = async payload => {
    startLoading();
    api.personalDatas
      .addCoBroker({
        ...payload,
        role: "COBROKER"
      })
      .then(response => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano CO-Brokera "${payload.company_name}"`
        );
        
        dispatch({
          type: "COBROKER_CREATED",
          payload: response.data
        });

        stopLoading();
        closeModal();
      })
      .catch(error => {
        stopLoading();
        if (error?.response.status === 400) {
          setApiValidationErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania CO-Brokera "${payload.company_name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwera",
            `Wystąpił błąd podczas dodawania CO-Brokera "${payload.company_name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania CO-Brokera "${payload.company_name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
		dispatch({
			type: "SHOW_NOTIFICATION",
			payload: {
				notification: {
					type,
					title,
					description,
				}
			}
		});
  };

  return (
    <div
      style={{ marginTop: 5, marginRight: 0, marginLeft: 10, marginBottom: 10 }}
    >
      <Space>
        <Button
          id={TRANSLATIONS.COBROKER_CREATE__OPEN_MODAL_BUTTON_LABEL.key}
          type="info"
          size="large"
          icon={<PlusOutlined />}
          onClick={openModal}
        >
          {translate(TRANSLATIONS.COBROKER_CREATE__OPEN_MODAL_BUTTON_LABEL.key)}
        </Button>

        <Tooltip
          title={translate(TRANSLATIONS.COBROKER_CREATE__TOOLTIP_LABEL.key)}
          mouseEnterDelay={0.2}
          mouseLeaveDelay={0.1}
          overlayInnerStyle={{ textAlign: "center" }}
        >
          <Button
            type="link"
            size="large"
            shape="circle"
            disabled={true}
            icon={<QuestionCircleOutlined />}
            style={{
              cursor: "help",
              color: "#1677ff"
            }}
          />
        </Tooltip>
      </Space>

      <Modal
        title={
          <h2
            id={TRANSLATIONS.COBROKER_CREATE__MODAL_TITLE.key}
            style={{ textAlign: "center" }}
          >
            {translate(TRANSLATIONS.COBROKER_CREATE__MODAL_TITLE.key)}
          </h2>
        }
        open={open}
        destroyOnClose={true}
        closeIcon={false}
        keyboard={false}
        footer={null}
        afterOpenChange={focusFormInput}
      >
        <Divider />

        {/* TODO: Ostylowanie errorów + wyciągniecie do komonsów */}
        {apiValidationErrors &&
          Object.entries(apiValidationErrors).map(([key, value]) => {
            return (
              <div key={key} style={{ color: "red", margin: 15 }}>
                key: {key}, value:
                <ul>
                  {value.map((value) => (
                    <li>{value}</li>
                  ))}
                </ul>
              </div>
            );
          })}

        <Form
          form={form}
          name="create-co-broker-form"
          autoComplete="off"
          preserve={false}
          layout="vertical"
          disabled={loading}
          onFinish={submit}
        >
          <Form.Item
            id="company_name"
            name="company_name"
            label={
              <label
                id={TRANSLATIONS.COBROKER_CREATE__MODAL_FORM_NAME_LABEL.key}
                style={{ fontWeight: "bold", fontSize: 20 }}
              >
                {translate(TRANSLATIONS.COBROKER_CREATE__MODAL_FORM_NAME_LABEL.key)}
              </label>
            }
            rules={[
              {
                required: true,
                message: "Pole wymagane"
              }
            ]}
          // BUG: can't name customer group with spaces
          // normalize={value => value.trim()}
          >
            <Input
              ref={companyNameInput}
              id="company_name"
              name="company_name"
              size="large"
              allowClear
              value={broker.company_name}
            />
          </Form.Item>

          <Form.Item
            id="email"
            name="email"
            label={
              <label
                id={TRANSLATIONS.COBROKER_CREATE__MODAL_FORM_EMAIL_LABEL.key}
                style={{ fontWeight: "bold", fontSize: 20 }}
              >
                {translate(TRANSLATIONS.COBROKER_CREATE__MODAL_FORM_EMAIL_LABEL.key)}
              </label>
            }
            rules={[
              {
                required: true,
                message: "Pole wymagane"
              },
              {
                type: "email",
                message: "Wprowadź poprawny adres e-mail"
              }
            ]}
          // BUG: can't name customer group with spaces
          // normalize={value => value.trim()}
          >
            <Input
              id="email"
              name="email"
              size="large"
              allowClear
              value={broker.email}
            />
          </Form.Item>

          <Form.Item
            id="phone"
            name="phone"
            label={
              <label
                id={TRANSLATIONS.COBROKER_CREATE__MODAL_FORM_PHONE_LABEL.key}
                style={{ fontWeight: "bold", fontSize: 20 }}
              >
                {translate(TRANSLATIONS.COBROKER_CREATE__MODAL_FORM_PHONE_LABEL.key)}
              </label>
            }
            rules={[]}
          // BUG: can't name customer group with spaces
          // normalize={value => value.trim()}
          >
            <Input
              id="phone"
              name="phone"
              size="large"
              allowClear
              value={broker.phone}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button
                id={TRANSLATIONS.COBROKER_CREATE__MODAL_CANCEL_BUTTON_LABEL.key}
                type="lightdark"
                ghost
                htmlType="button"
                size="large"
                onClick={closeModal}
              >
                {translate(TRANSLATIONS.COBROKER_CREATE__MODAL_CANCEL_BUTTON_LABEL.key)}
              </Button>

              <Button
                type="success"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                {translate(loading
                  ? TRANSLATIONS.COBROKER_CREATE__MODAL_CREATION_BUTTON_LABEL.key
                  : TRANSLATIONS.COBROKER_CREATE__MODAL_CONFIRM_BUTTON_LABEL.key
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
