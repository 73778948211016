import { useEffect, useState } from "react";

import { Spin, Descriptions } from "antd";

import { CUSTOMER_TYPES } from "../commons/CustomerTypes";
import { CUSTOMER_ROLES } from "../commons/CustomerRoles";

export default function CustomerData({ customer }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = [
    {
      key: "name",
      label: "Nazwa",
      children: customer.name,
      span: 3,
    },
    {
      key: "type",
      label: "Typ",
      children: CUSTOMER_TYPES.find((type) => type.value === customer.type).label,
      span: 3,
    },
    {
      key: "role",
      label: "Rola",
      children: CUSTOMER_ROLES.find((role) => role.value === customer.role).label,
      span: 3,
    },
    {
      key: "regon",
      label: "Regon",
      children: customer.regon,
      span: 3,
    },
    {
      key: "customer-group",
      label: "Grupa klienta",
      children: customer.customer_group ? customer.customer_group.name : "Brak",
      span: 3,
    },
    {
      key: "agreement-1",
      label: "Zgoda RODO lub marketongowa 1",
      children: customer.agreement_1 ? "Tak (+ link do zgody)" : "Nie",
      span: 3,
    },
    {
      key: "agreement-2",
      label: "Zgoda RODO lub marketongowa 2",
      children: customer.agreement_2 ? "Tak (+ link do zgody)" : "Nie",
      span: 3,
    },
    {
      key: "agreement-3",
      label: "Zgoda RODO lub marketongowa 3",
      children: customer.agreement_3 ? "Tak (+ link do zgody)" : "Nie",
      span: 3,
    },
  ];

  return (
    <>
      {loading && (
        <Spin tip="Wczytywanie danych..." spinning={loading} style={{ marginTop: 25 }}>
          <div />
        </Spin>
      )}

      {!loading && (
        <Descriptions bordered size="large" items={items} />
      )}
    </>
  );
}
