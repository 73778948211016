import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

export const CUSTOMER_TYPES = [
  {
    value: "PHISICAL_PERSON_WITHOUT_ECONOMIC_ACTIVITY",
    label: `${translate(
      TRANSLATIONS
        .CUSTOMER_TYPE__PHISICAL_PERSON_WITHOUT_ECONOMIC_ACTIVITY_LABEL.key
    )}`
  },
  {
    value: "PHISICAL_PERSON_WITH_ECONOMIC_ACTIVITY",
    label: `${translate(
      TRANSLATIONS.CUSTOMER_TYPE__PHISICAL_PERSON_WITH_ECONOMIC_ACTIVITY_LABEL
        .key
    )}`
  },
  {
    value: "LEGAL_PERSON",
    label: `${translate(TRANSLATIONS.CUSTOMER_TYPE__LEGAL_PERSON_LABEL.key)}`
  },
  // {
  //   value: "PARTNERSHIP",
  //   label: `${translate(TRANSLATIONS.CUSTOMER_TYPE__PARTNERSHIP_LABEL.key)}`
  // },
];
