import { useCallback, useState } from "react";
import userHelper from "../infra/userHelper";
import { Button } from "antd";
import PERMISSIONS from "../infra/permissions";
import BusTest from "../components/BusTest";
import useBus from "use-bus";
import { MaskedInput } from "antd-mask-input";

export default function Dashboard({ user, setUser }) {
  const [inputFromTest, setInputFromTest] = useState("");

  const hasPermission = useCallback(
    (permissionKey) => {
      return userHelper.hasPermission(user, permissionKey);
    },
    [user]
  );

  // NOTE: proste, sluchamy na event i definijuemy callback ktory przyjmuje event
  useBus("BUS_TEST", (event) => {
    console.log("GOT EVENT!", event);
    // bierzem se dane .payload i beng
    setInputFromTest(event.payload.input);
  });

  return (
    <div>
      <h1>Dashboard</h1>
      <h4>Value in dashboard from BusTest: {inputFromTest}</h4>
      <BusTest />
      {hasPermission(PERMISSIONS.CANDO) && <Button>U can see mee :)</Button>}

      <MaskedInput
        maskOptions={{
          //  https://imask.js.org/guide.html#masked-pattern
          // "+55(00)0000-0000"
          mask: "00-000",
          lazy: true,
          max: 6,
        }}
        onChange={(e) => console.log(e.target.value)}
      />
    </div>
  );
}
