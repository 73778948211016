import React, { useEffect, useState } from "react";
import api from "../../infra/api";
import translate from "../../translate";

import useQueryParams from "../../hooks/useQueryParams.hook";

import { useNavigate, useSearchParams } from "react-router-dom";
import useBus from "use-bus";

import { Divider, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import AgentTeamsFilters from "../../components/agentTeam/AgentTeamsFilters";
import AgentTeamsTable from "../../components/agentTeam/AgentTeamsTable";

import { TRANSLATIONS } from "../../translate/translations";

export default function AgentTeamsPage({ user }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { queryParams, setQueryParams } = useQueryParams();
  const [filters, setFilters] = useState({
    name_contains: "",
    agent_last_name_contains: "",
  });
  const [agentTeams, setAgentTeams] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    current: 1,
    next: null,
    prev: null,
  });
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState("-id");

  const fetchAgentTeams = async ({ page, size, order_by, filters }) => {
    setLoading(true);
    const queryFilters = `name_contains=${
      filters.name_contains ?? ""
    }&agent_last_name_contains=${filters.agent_last_name_contains ?? ""}`;
    api.agentTeams
      .getAgentTeams({
        page,
        size,
        order_by,
        queryFilters,
      })
      .then((response) => {
        const data = response.data;
        const pag = data.pagination;
        const res = data.results.map((result) => ({
          ...result,
          key: result.id,
        }));

        setPagination(pag);
        setAgentTeams(res);
        setPageNumber(pag.current);
        setLoading(false);
      })
      .catch((error) => {
        console.log("agent teams page error", error);
      });
  };

  useEffect(() => {
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setFilters((oldFilters) => {
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains = paramsFromUrl.name_contains ?? "";
      newFilters.agent_last_name_contains =
        paramsFromUrl.agent_last_name_contains ?? "";
      const data = {
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      fetchAgentTeams(data);
      return newFilters;
    });
  }, [orderBy, pageNumber, pageSize, queryParams, searchParams]);

  useEffect(() => {
    console.log("AgentTeamsPage mounted ...");
  }, []);

  // TODO: do przerobienia na jakieś lepsze rozwiązanie
  useBus("AGENT_TEAM_DEACTIVATED", (event) => {
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setFilters((oldFilters) => {
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains =
        paramsFromUrl.name_contains ?? oldFilters.name_contains;
      newFilters.agent_last_name_contains =
        paramsFromUrl.agent_last_name_contains ??
        oldFilters.agent_last_name_contains;
      const data = {
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      fetchAgentTeams(data);
      return newFilters;
    });
  });

  return (
    <>
      <h1 id={TRANSLATIONS.AGENT_TEAM_LIST__TITLE.key}>
        {translate(TRANSLATIONS.AGENT_TEAM_LIST__TITLE.key)}
      </h1>

      <Divider />

      <AgentTeamsFilters
        user={user}
        options={{
          pageNumber,
          pageSize,
          orderBy,
          filters,
          setFilters,
        }}
      />

      <Row
        justify="space-between"
        style={{
          marginTop: 30,
          marginBottom: 15,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Col>
          <Button
            id={
              TRANSLATIONS.AGENT_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL.key
            }
            type="info"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => navigate("/agent-teams/create")}
          >
            {translate(
              TRANSLATIONS.AGENT_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL.key
            )}
          </Button>
        </Col>
      </Row>

      <AgentTeamsTable
        user={user}
        tableStateChanged={(newPageNumber, newPageSize, newOrderBy) => {
          setPageNumber(newPageNumber);
          setPageSize(newPageSize);
          setOrderBy(newOrderBy);
          setQueryParams("/agent-teams", {
            page: newPageNumber,
            size: newPageSize,
            order_by: newOrderBy,
            name_contains: filters.name_contains,
            agent_last_name_contains: filters.agent_last_name_contains,
          });
        }}
        options={{
          pagination,
          pageSize,
          items: agentTeams,
        }}
        loading={loading}
      />
    </>
  );
}
