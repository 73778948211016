import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../../infra/api";
import { dispatch } from "use-bus";

import { Descriptions, Spin } from "antd";

function BrokerData({ personalData }) {
  return (
    <div>
      <div>{personalData.company_name ?? `${personalData.first_name} ${personalData.last_name}`}</div>
      <div>{personalData.email}</div>
      <div>{personalData.phone ?? ""}</div>
    </div>
  )
};

export default function CustomerBrokerTeam({ customer }) {
  const navigate = useNavigate();
  const [brokers, setBrokers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCusomerBrokers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCusomerBrokers = async () => {
    startLoading();
    await api.customers
      .searchCustomerBrokers(`customer=${customer.id}`)
      .then((response) => {
        setBrokers(response.data.results);
        console.log("CustomerBrokerTeam brokers", response.data.results);
        stopLoading();
      })
      .catch(error => {
        stopLoading();
        if (error?.response.status === 400) {
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas wczytywania danych zespołu brokerskiego dla klienta "${customer.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwera",
            `Wystąpił błąd podczas wczytywania danych zespołu brokerskiego dla klienta "${customer.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas wczytywania danych zespołu brokerskiego dla klienta "${customer.name}"`
          );
          navigate("/403");
        }
      });
  };

  const startLoading = () => {
    setLoading(true);
  }

  const stopLoading = () => {
    setLoading(false);
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  const items = [
    {
      key: "name",
      label: "Nazwa",
      children: customer.broker_team.name,
      span: 3,
    },
    {
      key: "keeper",
      label: "Opiekun",
      children: brokers.filter((customerBroker) => customerBroker.role === "KEEPER")
        .map((customerBroker, index) => <BrokerData key={index} personalData={customerBroker.broker.personal_data} />),
      span: 3,
    },
    {
      key: "correspondent",
      label: "Korespondent",
      children: brokers.filter((customerBroker) => customerBroker.role === "CORRESPONDENT")
        .map((customerBroker, index) => <BrokerData key={index} personalData={customerBroker.broker.personal_data} />),
      span: 3,
    },
  ];

  return (
    <>
      {!brokers.length > 0 && (
        <Spin tip="Wczytywanie danych..." spinning={loading} style={{ marginTop: 25 }}>
          <div />
        </Spin>
      )}

      {brokers.length > 0 && (
        <Descriptions bordered size="large" items={items} />
      )}
    </>
  );
}
