import { useEffect } from "react";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import { Table, Space, Tooltip } from "antd";

import GeneralContractDetailsButton from "./action/GeneralContractDetailsButton";

export default function GeneralContractsTable({
  user,
  tableStateChanged,
  options,
  loading,
}) {
  const pageSizeOptions = [10, 15, 25, 50, 100, 500];
  const sortTooltipDescription = {
    asc: `${translate(TRANSLATIONS.TABLE__HEADERS_SORTING_ASCENDING_LABEL.key)}`,
    desc: `${translate(TRANSLATIONS.TABLE__HEADERS_SORTING_DESCENDING_LABEL.key)}`,
    cancel: `${translate(TRANSLATIONS.TABLE__HEADERS_SORTING_CANCEL_LABEL.key)}`,
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 50,
      hidden: true,
    },
    {
      title: `${translate(TRANSLATIONS.GENERAL_CONTRACT_LIST_TABLE__HEADERS_NAME_LABEL.key)}`,
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      fixed: "left",
      width: 350,
      render: (name) => {
        if (name.length > 60) {
          return (
            <Tooltip
              placement="topLeft"
              title={name}
              destroyTooltipOnHide={true}
              overlayStyle={{ maxWidth: "525px" }}
              mouseEnterDelay={0.5}
            >
              {name}
            </Tooltip>
          );
        } else {
          return <span>{name}</span>;
        }
      },
    },

    {
      title: `${translate(TRANSLATIONS.TABLE__HEADERS_ACTIONS_LABEL.key)}`,
      key: "actions",
      render: (_, record) => (
        // record = user data, have access to obj attributes
        <Space size="middle">
          <GeneralContractDetailsButton record={record} />
        </Space>
      ),
      fixed: "right",
      width: 200,
    },
  ];

  useEffect(() => {
    console.log("GeneralContractsTable mounted ...");
    console.log("options items", options.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      id="general-contracts"
      size="large"
      virtual
      columns={columns}
      dataSource={options.items ?? []}
      pagination={{
        position: ["topRight", "bottomRight"],
        current: options.pagination.current,
        defaultCurrent: 1,
        defaultPageSize: options.pageSize,
        pageSize: options.pageSize,
        total: options.pagination.count,
        pageSizeOptions: pageSizeOptions,
        showTotal: (total) => `Total ${total} items`,
        showSizeChanger: true,
      }}
      loading={loading}
      showSorterTooltip={true}
      locale={{
        triggerAsc: sortTooltipDescription.asc,
        triggerDesc: sortTooltipDescription.desc,
        cancelSort: sortTooltipDescription.cancel,
      }}
      rowKey="id"
      bordered
      scroll={{
        y: 1000,
        x: 1300,
      }}
      onChange={(pagination, filters, sorter) => {
        // TODO: nałożenie aktualnego starnu sortowania z urla
        // ... kolumna ma atrubyt sortOrder, zapewne trzeba bedzie go ustawić po wejściu na urla
        // ... wartości przyjmowane przez sortOrder - "ascend", "descend", null
        const sortedOrder = sorter.order ?? "";
        const sortedField = sorter.columnKey ?? "";
        const orderBy =
          sortedOrder === "ascend"
            ? `-${sortedField}`
            : sortedOrder === "descend"
              ? `${sortedField}`
              : "-id";
        tableStateChanged(pagination.current, pagination.pageSize, orderBy);
      }}
    />
  );
}
