import React, { useEffect } from "react";
import translate from "../../translate";

import { Table, Space, Tooltip, Badge, Empty } from "antd";

import AgentTeamDeactivationModal from "./action/AgentTeamDeactivationModal";
import AgentTeamDetailsButton from "./action/AgentTeamDetailsButton";
import AgentTeamEditButton from "./action/AgentTeamEditButton";

import { TRANSLATIONS } from "../../translate/translations";

export default function AgentTeamsTable({
  user,
  tableStateChanged,
  options,
  loading,
}) {
  const pageSizeOptions = [10, 15, 25, 50, 100, 500];
  const sortTooltipDescription = {
    asc: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL.key)}`,
    desc: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL.key)}`,
    cancel: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL.key)}`,
  };
  const statusesDict = {
    active: {
      text: "ACTIVE",
      translation: translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__STATUSES_ACTIVE_LABEL.key),
    },
    inactive: {
      text: "INACTIVE",
      translation: translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__STATUSES_INACTIVE_LABEL.key),
    },
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 50,
      hidden: true,
    },
    {
      title: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_NAME_LABEL.key)}`,
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      fixed: "left",
      width: 250,
      render: (name) => {
        if (name.length > 60) {
          return (
            <Tooltip
              placement="topLeft"
              title={name}
              destroyTooltipOnHide={true}
              overlayStyle={{ maxWidth: "525px" }}
              mouseEnterDelay={0.5}
            >
              {name}
            </Tooltip>
          );
        } else {
          return <span>{name}</span>;
        }
      },
    },
    {
      title: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_STATUS_LABEL.key)}`,
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status) => <>
        <Badge
          status={status === statusesDict.active.text ? "success" : "error"}
          text={statusesDict[status.toLowerCase()].translation}
        />
      </>,
    },

    {
      title: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_LEADER_LABEL.key)}`,
      dataIndex: "leader",
      key: "leader",
      width: 200,
      render: (leader) => 
      <span>
        <span>{leader.first_name} {leader.last_name}</span>
        <br />
        <span>{leader.email}</span>
        {leader.phone &&
          <span>
            <br />
            <span>{leader.phone}</span>
          </span>
        }
      </span>,
    },
    {
      title: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_TEAM_SIZE_LABEL.key)}`,
      dataIndex: "agent_count",
      key: "agent_count",
      width: 75,
      render: (agent_count) => <span>{agent_count ?? 0}</span>,
    },
    {
      title: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_POLICIES_COUNT_LABEL.key)}`,
      dataIndex: "policies_count",
      key: "policies_count",
      width: 100,
      render: (policies_count) => <span>{policies_count ?? 0}</span>,
      hidden: true,
    },
    {
      title: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_TEAM_NOTE_LABEL.key)}`,
      dataIndex: "team_note",
      key: "team_note",
      width: 100,
      render: (team_note) => <span>{team_note ?? 0}</span>,
      hidden: true,
    },
    {
      title: `${translate(TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_ACTIONS_LABEL.key)}`,
      key: "actions",
      render: (_, record) => (
        // record = user data, have access to obj attributes
        <Space size="middle">
          <AgentTeamDetailsButton record={record} />
          <AgentTeamEditButton record={record} />
          {record.status !== statusesDict.inactive.text &&
            <AgentTeamDeactivationModal record={record} />
          }
        </Space>
      ),
      fixed: "right",
      width: 150,
    },
  ];

  useEffect(() => {
    console.log("AgentTeamsTable mounted ...");
  }, []);

  return (
    <Table
      id="agent-teams"
      size="large"
      virtual
      columns={columns}
      dataSource={options.items ?? []}
      pagination={{
        position: ["topRight", "bottomRight"],
        current: options.pagination.current,
        defaultCurrent: 1,
        defaultPageSize: options.pageSize,
        pageSize: options.pageSize,
        total: options.pagination.count,
        pageSizeOptions: pageSizeOptions,
        showTotal: (total) => `Total ${total} items`,
        showSizeChanger: true,
        // onChange: (newPageNumber, newPageSize) => {
        //   tableStateChanged(newPageNumber, newPageSize);
        // },
      }}
      loading={loading}
      showSorterTooltip={true}
      locale={{
        triggerAsc: sortTooltipDescription.asc,
        triggerDesc: sortTooltipDescription.desc,
        cancelSort: sortTooltipDescription.cancel,
        emptyText: <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={`${translate(TRANSLATIONS.TEAM_MEMBERS_CARD__EMPTY_LIST_TITLE.key)}`}
        />
      }}
      rowKey="id"
      bordered
      scroll={{
        y: 1000,
        x: 1300,
      }}
      onChange={(pagination, filters, sorter) => {
        // TODO: nałożenie aktualnego starnu sortowania z urla
        // ... kolumna ma atrubyt sortOrder, zapewne trzeba bedzie go ustawić po wejściu na urla
        // ... wartości przyjmowane przez sortOrder - "ascend", "descend", null
        const sortedOrder = sorter.order ?? "";
        const sortedField = sorter.columnKey ?? "";
        const orderBy =
          sortedOrder === "ascend"
            ? `-${sortedField}`
            : sortedOrder === "descend"
            ? `${sortedField}`
            : "-id";
        tableStateChanged(pagination.current, pagination.pageSize, orderBy);
      }}
    />
  );
}
