import { useMemo, useCallback } from "react";
import { Link } from "react-router-dom";

import userHelper from "../infra/userHelper";

import {
  ApartmentOutlined,
  BarChartOutlined,
  SettingOutlined,
  TeamOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
const { Sider } = Layout;

export default function SideNavbar({ collapsed, navigate, user, setUser }) {
  const hasRole = useCallback(
    (roleName) => {
      return userHelper.hasRole(user, roleName);
    },
    [user]
  );

  // NOTE: to samo co wyzej tylko dla permissionow
  // const hasPermission = useCallback(
  //   (permissionKey) => {
  //     return userHelper.hasPermission(user, permissionKey);
  //   },
  //   [user]
  // );

  const items = useMemo(
    () => {
      console.log("user", user);
      const baseItems = [
        {
          key: "dashboard",
          icon: <BarChartOutlined />,
          label: (<Link to={"/dashboard"}>Dashboard</Link>),
        },
        {
          key: "customers",
          icon: <ApartmentOutlined />,
          label: "Klienci",
          children: [
            {
              key: "customer_groups",
              label: (<Link to={"/customer-groups"}>Grupy Klientów</Link>),
            },
            {
              key: "customer_cards",
              label: (<Link to={"/customers"}>Karty Klientów</Link>),
            }
          ]
        },
        {
          key: "general_contracts",
          icon: <AuditOutlined />,
          label: (<Link to={"/general-contracts"}>Umowy Generalne</Link>),
        },
        {
          key: "broker_teams",
          icon: <TeamOutlined />,
          label: (<Link to={"/broker-teams"}>Zespoły brokerskie</Link>),
        },
        {
          key: "agent_teams",
          icon: <TeamOutlined />,
          label: (<Link to={"/agent-teams"}>Zespoły agencyjne</Link>),
        },

      // {
      //   key: "users",
      //   icon: <TeamOutlined />,
      //   label: "Users",
      //   onClick: () => {
      //     navigate("/users");
      //   }
      // }
    ];

    const adminItems = [
      {
        key: "administration",
        icon: <ApartmentOutlined />,
        label: "Administracja",
        children: [
          {
            key: "insurers",
            label: "Ubezpieczyciele",
            onClick: () => {
              navigate("/admin/insurers");
            },
          },
        ],
      },
      {
        key: "settings",
        icon: <SettingOutlined />,
        label: "Ustawienia",
        onClick: () => {
          navigate("/settings");
        },
      },
    ];

    let finalItems = [].concat(baseItems);
    if (hasRole("SYS_ADMIN")) {
      finalItems = finalItems.concat(adminItems);
    }
    return finalItems;
  }, [user, navigate, hasRole]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["dashboard"]}
        defaultOpenKeys={["customers", "administration"]}
        items={items}
      />
    </Sider>
  );
}
