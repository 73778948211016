import { Link } from "react-router-dom";

import { Button, Tooltip } from "antd";
import { EyeTwoTone } from "@ant-design/icons";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

export default function GeneralContractDetailsButton({ record }) {
  return (
    <Tooltip
      title={translate(
        TRANSLATIONS.TABLE__ACTION_DETAILS_BUTTON_LABEL.key
      )}
      destroyTooltipOnHide
      mouseEnterDelay={0.5}
    >
      <Link to={`/general-contracts/${record.id}`}>
        <Button
          id={TRANSLATIONS.TABLE__ACTION_DETAILS_BUTTON_LABEL.key}
          type="text"
          htmlType="button"
          icon={<EyeTwoTone twoToneColor="#17a2b8" />}
        />
      </Link>
    </Tooltip>
  );
}
