import React from "react";
import { Link } from "react-router-dom";

import { Button, Tooltip } from "antd";
import { EditTwoTone } from "@ant-design/icons";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

export default function BrokerTeamEditButton({ record }) {
  return (
    <Tooltip
      title={translate(TRANSLATIONS.BROKER_TEAM_LIST_TABLE__ACTION_UPDATE_BUTTON_LABEL.key)}
      destroyTooltipOnHide
      mouseEnterDelay={0.5}
    >
      <Link to={`/broker-teams/${record.id}/update`}>
        <Button
          id={TRANSLATIONS.BROKER_TEAM_LIST_TABLE__ACTION_UPDATE_BUTTON_LABEL.key}
          type="text"
          htmlType="button"
          icon={<EditTwoTone twoToneColor="#28a745" />} 
        />
      </Link>

    </Tooltip>
  );
}
