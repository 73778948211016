import { useEffect, useMemo, useState } from "react";

import { Row, Col, Form, Select, Checkbox, } from "antd";

import api from "../../../infra/api";

import debounce from "lodash/debounce";
import { MaskedInput } from "antd-mask-input";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {function} props.formHelpers.validateAgainstRegex
 * @param {object} props.formFields
 * @param {string} props.formFields.CUSTOMER
 * @param {string} props.formFields.HANDLING_PERSON_CUSTOMER
 * @param {string} props.formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER
 * @param {string} props.formFields.CUSTOMER_SALARY
 * @param {string} props.formFields.HAS_NOTE
 * @param {string} props.formFields.LEAD_BROKER
 * @param {string} props.formFields.LEAD_BROKER_SALARY
 * @param {string} props.formFields.BROKERAGE
 * @param {string} props.formFields.HANDLING_PERSON_AGENT
 * @param {string} props.formFields.TRAVELING_AGENT
 * @param {string} props.formFields.TRAVELING_AGENT_SALARY
 * @param {string} props.formFields.PROVISION_AGENCY
 * @param {string} props.formFields.OTHER_SALARY
 * @param {object} props.config
 * @param {object} props.config.customers
 * @param {object} props.config.setCustomers
 * @param {object} props.config.customerPersons
 * @param {object} props.config.setCustomerPersons
 * @param {object} props.config.customerBrokers
 * @param {object} props.config.setCustomerBrokers
 * @param {object} props.config.customerAgents
 * @param {object} props.config.setCustomerAgents
 * @returns {JSX.Element}
 * */
export default function CustomersFormSection({
  form,
  formHelpers,
  formFields,
  config,
}) {
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);

  useEffect(() => {
    const customer = form.getFieldValue(formFields.CUSTOMER)
    if (!customer) {
      fetchCustomers({ name_contains: "" });
    }

    if (customer) {
      setIsCustomerSelected(true);
      // TODO: fetch customer details for customer persons, brokers and agents
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomers = useMemo(
    () =>
      debounce((filters) => {
        const query = Object.keys(filters).map(
          (key) => `${key}=${filters[key]}`
        );
        api.customers.searchCustomers(query).then((result) => {
          config.setCustomers(result.data.results);
        });
      }, 500),
    [config]
  );

  // TODO: do helpera wyciągnąć, można wrzucić do helpera z max lengthem
  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form.Item id="general_contract_customer">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.CUSTOMER}
            name={formFields.CUSTOMER}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Klient
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.CUSTOMER,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.CUSTOMER) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.CUSTOMER)
                ? formHelpers.getFieldErrors(formFields.CUSTOMER).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              onSearch={(value) => fetchCustomers({ name_contains: value })}
              size="large"
              placeholder="Wybierz klienta"
              options={config.customers.map((customer) => ({
                value: customer.id,
                label: customer.name,
              }))}
              filterOption={handleFilterOption}
              onChange={(value) => {
                form.setFieldValue(formFields.CUSTOMER, value);
                form.setFieldValue(formFields.HANDLING_PERSON_CUSTOMER, null);
                if (value) {
                  // TODO: fetch customer details for customer persons
                  setIsCustomerSelected(true);
                }
              }}
              onClear={() => {
                form.setFieldValue(formFields.CUSTOMER, null);
                form.setFieldValue(formFields.HANDLING_PERSON_CUSTOMER, null);
                config.setCustomerPersons([]);
                fetchCustomers({ name_contains: "" });
                setIsCustomerSelected(false);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.HANDLING_PERSON_CUSTOMER}
            name={formFields.HANDLING_PERSON_CUSTOMER}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Osoba prowadząca po stronie klienta
              </label>
            }
            required={isCustomerSelected}
            rules={[
              {
                validator: (_, value) => {
                  if (isCustomerSelected) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.HANDLING_PERSON_CUSTOMER,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.HANDLING_PERSON_CUSTOMER);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.HANDLING_PERSON_CUSTOMER) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.HANDLING_PERSON_CUSTOMER)
                ? formHelpers.getFieldErrors(formFields.HANDLING_PERSON_CUSTOMER).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isCustomerSelected}
              placeholder="Wybierz osobę prowadzącą po stronie klienta"
              options={config.customerPersons}
              filterOption={handleFilterOption}
              onClear={() => form.setFieldValue(formFields.HANDLING_PERSON_CUSTOMER, null)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER}
            name={formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Numer konta do zwrotów klienta
              </label>
            }
            required={isCustomerSelected}
            rules={[
              {
                validator: (_, value) => {
                  if (isCustomerSelected) {
                  return formHelpers.validateRequiredGuiField(
                    formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER,
                    value,
                    "Pole wymagane"
                  );
                }
                formHelpers.resetFieldGuiError(formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER);
                return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    const finalValue = value.replace(/\s/g, "");
                    return formHelpers.validateAgainstRegex(
                      /^\d{26}$/g,
                      formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER,
                      finalValue,
                      "Numer rachunku musi składać się z 26 cyfr"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER)
                ? formHelpers.getFieldErrors(formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER}
              name={formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER}
              allowClear
              size="large"
              disabled={!isCustomerSelected}
              status={
                formHelpers.errorFields.includes(formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: "00 0000 0000 0000 0000 0000 0000",
                lazy: true,
                max: 32,
              }}
              onChange={(e) => {
                const finalValue = e.target.value.replace(/\s/g, "");
                formHelpers.resetFieldApiError(formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER);
                form.setFieldsValue({ [formFields.CUSTOMER_RETURN_ACCOUNT_NUMBER]: finalValue });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.HAS_NOTE}
            name={formFields.HAS_NOTE}
            valuePropName="checked"
            validateStatus={
              formHelpers.errorFields.includes(formFields.HAS_NOTE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.HAS_NOTE)
                ? formHelpers.getFieldErrors(formFields.HAS_NOTE).join(", ")
                : null
            }
          >
            <Checkbox
              checked={form.getFieldValue([formFields.HAS_NOTE])}
              onChange={(e) => form.setFieldsValue({ [formFields.HAS_NOTE]: e.target.checked })}
            >
              Faktura / Nota składowa
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.CUSTOMER_SALARY}
            name={formFields.CUSTOMER_SALARY}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Wynagrodzenie dla klienta [%]
              </label>
            }
            required={isCustomerSelected}
            rules={[
              {
                validator: (_, value) => {
                  if (isCustomerSelected) {
                  return formHelpers.validateRequiredGuiField(
                    formFields.CUSTOMER_SALARY,
                    value,
                    "Pole wymagane"
                  );
                }
                formHelpers.resetFieldGuiError(formFields.CUSTOMER_SALARY);
                return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.CUSTOMER_SALARY) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.CUSTOMER_SALARY)
                ? formHelpers.getFieldErrors(formFields.CUSTOMER_SALARY).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.CUSTOMER_SALARY}
              name={formFields.CUSTOMER_SALARY}
              allowClear
              size="large"
              disabled={!isCustomerSelected}
              status={
                formHelpers.errorFields.includes(formFields.CUSTOMER_SALARY)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: Number,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: ".",
                mapToRadix: [","],
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.CUSTOMER_SALARY);
                form.setFieldsValue({ [formFields.CUSTOMER_SALARY]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.LEAD_BROKER}
            name={formFields.LEAD_BROKER}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Broker prowadzący
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.LEAD_BROKER) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.LEAD_BROKER)
                ? formHelpers.getFieldErrors(formFields.LEAD_BROKER).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isCustomerSelected}
              placeholder="Wybierz Brokera prowadzącego"
              options={config.customerBrokers}
              filterOption={handleFilterOption}
              onClear={() => form.setFieldValue(formFields.LEAD_BROKER, null)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.LEAD_BROKER_SALARY}
            name={formFields.LEAD_BROKER_SALARY}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Wynagrodzenie Broker prowadzący [%]
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.LEAD_BROKER_SALARY) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.LEAD_BROKER_SALARY)
                ? formHelpers.getFieldErrors(formFields.LEAD_BROKER_SALARY).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.LEAD_BROKER_SALARY}
              name={formFields.LEAD_BROKER_SALARY}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.LEAD_BROKER_SALARY)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: Number,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: ".",
                mapToRadix: [","],
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.LEAD_BROKER_SALARY);
                form.setFieldsValue({ [formFields.LEAD_BROKER_SALARY]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.BROKERAGE}
            name={formFields.BROKERAGE}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Kurtaż Broker [%]
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.BROKERAGE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.BROKERAGE)
                ? formHelpers.getFieldErrors(formFields.BROKERAGE).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.BROKERAGE}
              name={formFields.BROKERAGE}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.BROKERAGE)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: Number,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: ".",
                mapToRadix: [","],
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.BROKERAGE);
                form.setFieldsValue({ [formFields.BROKERAGE]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.HANDLING_PERSON_AGENT}
            name={formFields.HANDLING_PERSON_AGENT}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Agent prowadzący
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.HANDLING_PERSON_AGENT) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.HANDLING_PERSON_AGENT)
                ? formHelpers.getFieldErrors(formFields.HANDLING_PERSON_AGENT).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isCustomerSelected}
              placeholder="Wybierz Agenta prowadzącego"
              options={config.customerAgents}
              filterOption={handleFilterOption}
              onClear={() => form.setFieldValue(formFields.HANDLING_PERSON_AGENT, null)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.PROVISION_AGENCY}
            name={formFields.PROVISION_AGENCY}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Prowizja Agencja [%]
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.PROVISION_AGENCY) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.PROVISION_AGENCY)
                ? formHelpers.getFieldErrors(formFields.PROVISION_AGENCY).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.PROVISION_AGENCY}
              name={formFields.PROVISION_AGENCY}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.PROVISION_AGENCY)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: Number,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: ".",
                mapToRadix: [","],
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.PROVISION_AGENCY);
                form.setFieldsValue({ [formFields.PROVISION_AGENCY]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.TRAVELING_AGENT}
            name={formFields.TRAVELING_AGENT}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Akwizytor
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.TRAVELING_AGENT) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.TRAVELING_AGENT)
                ? formHelpers.getFieldErrors(formFields.TRAVELING_AGENT).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isCustomerSelected}
              placeholder="Wybierz Akwizytora"
              options={config.customerAgents}
              filterOption={handleFilterOption}
              onClear={() => form.setFieldValue(formFields.TRAVELING_AGENT, null)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.TRAVELING_AGENT_SALARY}
            name={formFields.TRAVELING_AGENT_SALARY}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Wynagrodzenie Akwizytor [%]
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.TRAVELING_AGENT_SALARY) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.TRAVELING_AGENT_SALARY)
                ? formHelpers.getFieldErrors(formFields.TRAVELING_AGENT_SALARY).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.TRAVELING_AGENT_SALARY}
              name={formFields.TRAVELING_AGENT_SALARY}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.TRAVELING_AGENT_SALARY)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: Number,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: ".",
                mapToRadix: [","],
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.TRAVELING_AGENT_SALARY);
                form.setFieldsValue({ [formFields.TRAVELING_AGENT_SALARY]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.OTHER_SALARY}
            name={formFields.OTHER_SALARY}
            initialValue={null}
            label={
              <label style={{ fontWeight: "bold" }}>
                Wynagrodzenie inni [%]
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.OTHER_SALARY) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.OTHER_SALARY)
                ? formHelpers.getFieldErrors(formFields.OTHER_SALARY).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.OTHER_SALARY}
              name={formFields.OTHER_SALARY}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.OTHER_SALARY)
                  ? "error"
                  : null
              }
              maskOptions={{
                mask: Number,
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: ".",
                mapToRadix: [","],
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.OTHER_SALARY);
                form.setFieldsValue({ [formFields.OTHER_SALARY]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
}
