import { useEffect, useMemo, useState } from "react";

import { Row, Col, Form, Input, Select, DatePicker } from "antd";
import { MaskedInput } from "antd-mask-input";

import api from "../../../infra/api";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import debounce from "lodash/debounce";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {function} props.formHelpers.validateAgainstRegex
 * @param {object} props.formFields
 * @param {string} props.formFields.NUMBER
 * @param {string} props.formFields.INSURER
 * @param {string} props.formFields.INSURER_DEPOT
 * @param {string} props.formFields.START_DATE
 * @param {string} props.formFields.START_DATE_AS_STRING
 * @param {string} props.formFields.HANDLING_PERSON_INSURER
 * @param {string} props.formFields.HANDLING_PERSON_INSURER_FIRST_NAME
 * @param {string} props.formFields.HANDLING_PERSON_INSURER_LAST_NAME
 * @param {string} props.formFields.HANDLING_PERSON_INSURER_PHONE
 * @param {string} props.formFields.HANDLING_PERSON_INSURER_EMAIL
 * @param {object} props.config
 * @param {object} props.config.insurers
 * @param {object} props.config.setInsurers
 * @param {object} props.config.insurerDepots
 * @param {object} props.config.setInsurerDepots
 * @returns {JSX.Element}
 * */
export default function GeneralContractDataFormSection({
  form,
  formHelpers,
  formFields,
  config,
}) {
  const [isInsurerSelected, setIsInsurerSelected] = useState(false);

  useEffect(() => {
    const insurer = form.getFieldValue(formFields.INSURER)
    if (!insurer) {
      fetchInsurers({ name_contains: "" });
    }

    if (insurer) {
      setIsInsurerSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInsurers = useMemo(
    () =>
      debounce((filters) => {
        const query = Object.keys(filters).map(
          (key) => `${key}=${filters[key]}`
        );
        api.insurers.searchInsurers(query).then((result) => {
          config.setInsurers(result.data.results);
        });
      }, 500),
    [config]
  );

  const fetchInsurerDepots = (insurer) => {
    api.insurers
      .searchDepots(`insurer=${insurer}`)
      .then((result) => {
        config.setInsurerDepots(result.data.results.map((depot) => ({
          value: depot.id,
          label: depot.name,
        })))
      });
  };

  // TODO: do helpera wyciągnąć, można wrzucić do helpera z max lengthem
  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form.Item id="general_contract_data">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.NUMBER}
            name={formFields.NUMBER}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.GENERAL_CONTRACT_NUMBER__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.GENERAL_CONTRACT_NUMBER__LABEL.key)}
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.NUMBER,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.NUMBER) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.NUMBER)
                ? formHelpers.getFieldErrors(formFields.NUMBER).join(", ")
                : null
            }
          >
            <Input
              id={formFields.NUMBER}
              name={formFields.NUMBER}
              allowClear
              size="large"
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.NUMBER);
                form.setFieldValue(formFields.NUMBER, e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.START_DATE}
            name={formFields.START_DATE}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.GENERAL_CONTRACT_START_DATE__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.GENERAL_CONTRACT_START_DATE__LABEL.key)}
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.START_DATE,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.START_DATE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.START_DATE)
                ? formHelpers.getFieldErrors(formFields.START_DATE).join(", ")
                : null
            }
          >
            <DatePicker
              allowClear
              size="large"
              format="YYYY-MM-DD"
              placeholder="Wybierz datę rozpoczęcia"
              onChange={(date, dateString) => {
                formHelpers.resetFieldApiError(formFields.START_DATE);
                form.setFieldValue(formFields.START_DATE, date);
                form.setFieldValue(formFields.START_DATE_AS_STRING, dateString);
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.INSURER}
            name={formFields.INSURER}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.GENERAL_CONTRACT_INSURER__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.GENERAL_CONTRACT_INSURER__LABEL.key)}
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.INSURER,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.INSURER) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.INSURER)
                ? formHelpers.getFieldErrors(formFields.INSURER).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              onSearch={(value) => fetchInsurers({ name_contains: value })}
              size="large"
              placeholder="Wybierz ubezpieczyciela"
              options={config.insurers.map((insurer) => ({
                value: insurer.id,
                label: insurer.name,
              }))}
              filterOption={handleFilterOption}
              onChange={(value) => {
                form.setFieldValue(formFields.INSURER, value);
                form.setFieldValue(formFields.INSURER_DEPOT, null);
                if (value) {
                  fetchInsurerDepots(value);
                  setIsInsurerSelected(true);
                }
              }}
              onClear={() => {
                form.setFieldValue(formFields.INSURER, null);
                form.setFieldValue(formFields.INSURER_DEPOT, null);
                fetchInsurers({ name_contains: "" });
                config.setInsurerDepots([]);
                setIsInsurerSelected(false);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.INSURER_DEPOT}
            name={formFields.INSURER_DEPOT}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.GENERAL_CONTRACT_INSURER_DEPOT__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.GENERAL_CONTRACT_INSURER_DEPOT__LABEL.key)}
              </label>
            }
            required={isInsurerSelected}
            rules={[
              {
                validator: (_, value) => {
                  if (isInsurerSelected) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.INSURER_DEPOT,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.INSURER_DEPOT);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.INSURER_DEPOT) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.INSURER_DEPOT)
                ? formHelpers.getFieldErrors(formFields.INSURER_DEPOT).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isInsurerSelected}
              placeholder="Wybierz oddział ubezpieczyciela"
              options={config.insurerDepots}
              filterOption={handleFilterOption}
              onClear={() => form.setFieldValue(formFields.INSURER_DEPOT, null)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.HANDLING_PERSON_INSURER}
            label={
              <label
                id={TRANSLATIONS.GENERAL_CONTRACT_HANDLING_PERSON_INSURER__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.GENERAL_CONTRACT_HANDLING_PERSON_INSURER__LABEL.key)}
              </label>
            }
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                <Form.Item
                  id={formFields.HANDLING_PERSON_INSURER_FIRST_NAME}
                  name={formFields.HANDLING_PERSON_INSURER_FIRST_NAME}
                  initialValue={null}
                  label={
                    <label
                      id={TRANSLATIONS.GENERAL_CONTRACT__HANDLING_PERSON_INSURER_FIRST_NAME__LABEL.key}
                      style={{ fontWeight: "bold" }}
                    >
                      {translate(TRANSLATIONS.GENERAL_CONTRACT__HANDLING_PERSON_INSURER_FIRST_NAME__LABEL.key)}
                    </label>
                  }
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        return formHelpers.validateRequiredGuiField(
                          formFields.HANDLING_PERSON_INSURER_FIRST_NAME,
                          value,
                          "Pole wymagane"
                        );
                      },
                    },
                  ]}
                  validateStatus={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_FIRST_NAME)
                      ? "error"
                      : null
                  }
                  help={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_FIRST_NAME)
                      ? formHelpers.getFieldErrors(formFields.HANDLING_PERSON_INSURER_FIRST_NAME).join(", ")
                      : null
                  }
                  normalize={(value) => value.trimStart()}
                >
                  <Input
                    id={formFields.HANDLING_PERSON_INSURER_FIRST_NAME}
                    name={formFields.HANDLING_PERSON_INSURER_FIRST_NAME}
                    allowClear
                    size="large"
                    onChange={(e) => {
                      formHelpers.resetFieldApiError(formFields.HANDLING_PERSON_INSURER_FIRST_NAME);
                      form.setFieldValue(formFields.HANDLING_PERSON_INSURER_FIRST_NAME, e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                <Form.Item
                  id={formFields.HANDLING_PERSON_INSURER_LAST_NAME}
                  name={formFields.HANDLING_PERSON_INSURER_LAST_NAME}
                  initialValue={null}
                  label={
                    <label
                      id={TRANSLATIONS.GENERAL_CONTRACT__HANDLING_PERSON_INSURER_LAST_NAME__LABEL.key}
                      style={{ fontWeight: "bold" }}
                    >
                      {translate(TRANSLATIONS.GENERAL_CONTRACT__HANDLING_PERSON_INSURER_LAST_NAME__LABEL.key)}
                    </label>
                  }
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        return formHelpers.validateRequiredGuiField(
                          formFields.HANDLING_PERSON_INSURER_LAST_NAME,
                          value,
                          "Pole wymagane"
                        );
                      },
                    },
                  ]}
                  validateStatus={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_LAST_NAME)
                      ? "error"
                      : null
                  }
                  help={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_LAST_NAME)
                      ? formHelpers.getFieldErrors(formFields.HANDLING_PERSON_INSURER_LAST_NAME).join(", ")
                      : null
                  }
                  normalize={(value) => value.trimStart()}
                >
                  <Input
                    id={formFields.HANDLING_PERSON_INSURER_LAST_NAME}
                    name={formFields.HANDLING_PERSON_INSURER_LAST_NAME}
                    allowClear
                    size="large"
                    onChange={(e) => {
                      formHelpers.resetFieldApiError(formFields.HANDLING_PERSON_INSURER_LAST_NAME);
                      form.setFieldValue(formFields.HANDLING_PERSON_INSURER_LAST_NAME, e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                <Form.Item
                  id={formFields.HANDLING_PERSON_INSURER_PHONE}
                  name={formFields.HANDLING_PERSON_INSURER_PHONE}
                  initialValue={null}
                  label={
                    <label
                      id={TRANSLATIONS.GENERAL_CONTRACT__HANDLING_PERSON_INSURER_PHONE__LABEL.key}
                      style={{ fontWeight: "bold" }}
                    >
                      {translate(TRANSLATIONS.GENERAL_CONTRACT__HANDLING_PERSON_INSURER_PHONE__LABEL.key)}
                    </label>
                  }
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        return formHelpers.validateRequiredGuiField(
                          formFields.HANDLING_PERSON_INSURER_PHONE,
                          value,
                          "Pole wymagane"
                        );
                      },
                    },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const finalValue = value.replace(/\s/g, "");
                          return formHelpers.validateAgainstRegex(
                            /^\d{9}$/g,
                            formFields.HANDLING_PERSON_INSURER_PHONE,
                            finalValue,
                            "Telefon musi być w formacie 000 000 000"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  validateStatus={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_PHONE) ? "error" : null
                  }
                  help={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_PHONE)
                      ? formHelpers.getFieldErrors(formFields.HANDLING_PERSON_INSURER_PHONE).join(", ")
                      : null
                  }
                >
                  <MaskedInput
                    id={formFields.HANDLING_PERSON_INSURER_PHONE}
                    name={formFields.HANDLING_PERSON_INSURER_PHONE}
                    allowClear
                    size="large"
                    status={
                      formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_PHONE)
                        ? "error"
                        : null
                    }
                    count={{
                      show: true,
                      max: 11,
                    }}
                    maskOptions={{
                      mask: "000 000 000",
                      lazy: true,
                      max: 11,
                    }}
                    onChange={(e) => {
                      const finalValue = e.target.value.replace(/\s/g, "");
                      formHelpers.resetFieldApiError(formFields.HANDLING_PERSON_INSURER_PHONE);
                      form.setFieldsValue({ [formFields.HANDLING_PERSON_INSURER_PHONE]: finalValue });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                <Form.Item
                  id={formFields.HANDLING_PERSON_INSURER_EMAIL}
                  name={formFields.HANDLING_PERSON_INSURER_EMAIL}
                  initialValue={null}
                  label={
                    <label
                      id={TRANSLATIONS.CUSTOMER_EMAIL__LABEL.key}
                      style={{ fontWeight: "bold" }}
                    >
                      {translate(TRANSLATIONS.CUSTOMER_EMAIL__LABEL.key)}
                    </label>
                  }
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        return formHelpers.validateRequiredGuiField(
                          formFields.HANDLING_PERSON_INSURER_EMAIL,
                          value,
                          "Pole wymagane"
                        );
                      },
                    },
                    {
                      validator: (_, value) => {
                        if (value) {
                          return formHelpers.validateAgainstRegex(
                            /^[a-zA-Z\d._-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/g,
                            formFields.HANDLING_PERSON_INSURER_EMAIL,
                            value,
                            "Należy wprowadzić poprawny adres e-mail"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  validateStatus={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_EMAIL)
                      ? "error"
                      : null
                  }
                  help={
                    formHelpers.errorFields.includes(formFields.HANDLING_PERSON_INSURER_EMAIL)
                      ? formHelpers.getFieldErrors(formFields.HANDLING_PERSON_INSURER_EMAIL).join(", ")
                      : null
                  }
                  normalize={(value) => value.trimStart()}
                >
                  <Input
                    id={formFields.HANDLING_PERSON_INSURER_EMAIL}
                    name={formFields.HANDLING_PERSON_INSURER_EMAIL}
                    allowClear
                    size="large"
                    onChange={(e) => {
                      formHelpers.resetFieldApiError(formFields.HANDLING_PERSON_INSURER_EMAIL);
                      form.setFieldValue(formFields.HANDLING_PERSON_INSURER_EMAIL, e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
}
