import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Divider } from "antd";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import UseForm from "../../hooks/useForm";
import CreateAgencyTeamFormSection from "../../components/agentTeam/create/CreateAgencyTeamFormSection";

export default function AgentTeamCreatePage({ user }) {
  const navigate = useNavigate();
  const {
    form,
    formHelpers,
    formFields,
  } = UseForm({
    NAME: "name",
    LEADER: "leader",
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("AgentTeamCreatePage mounted...");
  }, []);

  const submit = async (payload) => {
    setLoading(true);

    api.agentTeams
      .createAgentTeam(payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano zespół "${payload.name}"`
        );
        setLoading(false);
        navigate(`/agent-teams/${response.data.id}`);
      })
      .catch((error) => {
        setLoading(false);

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        if (error?.response.status === 400) {
          form.setApiErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania zespołu "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas dodawania zespołu "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania zespołu "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  return (
    <>
      <h1 id={TRANSLATIONS.AGENT_TEAM_CREATE__TITLE.key}>
        {translate(TRANSLATIONS.AGENT_TEAM_CREATE__TITLE.key)}
      </h1>

      <Divider />

      <Form
        id="agent_team_create_form"
        name="agent_team_create_form"
        form={form}
        autoComplete="off"
        layout="vertical"
        preserve={false}
        disabled={+loading}
        onFinish={(values) => submit({
          name: form.getFieldValue(formFields.NAME).trim(),
          leader: form.getFieldValue(formFields.LEADER),
        })}
      >
        <CreateAgencyTeamFormSection
          form={form}
          formHelpers={formHelpers}
          formFields={formFields}
        />

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key}
            type="success"
            htmlType="submit"
            size="large"
          >
            {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
