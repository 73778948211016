import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../../infra/api";
import translate from "../../../translate";

import { Modal, Tooltip, Divider } from "antd";
import { MinusCircleTwoTone } from "@ant-design/icons";

import { dispatch } from "use-bus";
import { TRANSLATIONS } from "../../../translate/translations";

export default function AgentTeamMemberRemoveModal({ member }) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const startLoading = () => {
		setLoading(true);
	};

	const stopLoading = () => {
		setLoading(false);
	};

	const submit = async (payload) => {
		startLoading();
		api.agents
			.deleteAgent(payload.id)
			.then(response => {
				stopLoading();
				closeModal();
				showNotification(
					"success",
					"Operacja zakończona powodzeniem",
					`Pomyślnie usunięto "${payload.memberName}"`
				);
				dispatch({  type: "AGENT_REMOVED" });
			})
			.catch(error => {
				stopLoading();
				if (error?.response.status === 400) {
					showNotification(
						"error",
						"Niepoprawne dane",
						`Wystąpił błąd usuwania "${payload.memberName}"`
					);
				} else if (error?.response.status >= 500) {
					showNotification(
						"error",
						"Błąd serwera",
						`Wystąpił błąd usuwania "${payload.memberName}"`
					);
					navigate("/500");
				} else if (error?.response.status === 403) {
					showNotification(
						"error",
						"Brak uprawnień",
						`Wystąpił błąd podczas usuwania "${payload.memberName}"`
					);
					navigate("/403");
				}
			});
	};

	const showNotification = (type, title, description) => {
		dispatch({
			type: "SHOW_NOTIFICATION",
			payload: {
				notification: {
					type,
					title,
					description,
				}
			}
		});
	};

	return (
		<>
			<Tooltip
				title={translate(TRANSLATIONS.AGENT_TEAM_MEMBERS__REMOVE_BUTTON_LABEL.key)}
				destroyTooltipOnHide
				mouseEnterDelay={0.5}
			>
				<MinusCircleTwoTone key="remove" twoToneColor="#ff4d4f" onClick={openModal} />
			</Tooltip>

			<Modal
				id="remove-member-modal"
				title={
					<h3 id={TRANSLATIONS.TEAM_MEMBER_REMOVE__MODAL_TITLE.key}>
						{translate(TRANSLATIONS.TEAM_MEMBER_REMOVE__MODAL_TITLE.key)}
						<br />
						"{member.title}"?
					</h3>
				}
				style={{ textAlign: "center" }}
				centered
				destroyOnClose={true}
				maskClosable={false}
				closable={false}
				keyboard={true}
				open={+open}
				okText={
					loading
						? translate(TRANSLATIONS.TEAM_MEMBER_REMOVE__MODAL_REMOVING_BUTTON_LABEL.key)
						: translate(TRANSLATIONS.TEAM_MEMBER_REMOVE__MODAL_CONFIRM_BUTTON_LABEL.key)
				}
				okButtonProps={{
					type: "primary",
					danger: true,
					size: "large",
					htmlType: "submit",
					loading: loading,
					disabled: loading
				}}
				onOk={() => submit({
					id: member.id,
					memberName: member.title,
				})}
				cancelText={translate(TRANSLATIONS.TEAM_MEMBER_REMOVE__MODAL_CANCEL_BUTTON_LABEL.key)}
				cancelButtonProps={{
					type: "lightdark",
					ghost: true,
					size: "large",
					disabled: loading
				}}
				onCancel={closeModal}
			>
				<Divider />
			</Modal>
		</>
	);
}
