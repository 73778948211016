import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import api from "../../../infra/api";
import { dispatch } from "use-bus";

import {
	Button,
	Modal,
	Space,
	Form,
	Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import DebounceSelectPerson from "../../commons/DebounceSelectPerson"

export default function BrokerTeamMemberAddModal({ brokerTeamId }) {
	const navigate = useNavigate();
	const [apiValidationErrors, setApiValidationErrors] = useState({});
	const [guiValidationErrors, setGuiValidationErrors] = useState({});

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [member, setMember] = useState({
		label: "",
		value: null,
	});

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const startLoading = () => {
		setLoading(true);
	};

	const stopLoading = () => {
		setLoading(false);
	};

	const resetGuiValidationErrors = () => {
		setGuiValidationErrors((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.member = null;
			return newState;
		});
	}
	
	const resetApiValidationErrors = () => {
		setApiValidationErrors((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.broker_team = null;
			return newState;
		})
	}

	const submit = async payload => {
		startLoading();
		api.brokers
			.createBroker({
				broker_team: payload.brokerTeamId,
				personal_data: payload.personalDataId,
			})
			.then(response => {
				stopLoading();
				closeModal();
				showNotification(
					"success",
					"Operacja zakończona powodzeniem",
					`Pomyślnie dodano Brokera "${payload.memberName}"`
				);
				dispatch({ type: "BROKER_ADDED" });
			})
			.catch(error => {
				stopLoading();
				if (error?.response.status === 400) {
					setApiValidationErrors(error.response.data);
					showNotification(
						"error",
						"Niepoprawne dane",
						`Wystąpił błąd podczas dodawania Brokera "${payload.memberName}"`
					);
				} else if (error?.response.status >= 500) {
					showNotification(
						"error",
						"Błąd serwera",
						`Wystąpił błąd podczas dodawania Brokera "${payload.memberName}"`
					);
					navigate("/500");
				} else if (error?.response.status === 403) {
					showNotification(
						"error",
						"Brak uprawnień",
						`Wystąpił błąd podczas dodawania Brokera "${payload.memberName}"`
					);
					navigate("/403");
				}
			});
	};

	const showNotification = (type, title, description) => {
		dispatch({
			type: "SHOW_NOTIFICATION",
			payload: {
				notification: {
					type,
					title,
					description,
				}
			}
		});
	};

	const setMemberBroker = (id, name) => {
		setMember((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.label = name;
			newState.value = id;
			return newState;
		});
	};

	const fetchBrokers = async (searchValue) => {
		return await fetchUsers(
			[
				"SYS_ADMIN_BROKER",
				"BROKER_LIDER",
				"BROKER_MERCHANT",
				"BROKER_SERVICE",
				"BROKER_CORRESPONDENT",
				"BROKER",
			],
			searchValue
		);
	}

	async function fetchUsers(searchRoles, searchValue) {
		let results = [];
		if (searchValue.length > 0) {
			await api.personalDatas
				.getPersonalData(`user_role=${searchRoles.join(",")}&search=${searchValue}`)
				.then((response) => { results = response.data.results; })
				.catch((error) => console.log("fetchUsers error", error));
		}
		return results;
	}

	return (
		<>
			<Button
				id={TRANSLATIONS.BROKER_TEAM_DETAILS__ACTION_ADD_LABEL.key}
				type="info"
				size="large"
				htmlType="button"
				icon={<PlusOutlined />}
				onClick={openModal}
			>
				{translate(TRANSLATIONS.BROKER_TEAM_DETAILS__ACTION_ADD_LABEL.key)}
			</Button>

			<Modal
				id="add-member-modal"
				name="add-member-modal"
				title={
					<h2
						id={TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_TITLE.key}
						style={{ textAlign: "center" }}
					>
						{translate(TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_TITLE.key)}
					</h2>
				}
				open={open}
				destroyOnClose={true}
				closeIcon={false}
				keyboard={false}
				footer={null}
				afterClose={() => {
					resetGuiValidationErrors();
					resetApiValidationErrors();
				}}
			>
				<Divider />

				<Form
					id="add-member-form"
					name="add-member-form"
					autoComplete="off"
					preserve={false}
					layout="vertical"
					disabled={loading}
					onFinish={(values) => submit({
						brokerTeamId: brokerTeamId,
						personalDataId: values.member.value,
						memberName: values.member.label,
					})}
				>
					<Form.Item
						id="member"
						name="member"
						label={
							<label
								id={TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_FORM_PERSON_LABEL.key}
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								{translate(TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_FORM_PERSON_LABEL.key)}
							</label>
						}
						rules={[
							// TODO: zrobić na useMemo()
							{
								message: "Pole wymagane",
								validator: (_, value) => {
									if (!member.value) {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.member = "Pole wymagane";
											return newState;
										})
										return Promise.reject();
									} else {
										resetGuiValidationErrors();
										return Promise.resolve();
									}
								},
							},
						]}
						validateStatus={
							apiValidationErrors?.broker_team?.length > 0 || guiValidationErrors?.member
								? "error"
								: null
						}
						help={
							apiValidationErrors?.broker_team?.length > 0
								? apiValidationErrors.broker_team.join(",")
								: null
						}
					>
						<DebounceSelectPerson
							fetchOptions={fetchBrokers}
							onSelect={(option) => setMemberBroker(option.value, option.label)}
							onClear={() => {
								setMemberBroker(null, "");
								resetGuiValidationErrors();
								resetApiValidationErrors();
							}}
						/>
					</Form.Item>

					<Form.Item style={{ textAlign: "center" }}>
						<Space>
							<Button
								id={TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_CANCEL_BUTTON_LABEL.key}
								type="lightdark"
								ghost
								htmlType="button"
								size="large"
								onClick={closeModal}
							>
								{translate(TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_CANCEL_BUTTON_LABEL.key)}
							</Button>

							<Button
								type="success"
								htmlType="submit"
								size="large"
								loading={loading}
							>
								{translate(loading
									? TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_CREATION_BUTTON_LABEL.key
									: TRANSLATIONS.TEAM_MEMBERS_ADD__MODAL_CONFIRM_BUTTON_LABEL.key
								)}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}
