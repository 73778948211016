import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import api from "../../../infra/api";
import { dispatch } from "use-bus";

import {
	Divider,
	Tooltip,
	Modal,
	Form,
	Input,
	Space,
	Button,
} from "antd";
import { EditTwoTone } from "@ant-design/icons";

export default function BrokerTeamMemberBrokerEditModal({ teamId, personalData }) {
	const navigate = useNavigate();
	const [apiValidationErrors, setApiValidationErrors] = useState({});
	const [guiValidationErrors, setGuiValidationErrors] = useState({
		first_name: [],
		last_name: [],
		phone: [],
		email: [],
	});

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [memberOld] = useState({
		id: personalData.id,
		broker_id: personalData.broker_id,
		company_name: null,
		email: personalData.email,
		first_name: personalData.first_name,
		last_name: personalData.last_name,
		phone: personalData.phone,
	});
	const [member, setMember] = useState({
		id: personalData.id,
		broker_id: personalData.broker_id,
		company_name: null,
		email: personalData.email,
		first_name: personalData.first_name,
		last_name: personalData.last_name,
		phone: personalData.phone,
	});

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const startLoading = () => {
		setLoading(true);
	};

	const stopLoading = () => {
		setLoading(false);
	};

	const resetGuiValidationErrors = () => {
		setGuiValidationErrors((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.first_name = [];
			newState.last_name = [];
			newState.phone = [];
			newState.email = [];
			return newState;
		})
	}

	const resetApiValidationErrors = () => {
		setApiValidationErrors((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.broker_team = null;
			return newState;
		})
	}

	const submit = async payload => {
		startLoading();
		const noticationMemberLabel = `${payload.personal_data.first_name} ${payload.personal_data.last_name}`
		api.brokers
			.updateBroker(payload.broker_id, {
				broker_team: payload.broker_team_id,
				personal_data: payload.personal_data,
			})
			.then(response => {
				stopLoading();
				closeModal();
				showNotification(
					"success",
					"Operacja zakończona powodzeniem",
					`Pomyślnie zaktualizowano "${noticationMemberLabel}"`
				);
				dispatch({ type: "BROKER_UPDATED" });
			})
			.catch(error => {
				stopLoading();
				if (error?.response.status === 400) {
					setApiValidationErrors(error.response.data);
					showNotification(
						"error",
						"Niepoprawne dane",
						`Wystąpił błąd podczas aktualizacji "${noticationMemberLabel}"`
					);
				} else if (error?.response.status >= 500) {
					showNotification(
						"error",
						"Błąd serwera",
						`Wystąpił błąd podczas aktualizacji "${noticationMemberLabel}"`
					);
					navigate("/500");
				} else if (error?.response.status === 403) {
					showNotification(
						"error",
						"Brak uprawnień",
						`Wystąpił błąd podczas aktualizacji "${noticationMemberLabel}"`
					);
					navigate("/403");
				}
			});
	};

	const showNotification = (type, title, description) => {
		dispatch({
			type: "SHOW_NOTIFICATION",
			payload: {
				notification: {
					type,
					title,
					description,
				}
			}
		});
	};

	const setMemberBrokerFirstName = (value) => {
		const val = isNotEmpty(value) ? value : null;
		setMember((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.first_name = val;
			return newState;
		});
	};

	const setMemberBrokerLastName = (value) => {
		const val = isNotEmpty(value) ? value : null;
		setMember((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.last_name = val;
			return newState;
		});
	};

	const setMemberBrokerPhone = (value) => {
		const val = isNotEmpty(value) ? value : null;
		setMember((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.phone = val;
			return newState;
		});
	};

	const setMemberBrokerEmail = (value) => {
		const val = isNotEmpty(value) ? value : null;
		setMember((oldState) => {
			const newState = Object.assign({}, oldState);
			newState.email = val;
			return newState;
		});
	};

	// TODO: wyciągnąć do commonsów
	function isNotEmpty(value) {
		return value.length > 0
	}

	return (
		<>
			<Tooltip
				title={translate(TRANSLATIONS.BROKER_TEAM_MEMBERS__EDIT_BUTTON_LABEL.key)}
				destroyTooltipOnHide
				mouseEnterDelay={0.5}
			>
				<EditTwoTone key="edit" twoToneColor="#28a745"  onClick={openModal} />
			</Tooltip>

			<Modal
				id="edit-member-modal"
				name="edit-member-modal"
				title={
					<h2
						id={TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_TITLE.key}
						style={{ textAlign: "center" }}
					>
						{translate(TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_TITLE.key)}
					</h2>
				}
				open={open}
				destroyOnClose={true}
				closeIcon={false}
				keyboard={false}
				footer={null}
				afterClose={() => {
					resetGuiValidationErrors();
					resetApiValidationErrors();
				}}
			>
				<Divider />

				<Form
					id="edit-member-form"
					name="edit-member-form"
					autoComplete="off"
					preserve={false}
					layout="vertical"
					disabled={loading}
					onFinish={(values) => submit({
						id: member.id,
						broker_id: member.broker_id,
						broker_team_id: teamId,
						personal_data: {
							company_name: member.company_name,
							first_name: member.first_name,
							last_name: member.last_name,
							phone: member.phone,
							email: member.email,
						}
					})}
				>
					<Form.Item
						id="first_name"
						name="first_name"
						label={
							<label
								id={TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_FIRST_NAME_LABEL.key}
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								{translate(TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_FIRST_NAME_LABEL.key)}
							</label>
						}
						initialValue={personalData.first_name}
						required
						rules={[
							// TODO: zrobić na useMemo()
							{
								message: "Pole wymagane",
								validator: (_, value) => {
									if (!member.first_name) {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.first_name.push("Pole wymagane");
											return newState;
										});
										return Promise.reject();
									} else {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.first_name = [];
											return newState;
										});
										return Promise.resolve();
									}
								},
							},
						]}
						validateStatus={
							(apiValidationErrors?.broker_team?.length > 0 || guiValidationErrors?.first_name?.length > 0)
								? "error"
								: null
						}
						help={
							apiValidationErrors?.broker_team?.length > 0
								? apiValidationErrors.broker_team.join(",")
								: null
						}
					>
						<Input
							id="first_name"
							name="first_name"
							size="large"
							allowClear
							value={member.first_name}
							onChange={(e) => setMemberBrokerFirstName(e.target.value.trim())}
						/>
					</Form.Item>

					<Form.Item
						id="last_name"
						name="last_name"
						label={
							<label
								id={TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_LAST_NAME_LABEL.key}
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								{translate(TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_LAST_NAME_LABEL.key)}
							</label>
						}
						initialValue={personalData.last_name}
						required
						rules={[
							// TODO: zrobić na useMemo()
							{
								message: "Pole wymagane",
								validator: (_, value) => {
									if (!member.last_name) {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.last_name.push("Pole wymagane");
											return newState;
										});
										return Promise.reject();
									} else {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.last_name = [];
											return newState;
										});
										return Promise.resolve();
									}
								},
							},
						]}
						validateStatus={
							(apiValidationErrors?.broker_team?.length > 0 || guiValidationErrors?.last_name?.length > 0)
								? "error"
								: null
						}
						help={
							apiValidationErrors?.broker_team?.length > 0
								? apiValidationErrors.broker_team.join(",")
								: null
						}
					>
						<Input
							id="last_name"
							name="last_name"
							size="large"
							allowClear
							value={member.last_name}
							onChange={(e) => setMemberBrokerLastName(e.target.value.trim())}
						/>
					</Form.Item>

					<Form.Item
						id="phone"
						name="phone"
						label={
							<label
								id={TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_PHONE_LABEL.key}
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								{translate(TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_PHONE_LABEL.key)}
							</label>
						}
						initialValue={personalData.phone}
						rules={[
							{
								max: 9,
								message: "Maksymalna długość to 9 znaków",
							},
						]}
					>
						<Input
              id="phone"
              name="phone"
              size="large"
              allowClear
							maxLength={9}
							showCount
              value={member.phone}
							onChange={(e) => setMemberBrokerPhone(e.target.value.trim())}
            />
					</Form.Item>

					<Form.Item
						id="email"
						name="email"
						label={
							<label
								id={TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_EMAIL_LABEL.key}
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								{translate(TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_FORM_EMAIL_LABEL.key)}
							</label>
						}
						required
						initialValue={personalData.email}
						validateFirst
						rules={[
							// TODO: zrobić na useMemo()
							{
								message: "Pole wymagane",
								validator: (_, value) => {
									if (!member.email) {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.email.push("Pole wymagane");
											return newState;
										});
										return Promise.reject();
									} else {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.email = [];
											return newState;
										});
										return Promise.resolve();
									}
								},
							},
							{
								message: "Należy wprowadzić poprawny adres e-mail",
								validator: (_, value) => {
									const regexp = /^[a-zA-Z\d._-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/g;
									if (member.email && !member.email.match(regexp)) {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.email.push("Należy wprowadzić poprawny adres e-mail");
											return newState;
										});
										return Promise.reject();
									} else {
										setGuiValidationErrors((oldState) => {
											const newState = Object.assign({}, oldState);
											newState.email = [];
											return newState;
										});
										return Promise.resolve();
									}
								},
							},
						]}
						validateStatus={
							(apiValidationErrors?.broker_team?.length > 0 || guiValidationErrors?.email?.length > 0)
								? "error"
								: null
						}
						help={
							apiValidationErrors?.broker_team?.length > 0
								? apiValidationErrors.broker_team.join(",")
								: null
						}
					>
						<Input
              id="email"
              name="email"
              size="large"
              allowClear
              value={member.email}
							onChange={(e) => setMemberBrokerEmail(e.target.value.trim())}
            />
					</Form.Item>

					<Form.Item style={{ textAlign: "center" }}>
						<Space>
							<Button
								id={TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_CANCEL_BUTTON_LABEL.key}
								type="lightdark"
								ghost
								htmlType="button"
								size="large"
								onClick={closeModal}
							>
								{translate(TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_CANCEL_BUTTON_LABEL.key)}
							</Button>

							<Button
								type="success"
								htmlType="submit"
								size="large"
								loading={loading}
								disabled={JSON.stringify(member) === JSON.stringify(memberOld)}
							>
								{translate(loading
									? TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_UPDATING_BUTTON_LABEL.key
									: TRANSLATIONS.TEAM_MEMBER_EDIT__MODAL_SAVE_BUTTON_LABEL.key
								)}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}
