import { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  Link,
  useLocation,
  Route,
  Routes,
} from "react-router-dom";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import { Spin, Divider, Typography, Tabs } from "antd";

import CustomerData from "../../components/customer/details/CustomerData";
import CustomerBrokerTeam from "../../components/customer/details/CustomerBrokerTeam";
import CustomerAgentTeam from "../../components/customer/details/CustomerAgentTeam";

export default function CustomerDetailPage({ user }) {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("customer-data");

  useEffect(() => {
    setActiveTabKey(getLastPartOfLocation(location.pathname));
    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLastPartOfLocation = (location) => {
    const lastChar = location.slice(-1);
    const locationWithoutLastSlash = lastChar === "/"
      ? location.slice(0, -1)
      : location;
    const lastSlashIndex = locationWithoutLastSlash.lastIndexOf("/");
    const lastSlashToEnd = locationWithoutLastSlash.substring(lastSlashIndex);
    const lastSlashToEndWithoutSlash = lastSlashToEnd.replace("/", "");
    return lastSlashToEndWithoutSlash;
  };

  const fetchCustomerData = async () => {
    startLoading();
    await api.customers
      .getCustomer(id)
      .then((response) => {
        setCustomer(response.data);
        console.log("CustomerDetailPage customer", response.data);
        stopLoading();
      })
      .catch(error => {
        stopLoading();
        if (error?.response.status === 400) {
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas wczytywania danych klienta "${customer.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwera",
            `Wystąpił błąd podczas wczytywania danych klienta "${customer.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas wczytywania danych klienta "${customer.name}"`
          );
          navigate("/403");
        }
      });
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  const items = [
    {
      key: "customer-data",
      label: <Link to={`/customers/${id}/customer-data`}>Dane klienta</Link>,
    },
    {
      key: "broker-team",
      label: <Link to={`/customers/${id}/broker-team`}>Zespół brokerski</Link>,
    },
    {
      key: "agent-team",
      label: <Link to={`/customers/${id}/agent-team`}>Zespół agencyjny</Link>,
    },
    // Przenieść do CustomerData.js
    // Lub wywalić całkowicie - podpytać się Izy jak to ma wyglądać
    // {
    // 	key: "agreements",
    // 	label: <Link to={`/customers/${id}/agreements`}>Zgody RODO i marketingowe</Link>,
    // 	children: <Outlet />,
    // }
  ];

  return (
    <>
      {!customer && (
        <Spin tip="Wczytywanie danych..." spinning={loading}>
          <div />
        </Spin>
      )}

      {customer && (
        <>
          <Typography.Title level={1} style={{ fontWeight: "bold" }}>
            {customer.name}
          </Typography.Title>

          <Divider />

          <Tabs
            activeKey={activeTabKey}
            defaultActiveKey={activeTabKey}
            type="card"
            size="large"
            items={items}
          />

          <Routes>
            <Route path="" element={<CustomerData customer={customer} />} />
            <Route path="customer-data" element={<CustomerData customer={customer} />} />
            <Route path="broker-team" element={<CustomerBrokerTeam customer={customer} />} />
            <Route path="agent-team" element={<CustomerAgentTeam customer={customer} />} />
          </Routes>
        </>
      )}
    </>
  );
}
