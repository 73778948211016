import { useState } from "react";

import { Button, Form, Input, Steps } from "antd";
import UseForm from "../hooks/useForm";

import BrokerTeamFormSection from "../components/customer/create/BrokerTeamFormSection";

export default function DebugPageSas({ user, setUser }) {
  const {
    form: step1Form,
    formHelpers: step1Helpers,
    formFields: step1Fields,
  } = UseForm({
    foo: "foo",
    BROKER_TEAM: "broker_team",
    BROKER_KEEPER: "keeper_broker_side",
    BROKER_CORRESPONDENT: "correspondent_broker_side",
  });
  const {
    form: step2Form,
    formHelpers: step2Helpers,
    formFields: step2Fields,
  } = UseForm({
    bar: "bar",
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([
    {
      key: 0,
      title: "Step 1",
      content: "Content 1",
      status: "process",
    },
    {
      key: 1,
      title: "Step 2",
      content: "Content 2",
      status: "wait",
    },
  ]);

  const setStepStatus = (step, status) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[step].status = status;
      return newItems;
    });
  };

  const fakeSubmitApi = (payload) => {
    console.log("fakeSubmitApi", payload);
    return new Promise((resolve, reject) => {
      const res = {
        response: {
          status: 400,
          data: {
            foo: ["error foo"],
            // bas: ["error bas"],
            // bar: ["error bar"],
          },
        },
      };
      setTimeout(() => {
        console.log("rejecting");
        reject(res);
      }, 1000);
    });
  };

  const stepFieldsNameMapping = {
    0: { ...step1Fields, bas: "bas" },
    1: step2Fields,
  };

  // przenieść do helpera
  const findStepByFieldName = (fieldName) => {
    for (const [step, fields] of Object.entries(stepFieldsNameMapping)) {
      if (fields[fieldName]) {
        return parseInt(step);
      }
    }
    return -1;
  };

  const findFirstFailedStep = (apiErrors) => {
    for (const [field] of Object.entries(apiErrors)) {
      const step = findStepByFieldName(field);
      if (step !== -1) {
        console.log("failed step found", step);
        return step;
      }
    }
    throw new Error("No failed step found");
  };

  const stepHasErrors = (step, response) => {
    for (const [field] of Object.entries(response)) {
      if (stepFieldsNameMapping[step][field]) {
        return true;
      }
    }
    return false;
  };

  const [brokerTeams, setBrokerTeams] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [agentsTeams, setAgentsTeams] = useState([]);
  const [agents, setAgents] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);
  const config = {
    brokerTeams,
    setBrokerTeams,
    brokers,
    setBrokers,
    agentsTeams,
    setAgentsTeams,
    agents,
    setAgents,
    customerGroup,
    setCustomerGroup,
  };

  return (
    <div>
      <h1>Debug</h1>

      {/* okodzenie, dla stepu przed stepem z bledem, zmienic status na waiting */}
      <Steps
        size="large"
        current={currentStep}
        initial={0}
        loading={+loading}
        disabled={+loading}
        items={items}
      />

      {currentStep === 0 && (
        <Form
          form={step1Form}
          onFinish={(payload) => {
            console.log("finshing step", currentStep, payload);
            setStepStatus(currentStep, "finish");
            setStepStatus(currentStep + 1, "process");
            setCurrentStep(currentStep + 1);
          }}
        >
          <BrokerTeamFormSection
            form={step1Form}
            formHelpers={step1Helpers}
            formFields={step1Fields}
            config={config}
          />
          <Form.Item
            name={step1Fields.foo}
            required
            rules={[
              {
                required: true,
                message: "Wymagane",
              },
            ]}
            validateStatus={
              step1Helpers.errorFields.includes(step1Fields.foo)
                ? "error"
                : null
            }
            help={
              step1Helpers.errorFields.includes(step1Fields.foo)
                ? step1Helpers.getFieldErrors(step1Fields.foo).join(", ")
                : null
            }
          >
            <Input type="text" placeholder="foo" />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit">Next</Button>
          </Form.Item>
        </Form>
      )}

      {currentStep === 1 && (
        <Form
          form={step2Form}
          onFinish={(payload) => {
            const finalPayload = {
              foo: step1Form.getFieldValue(step1Fields.foo),
              bar: step2Form.getFieldValue(step2Fields.bar),
            };
            console.log("finalPayload", finalPayload);
            setLoading(true);
            setStepStatus(currentStep, "finish");
            fakeSubmitApi(finalPayload)
              .then((res) => {
                console.log("res", res);
              })
              .catch((err) => {
                console.log("err", err);
                step1Helpers.setApiErrors(err.response.data);
                step2Helpers.setApiErrors(err.response.data);

                if (stepHasErrors(0, err.response.data)) {
                  setStepStatus(0, "error");
                }

                if (stepHasErrors(1, err.response.data)) {
                  setStepStatus(1, "error");
                }

                setCurrentStep(findFirstFailedStep(err.response.data));
                setLoading(false);
              });
          }}
        >
          <Form.Item
            name={step2Fields.bar}
            required
            rules={[
              {
                required: true,
                message: "Wymagane",
              },
            ]}
            validateStatus={
              step2Helpers.errorFields.includes(step2Fields.bar)
                ? "error"
                : null
            }
            help={
              step2Helpers.errorFields.includes(step2Fields.bar)
                ? step2Helpers.getFieldErrors(step2Fields.bar).join(", ")
                : null
            }
          >
            <Input disabled={loading} type="text" placeholder="bar" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              htmlType="button"
              onClick={() => {
                setStepStatus(currentStep, "wait");
                setStepStatus(currentStep - 1, "process");
                setCurrentStep(currentStep - 1);
              }}
            >
              Back
            </Button>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} disabled={loading} htmlType="submit">
              Done
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
