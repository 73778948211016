import { Avatar, Badge, Col, Descriptions, Divider, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";

export default function UserProfilePage({ user }) {
  const getFormattedDate = (date) => {
    return (`${date.split("T")[0]} ${date.split("T")[1].split(".")[0]}`).replace("Z", "");
  };

  const items = [
    { key: 1, label: "Imię i nazwisko", children: `${user.first_name} ${user.last_name}`, span: 3 },
    { key: 2, label: "E-mail", children: user.email, span: 3 },
    { key: 3, label: "Aktywny", children: <Badge status={user.is_active ? "success" : "error"} />, span: 3 },
    { key: 4, label: "Data utworzenia", children: getFormattedDate(user.date_joined), span: 3 },
    { key: 5, label: "Data ostatniego logowania", children: getFormattedDate(user.last_login), span: 3 },
  ];

  return (
    <>
      <h1>Profil użutkownika</h1>

      <Divider />

      <Row gutter={16} style={{ justifyContent: "center" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={1} style={{ textAlign: "right" }}>
          <Avatar size="large" icon={<UserOutlined size="large" />} />
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={6} style={{ textAlign: "left" }}>
          <Descriptions bordered items={items} />
        </Col>
      </Row>
    </>
  )
}