import axios from "axios";

const BE_URL = process.env.REACT_APP_FLEET_MONO_API_URL;
console.log("BE_URL", BE_URL);
const headers = (token = null, lang = "PL") => {
  const h = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Lang: lang,
  };
  if (token) {
    h.Authorization = `Bearer ${token}`;
  }
  return h;
};

const getLocalAccessToken = () => {
  const tokens = JSON.parse(window.localStorage.getItem("tokens"));
  return tokens.access;
};

const getLocalRefreshToken = () => {
  const tokens = JSON.parse(window.localStorage.getItem("tokens"));
  return tokens.refresh;
};

const getLocalLang = () => {
  return window.localStorage.getItem("lang");
};

const api = {
  auth: {
    getToken: (email, password) => {
      console.log("getToken()");
      return axios.post(
        `${BE_URL}/auth/jwt/create/`,
        {
          email: `${email}`,
          password: `${password}`,
        },
        {
          headers: headers(),
        }
      );
    },
    refreshToken: () => {
      return axios.post(
        `${BE_URL}/auth/jwt/refresh/`,
        { refresh: getLocalRefreshToken() },
        { headers: headers() }
      );
    },
  },
  users: {
    getMe: () => {
      return axios.get(`${BE_URL}/users/me/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getUsers: (page, pageSize, order_by, queryFilters) => {
      return axios.get(
        `${BE_URL}/users/?page=${page}&size=${pageSize}&order_by=${order_by}&${queryFilters}`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
  },
  customerGroups: {
    getCustomerGroups: ({ variant, page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/customer-groups/${variant}/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters}`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    searchCustomerGroups: (queryFilters) => {
      return axios.get(`${BE_URL}/customer-groups/all/?${queryFilters}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getCustomerGroup: (id) => {
      return axios.get(`${BE_URL}/customer-groups/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createCustomerGroup: (payload) => {
      return axios.post(`${BE_URL}/customer-groups/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    updateCustomerGroup: (id, name) => {
      return axios.put(
        `${BE_URL}/customer-groups/${id}/`,
        { name },
        { headers: headers(getLocalAccessToken(), getLocalLang()) }
      );
    },
    deleteCustomerGroup: (id) => {
      return axios.delete(`${BE_URL}/customer-groups/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  customers: {
    getCustomers: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/customers/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters}`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    searchCustomers: (searchQuery) => {
      return axios.get(`${BE_URL}/customers/?${searchQuery}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getCustomer: (id) => {
      return axios.get(`${BE_URL}/customers/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    searchCustomerBrokers: (searchQuery) => {
      return axios.get(`${BE_URL}/customer-brokers/?${searchQuery}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    searchCustomerAgents: (searchQuery) => {
      return axios.get(`${BE_URL}/customer-agents/?${searchQuery}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createCustomer: (payload) => {
      return axios.post(`${BE_URL}/customers/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    updateCustomer: (id, payload) => {
      return axios.put(`${BE_URL}/customers/${id}/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    deleteCustomer: (id) => {
      return axios.delete(`${BE_URL}/customers/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  brokerTeams: {
    getBrokerTeams: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/broker-teams/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters}`,
        { headers: headers(getLocalAccessToken(), getLocalLang()), }
      );
    },
    searchBrokerTeam: (searchQuery) => {
      return axios.get(`${BE_URL}/broker-teams/?${searchQuery}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getBrokerTeam: (id) => {
      return axios.get(`${BE_URL}/broker-teams/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createBrokerTeam: (payload) => {
      return axios.post(`${BE_URL}/broker-teams/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    // daktywacja ma wysyłać z status: "INACTIVE"
    updateBrokerTeam: (id, payload) => {
      return axios.put(`${BE_URL}/broker-teams/${id}/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  brokers: {
    getBrokers: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/brokers/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters}`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    searchBrokers: (searchQuery) => {
      return axios.get(`${BE_URL}/brokers/?${searchQuery}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getBroker: (id) => {
      return axios.get(`${BE_URL}/brokers/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createBroker: (payload) => {
      return axios.post(`${BE_URL}/brokers/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    updateBroker: (id, payload) => {
      return axios.put(`${BE_URL}/brokers/${id}/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    deleteBroker: (id) => {
      return axios.delete(`${BE_URL}/brokers/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  agentTeams: {
    getAgentTeams: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/agent-teams/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters}`,
        { headers: headers(getLocalAccessToken(), getLocalLang()) }
      );
    },
    searchAgentTeam: (searchQuery) => {
      return axios.get(`${BE_URL}/agent-teams/?${searchQuery}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getAgentTeam: (id) => {
      return axios.get(`${BE_URL}/agent-teams/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createAgentTeam: (payload) => {
      return axios.post(`${BE_URL}/agent-teams/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    // daktywacja ma wysyłać z status: "INACTIVE"
    updateAgentTeam: (id, payload) => {
      return axios.put(`${BE_URL}/agent-teams/${id}/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  agents: {
    getAgents: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/agents/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters}`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    searchAgents: (searchQuery) => {
      return axios.get(`${BE_URL}/agents/?${searchQuery}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getAgent: (id) => {
      return axios.get(`${BE_URL}/agents/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createAgent: (payload) => {
      return axios.post(`${BE_URL}/agents/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    updateAgent: (id, payload) => {
      return axios.put(`${BE_URL}/agents/${id}/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    deleteAgent: (id) => {
      return axios.delete(`${BE_URL}/agents/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  personalDatas: {
    getPersonalData: (queryFilters) => {
      return axios.get(`${BE_URL}/personal-datas/?${queryFilters}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    addCoBroker: (payload) => {
      return axios.post(`${BE_URL}/personal-datas/add_cobroker/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    updateCobroker: (payload) => {
      return axios.put(`${BE_URL}/personal-datas/update_cobroker/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createPersonalData: (payload) => {
      return axios.post(`${BE_URL}/personal-datas/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  insurers: {
    getInsurers: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/insurers/?page=${page}&size=${size}&order_by=${order_by}${queryFilters ?? ""
        }`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    searchInsurers: (queryFilters) => {
      return axios.get(`${BE_URL}/insurers/?${queryFilters}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createInsurer: (payload) => {
      return axios.post(`${BE_URL}/insurers/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getDepots: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/insurer-depots/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters}`,
        {
          headers: headers(getLocalAccessToken(), getLocalLang()),
        }
      );
    },
    searchDepots: (queryFilters) => {
      return axios.get(`${BE_URL}/insurer-depots/?${queryFilters}`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    getDepot: (id) => {
      return axios.get(`${BE_URL}/insurer-depots/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createInsurerDepot: (payload) => {
      return axios.post(`${BE_URL}/insurer-depots/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
  generalContracts: {
    getGeneralContracts: ({ page, size, order_by, queryFilters }) => {
      return axios.get(
        `${BE_URL}/customer-general-contracts/?page=${page}&size=${size}&order_by=${order_by}&${queryFilters ?? ""}`,
        { headers: headers(getLocalAccessToken(), getLocalLang()), }
      );
    },
    getGeneralContract: (id) => {
      return axios.get(`${BE_URL}/customer-general-contracts/${id}/`, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    createGeneralContract: (payload) => {
      return axios.post(`${BE_URL}/customer-general-contracts/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
    updateGeneralContract: (id, payload) => {
      return axios.put(`${BE_URL}/customer-general-contracts/${id}/`, payload, {
        headers: headers(getLocalAccessToken(), getLocalLang()),
      });
    },
  },
};

export default api;
