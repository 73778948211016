import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import debounce from "lodash/debounce";
import api from "../../infra/api";
import useBus, {dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import { Button, Form, Input, Select, Divider, Spin } from "antd";

import CreateCoBrokerModal from "../../components/commons/CreateCoBrokerModal";

// TODO: do wyciągniecią jak już będzie sprawdzone czy bangla
// Spróbować przenieść Select w scope formularza, options dla wybranego agenta i options dla wybranego brokera
// Jak się nie uda, to utworzyć pola do wprowadzenia ręcznego - checkbox i wyświetlenie pól do uzupełnienia
function DebounceSelect({ fetchOptions, debounceTimeout = 500, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      setFetching(true);
      setOptions([]);

      fetchOptions(value).then((newOptions) => {
        const opt = newOptions.map((option) => ({
          label: option.company_name
            ? `${option.company_name} (${option.email})`
            : `${option.first_name} ${option.last_name} (${option.email})`,
          value: option.id,
        }));
        setOptions(opt);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      allowClear
      size="large"
      showSearch={{ single: true }}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin /> : null}
      {...props}
      options={options}
    />
  );
}

export default function CustomerGroupCreatePage({ user }) {
  const navigate = useNavigate();
  const [apiValidationErrors, setApiValidationErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [createCustomerGroupForm] = Form.useForm();
  const nameInputRef = useRef(null);
  const [customerGroup, setCustomerGroup] = useState({
    name: "",
    keeper_agency_side: {
      label: "",
      value: null
    },
    keeper_broker_side: {
      label: "",
      value: null
    },
    customers: [],
  });

  // const [newKeeperBroker, setNewKeeperBroker] = useState(null);
  // const newKeeperBrokerDescription = useMemo(() => {
  //   if (newKeeperBroker) {
  //     return `${newKeeperBroker.company_name} (${newKeeperBroker.email})`
  //   }
  //   return "";
  // }, [newKeeperBroker])

  useEffect(() => {
    console.log("CustomerGroupCreatePage mounted...");
    nameInputRef.current.focus();
  }, []);

  const submit = async (payload) => {
    setLoading(true);
    api.customerGroups
      .createCustomerGroup(payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano grupę "${payload.name}"`
        );
        setLoading(false);
        navigate("/customer-groups")
      })
      .catch((error) => {
        setLoading(false);

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        // BUG: nie wyświetla notyfikacji po przekierowaniu na 500 albo 403
        if (error?.response.status === 400) {
          setApiValidationErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania grupy "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas dodawania grupy "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania grupy "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
		dispatch({
			type: "SHOW_NOTIFICATION",
			payload: {
				notification: {
					type,
					title,
					description,
				}
			}
		});
  };

  const setKeeperAgent = (id, name) => {
    setCustomerGroup((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.keeper_agency_side.label = name;
      newState.keeper_agency_side.value = id;
      return newState;
    });
  };

  useBus("COBROKER_CREATED", (event) => {
    const value = event.payload.id;
    const label = `${event.payload.company_name} (${event.payload.email})`
    setKeeperBroker(value, label);
    createCustomerGroupForm.setFieldValue("keeper_broker_side", { value: value, label: label})
    // setNewKeeperBroker(event.payload);
  });

  const setKeeperBroker = (id, name) => {
    setCustomerGroup((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.keeper_broker_side.label = name;
      newState.keeper_broker_side.value = id;
      return newState;
    });
  };

  const setCustomerGroupName = (value) => {
    setCustomerGroup((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.name = value;
      return newState;
    });
  }

  const fetchAgents = async (searchValue) => {
    return await fetchUsers(
      ["SYS_ADMIN_AGENCY", "AGENT_LIDER", "AGENT"],
      searchValue
    );
  };

  const fetchBrokers = async (searchValue) => {
    return await fetchUsers(
      [
        "SYS_ADMIN_BROKER",
        "BROKER_LIDER",
        "BROKER_MERCHANT",
        "BROKER_SERVICE",
        "BROKER_CORRESPONDENT",
        "COBROKER",
      ],
      searchValue
    );
  };

  async function fetchUsers(searchRoles, searchValue) {
    let results = [];
    if (searchValue.length > 0) {
      await api.personalDatas
        .getPersonalData(
          `user_role=${searchRoles.join(",")}&search=${searchValue}`
        )
        .then((response) => {
          results = response.data.results;
        })
        .catch((error) => console.log("fetchUsers error", error));
    }
    return results;
  }

  return (
    <>
      <h1 id={TRANSLATIONS.CG_CREATE__TITLE.key}>
        {translate(TRANSLATIONS.CG_CREATE__TITLE.key)}
      </h1>

      <Divider />

      {/* TODO: Ostylowanie errorów + wyciągniecie do komonsów */}
      {apiValidationErrors &&
        Object.entries(apiValidationErrors).map(([key, value]) => {
          return (
            <div key={key} style={{ color: "red", margin: 15 }}>
              key: {key}, value:
              <ul>
                {value.map((value) => (
                  <li>{value}</li>
                ))}
              </ul>
            </div>
          );
        })}

      <Form
        form={createCustomerGroupForm}
        id="customer-group-create-form"
        name="customer-group-create-form"
        autoComplete="off"
        layout="vertical"
        preserve={false}
        disabled={+loading}
        onFinish={(values) => submit({
          name: customerGroup.name,
          keeper_agency_side_personal_data: customerGroup.keeper_agency_side.value,
          keeper_broker_side_personal_data: customerGroup.keeper_broker_side.value, //newKeeperBroker ? newKeeperBroker.id : customerGroup.keeper_broker_side.value
          customers: customerGroup.customers
        })}
      >
        <Form.Item
          id="name"
          name="name"
          label={
            <label
              id={TRANSLATIONS.CG_CREATE__FORM_NAME_LABEL.key}
              style={{ fontWeight: "bold", fontSize: 20 }}
            >
              {translate(TRANSLATIONS.CG_CREATE__FORM_NAME_LABEL.key)}
            </label>
          }
          rules={[
            {
              required: true,
              message: "Pole wymagane",
            },
          ]}
          // validateStatus={`${apiValidationErrors.name ? "error" : ""}`}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
        // BUG: can't name customer group with spaces
        // user need to finish typing - do we need debounce here?
        // normalize={value => value.trim()}
        >
          <Input
            ref={nameInputRef}
            id="name"
            name="name"
            size="large"
            allowClear
            onChange={(e) => setCustomerGroupName(e.currentTarget.value)}
          />
        </Form.Item>

        <Form.Item
          id="keeper_agency_side"
          name="keeper_agency_side"
          label={
            <label
              id={TRANSLATIONS.CG_CREATE__FORM_KEEPER_AGENCY_SIDE_LABEL.key}
              style={{ fontWeight: "bold", fontSize: 20 }}
            >
              {translate(TRANSLATIONS.CG_CREATE__FORM_KEEPER_AGENCY_SIDE_LABEL.key)}
            </label>
          }
          rules={[
            {
              required: true,
              message: "Pole wymagane",
            },
            // TODO: wyciągnąć do commonsów
            // {
            //   message: "Pole wymagane",
            //   validator: (_, value) => {
            //     console.log("validation value", value);
            //     if (!customerGroup.keeper_agency_side) {
            //       return Promise.reject();
            //     } else {
            //       return Promise.resolve();
            //     }
            //   },
            // },
          ]}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
        >
          <DebounceSelect
            fetchOptions={fetchAgents}
            onSelect={(option) => setKeeperAgent(option.value, option.label)}
            onClear={() => setKeeperAgent(null, "")}
          />
        </Form.Item>

        <Form.Item
          label={
            <label
              id={TRANSLATIONS.CG_CREATE__FORM_KEEPER_BROKER_SIDE_LABEL.key}
              style={{ fontWeight: "bold", fontSize: 20 }}
            >
              {translate(TRANSLATIONS.CG_CREATE__FORM_KEEPER_BROKER_SIDE_LABEL.key)}
            </label>
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
        >

        <CreateCoBrokerModal user={user} />

        <Form.Item
          id="keeper_broker_side"
          name="keeper_broker_side"
        >
          <DebounceSelect
            fetchOptions={fetchBrokers}
            onSelect={(option) => setKeeperBroker(option.value, option.label)}
            onClear={() => setKeeperBroker(null, "")}
          />
        </Form.Item>

        {/* {!newKeeperBroker &&
          <span>
            <CreateCoBrokerModal user={user} />

            <Form.Item
              id="keeper_broker_side"
              name="keeper_broker_side"
            >
              <DebounceSelect
                fetchOptions={fetchBrokers}
                onSelect={(option) => setKeeperBroker(option.value, option.label)}
                onClear={() => setKeeperBroker(null, "")}
              />
            </Form.Item>
          </span>
          }

          {newKeeperBroker &&
            <Form.Item>
              <Input
                id="newKeeperBroker"
                name="newKeeperBroker"
                size="large"
                value={newKeeperBrokerDescription}
              />
              <Button onClick={() => setNewKeeperBroker(null)}>Usuń</Button>
            </Form.Item>
          } */}

        </Form.Item>

        {/*
          Trzeba zrobić komponent wyszukiwania z ikoną otwarcia okna modalnego
          okno modalne będzie zawierać tabelę z możliwością zaznaczania rekordów
          po zatwierdzeniu zaznaczone rekordy mają zostać wrzucone w tabelkę wyświetloną pod spodem (minimalistyczna tabelka)
          w tabeli można usuwać wybrane rekordy
        */}
        {/*
        <Form.Item
          id="customers"
          name="customers"
          label={
            <label style={{ fontWeight: "bold", fontSize: 20 }}>
              Klienci
            </label>
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 }
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 }
          }}
        >
          <p>Add customer component here!</p>
        </Form.Item>
        */}

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            id={TRANSLATIONS.CG_CREATE__FORM_CREATE_BUTTON_LABEL.key}
            type="success"
            htmlType="submit"
            size="large"
          >
            {translate(TRANSLATIONS.CG_CREATE__FORM_CREATE_BUTTON_LABEL.key)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
