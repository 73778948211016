import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, Divider } from "antd";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import DebounceSelectPerson from "../../components/commons/DebounceSelectPerson";

export default function BrokerTeamCreatePage({ user }) {
  const navigate = useNavigate();
  const [apiValidationErrors, setApiValidationErrors] = useState({});
  const [guiValidationErrors, setGuiValidationErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [createBrokerTeamFrom] = Form.useForm();
  const nameInputRef = useRef(null);
  const [brokerTeam, setBrokerTeam] = useState({
    name: "",
    leader: {
      label: "",
      value: null,
    },
  });

  useEffect(() => {
    console.log("BrokerTeamCreatePage mounted...");
    nameInputRef.current.focus();
  }, []);

  const submit = async (payload) => {
    setLoading(true);
    api.brokerTeams
      .createBrokerTeam(payload)
      .then((response) => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dodano zespół "${payload.name}"`
        );
        setLoading(false);
        navigate(`/broker-teams/${response.data.id}`);
      })
      .catch((error) => {
        setLoading(false);

        // TODO: to pewnie bedzie wyciagane do czegos w rodzaju handleApiError(error, {...})
        // BUG: nie wyświetla notyfikacji po przekierowaniu na 500 albo 403
        if (error?.response.status === 400) {
          setApiValidationErrors(error.response.data);
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dodawania zespołu "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwisu",
            `Wystąpił błąd podczas dodawania zespołu "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dodawania zespołu "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
		dispatch({
			type: "SHOW_NOTIFICATION",
			payload: {
				notification: {
					type,
					title,
					description,
				}
			}
		});
  };

  const setBrokerTeamName = (value) => {
    setBrokerTeam((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.name = value;
      return newState;
    });
  };

  const setBrokerTeamLeader = (id, name) => {
    setBrokerTeam((oldState) => {
      const newState = Object.assign({}, oldState);
      newState.leader.label = name;
      newState.leader.value = id;
      return newState;
    });
  }

  const fetchBrokers = async (searchValue) => {
    return await fetchUsers(
      [
        "SYS_ADMIN_BROKER",
        "BROKER_LIDER",
        "BROKER_MERCHANT",
        "BROKER_SERVICE",
        "BROKER_CORRESPONDENT",
        "BROKER"
      ],
      searchValue
    );
  }

  async function fetchUsers(searchRoles, searchValue) {
    let results = [];
    if (searchValue.length > 0) {
      await api.personalDatas
        .getPersonalData(`user_role=${searchRoles.join(",")}&search=${searchValue}`)
        .then((response) => { results = response.data.results; })
        .catch((error) => console.log("fetchUsers error", error));
    }
    return results;
  }

  return (
    <>
      <h1 id={TRANSLATIONS.BROKER_TEAM_CREATE__TITLE.key}>
        {translate(TRANSLATIONS.BROKER_TEAM_CREATE__TITLE.key)}
      </h1>

      <Divider />

      <Form
        form={createBrokerTeamFrom}
        id="broker-team-create-form"
        name="broker-team-create-form"
        autoComplete="off"
        layout="vertical"
        preserve={false}
        disabled={+loading}
        onFinish={(values) => submit({
          name: brokerTeam.name,
          leader: brokerTeam.leader.value
        })}
      >
        <Form.Item
          id="name"
          name="name"
          label={
            <label
              id={TRANSLATIONS.BROKER_TEAM_CREATE__FORM_NAME_LABEL.key}
              style={{ fontWeight: "bold", fontSize: 20 }}
            >
              {translate(TRANSLATIONS.BROKER_TEAM_CREATE__FORM_NAME_LABEL.key)}
            </label>
          }
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!brokerTeam.name) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.name = "Pole wymagane";
                    return newState;
                  })
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.name = null;
                    return newState;
                  })
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.name?.length > 0 || guiValidationErrors?.name
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.name?.length > 0
              ? apiValidationErrors.name.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          normalize={(value) => value.trimStart()}
        >
          <Input
            ref={nameInputRef}
            id="name"
            name="name"
            size="large"
            allowClear
            onChange={(e) => {
              setBrokerTeamName(e.currentTarget.value.trim())
              setApiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.name = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        <Form.Item
          id="leader"
          name="leader"
          label={
            <label
              id={TRANSLATIONS.BROKER_TEAM_CREATE__FORM_LEADER_LABEL.key}
              style={{ fontWeight: "bold", fontSize: 20 }}
            >
              {translate(TRANSLATIONS.BROKER_TEAM_CREATE__FORM_LEADER_LABEL.key)}
            </label>
          }
          rules={[
            // TODO: zrobić na useMemo()
            {
              message: "Pole wymagane",
              validator: (_, value) => {
                if (!brokerTeam.leader.value) {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.leader = "Pole wymagane";
                    return newState;
                  })
                  return Promise.reject();
                } else {
                  setGuiValidationErrors((oldState) => {
                    const newState = Object.assign({}, oldState);
                    newState.leader = null;
                    return newState;
                  })
                  return Promise.resolve();
                }
              },
            },
          ]}
          validateStatus={
            apiValidationErrors?.leader?.length > 0 || guiValidationErrors?.leader
              ? "error"
              : null
          }
          help={
            apiValidationErrors?.leader?.length > 0
              ? apiValidationErrors.leader.join(",")
              : null
          }
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 10 },
          }}
        >
          <DebounceSelectPerson
            fetchOptions={fetchBrokers}
            onSelect={(option) => setBrokerTeamLeader(option.value, option.label)}
            onClear={() => {
              setBrokerTeamLeader(null, "");
              setGuiValidationErrors((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.leader = [];
                return newState;
              });
            }}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            id={TRANSLATIONS.BROKER_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key}
            type="success"
            htmlType="submit"
            size="large"
          >
            {translate(TRANSLATIONS.BROKER_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL.key)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
