import { TRANSLATIONS } from "./translations";

const f = (key, formatObj = null) => {
  let lang = window.localStorage.getItem("lang") ?? "PL";
  let tpl = null;
  const tplObj = TRANSLATIONS[key];
  if (!tplObj) {
    throw new Error(`TPL object missing for key: ${key}`);
  }
  tpl = tplObj["val"][lang];
  if (!tpl) {
    console.warn("Missing translation!", key);
    return `{TRANSLATIONS.${key}}`;
  }

  if (formatObj) {
    const tpl_before_format = (" " + tpl).slice(1);
    for (const [key, value] of Object.entries(formatObj)) {
      console.log(`${key}: ${value}`);
      const toReplace = `{${key}}`;
      if (tpl.indexOf(toReplace) === -1) {
        console.warn(
          `Invalid tpl arg !!! ${lang}:${key}='${tpl_before_format}', invalid arg:${toReplace}=${value}`
        );
      }
      console.log("toReplace", toReplace);
      tpl = tpl.replace(toReplace, value);
      console.log("replaced", tpl);
    }
  }
  return tpl;
};
export default f;
