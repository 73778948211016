import { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  Link,
  useLocation,
  Route,
  Routes,
} from "react-router-dom";

import api from "../../infra/api";
import { dispatch } from "use-bus";

import { Spin, Divider, Typography, Tabs } from "antd";

export default function GeneralContractDetailPage({ user }) {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [generalContract, setGeneralContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("general-contract-data");

  useEffect(() => {
    setActiveTabKey(getLastPartOfLocation(location.pathname));
    fetchGeneralContractData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLastPartOfLocation = (location) => {
    const lastChar = location.slice(-1);
    const locationWithoutLastSlash = lastChar === "/"
      ? location.slice(0, -1)
      : location;
    const lastSlashIndex = locationWithoutLastSlash.lastIndexOf("/");
    const lastSlashToEnd = locationWithoutLastSlash.substring(lastSlashIndex);
    const lastSlashToEndWithoutSlash = lastSlashToEnd.replace("/", "");
    return lastSlashToEndWithoutSlash;
  };

  const fetchGeneralContractData = async () => {
    startLoading();
    await api.generalContracts
      .getGeneralContract(id)
      .then((response) => {
        setGeneralContract(response.data);
        console.log("GeneralContractDetailPage general contract", response.data);
        stopLoading();
      })
      .catch(error => {
        stopLoading();
        if (error?.response.status === 400) {
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas wczytywania danych umowy generalnej "${generalContract.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwera",
            `Wystąpił błąd podczas wczytywania danych umowy generalnej "${generalContract.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas wczytywania danych umowy generalnej "${generalContract.name}"`
          );
          navigate("/403");
        }
      });
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  const items = [
    {
      key: "general-contract-data",
      label: <Link to={`/genera-contracts/${id}/general-contract-data`}>Dane umowy</Link>,
    },
  ];

  return (
    <>
      {!generalContract && (
        <Spin tip="Wczytywanie danych..." spinning={loading}>
          <div />
        </Spin>
      )}

      {generalContract && (
        <>
          <Typography.Title level={1} style={{ fontWeight: "bold" }}>
            {generalContract.name}
          </Typography.Title>

          <Divider />

          {/* <Tabs
            activeKey={activeTabKey}
            defaultActiveKey={activeTabKey}
            type="card"
            size="large"
            items={items}
          />

          <Routes>
            <Route path="" element={<GeneralContractData generalContract={generalContract} />} />
            <Route path="general-contract-data" element={<GeneralContractData generalContract={generalContract} />} />
          </Routes> */}
        </>
      )}
    </>
  );
}
