import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import api from "../../infra/api";
import useBus from "use-bus";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import useQueryParams from "../../hooks/useQueryParams.hook";

import { Divider, Row, Radio, Col, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import CustomerGroupsFilters from "../../components/customerGroup/CustomerGroupsFilters";
import CustomerGroupsTable from "../../components/customerGroup/CustomerGroupsTable";

export default function CustomerGroupsPage({ user, navigate }) {
  const [searchParams] = useSearchParams();
  const { queryParams, setQueryParams } = useQueryParams();
  const [filters, setFilters] = useState({
    name_contains: "",
  });
  const [customerGroups, setCustomerGroups] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    current: 1,
    next: null,
    prev: null,
  });
  const [loading, setLoading] = useState(false);
  const viewVariants = {
    MINE: "mine",
    ALL: "all",
    PARTICIPANT: "participant",
  };
  const [viewVariant, setViewVariant] = useState(viewVariants.MINE);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState("-id");

  const fetchCustomerGroups = async ({
    variant,
    page,
    size,
    order_by,
    filters,
  }) => {
    setLoading(true);
    const queryFilters = `name_contains=${filters.name_contains ?? ""}`;
    api.customerGroups
      .getCustomerGroups({
        variant,
        page,
        size,
        order_by,
        queryFilters,
      })
      .then(response => {
        const data = response.data;
        const pag = data.pagination;
        const res = data.results.map((result) => ({
          ...result,
          key: result.id,
        }));

        setPagination(pag);
        setCustomerGroups(res);
        setPageNumber(pag.current);
        setLoading(false);
      })
      .catch(error => {
        console.log("customer group page error", error);
      });
  };

  useEffect(() => {
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setViewVariant(paramsFromUrl.variant ?? viewVariant);
    setFilters((oldFilters) => {
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains = paramsFromUrl.name_contains ?? "";
      const data = {
        variant: paramsFromUrl.variant ?? viewVariant,
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      fetchCustomerGroups(data);
      return newFilters;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, searchParams]);

  useEffect(() => {
    console.log("CustomerGroupsPage mounted ...");
  }, []);

  // TODO: do przerobienia na jakieś lepsze rozwiązanie
  useBus("CUSTOMER_GROUP_DELETED", (event) => {
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setViewVariant(paramsFromUrl.variant ?? viewVariant);
    setFilters((oldFilters) => {
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains = paramsFromUrl.name_contains ?? oldFilters.name_contains;
      const data = {
        variant: paramsFromUrl.variant ?? viewVariant,
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      fetchCustomerGroups(data);
      return newFilters;
    });
  });

  return (
    <>
      <h1 id={TRANSLATIONS.CG_LIST__TITLE.key}>
        {translate(TRANSLATIONS.CG_LIST__TITLE.key)}
        {/* NOTE: example, tłumaczenie z paramami
        translate test:{" "}
        {translate(TRANSLATIONS.FOO_BAR.key, { foo: "jimmy", bar: "choo" })} */}
      </h1>

      <Divider />

      <CustomerGroupsFilters
        user={user}
        options={{
          viewVariant,
          pageNumber,
          pageSize,
          orderBy,
          filters,
          setFilters,
        }}
      />

      <Row
        justify="space-between"
        style={{
          marginTop: 30,
          marginBottom: 15,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Col>
          {/* Dostępny dla konkretnych ról SYS_ADMIN_AGENCY SYS_ADMIN_BROKER */}
          <Button
            id={TRANSLATIONS.CG_LIST__ADD_NEW_GROUP_BUTTON_LABEL.key}
            type="info"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/customer-groups/create");
            }}
          >
            {translate(TRANSLATIONS.CG_LIST__ADD_NEW_GROUP_BUTTON_LABEL.key)}
          </Button>
        </Col>

        <Col>
          {/* NOTE: tu chyba nie ma sensu dodatkowego componentu robic ;)  */}
          <Radio.Group
            defaultValue={viewVariant}
            value={viewVariant}
            optionType="button"
            buttonStyle="solid"
            size="large"
            onChange={(event) => {
              const selectedValue = event.target.value;
              setViewVariant(selectedValue);
              setQueryParams("/customer-groups", {
                page: 1,
                size: pageSize,
                order_by: orderBy,
                name_contains: filters.name_contains,
                variant: selectedValue,
              });
            }}
          >
            <Radio.Button
              id={TRANSLATIONS.CG_LIST__FILTER_ALL_TAB_LABEL.key}
              value={viewVariants.ALL}
            >
              {translate(TRANSLATIONS.CG_LIST__FILTER_ALL_TAB_LABEL.key)}
            </Radio.Button>

            <Radio.Button
              id={TRANSLATIONS.CG_LIST__FILTER_MINE_TAB_LABEL.key}
              value={viewVariants.MINE}
            >
              {translate(TRANSLATIONS.CG_LIST__FILTER_MINE_TAB_LABEL.key)}
            </Radio.Button>

            <Radio.Button
              id={TRANSLATIONS.CG_LIST__FILTER_PARTICIPANT_TAB_LABEL.key}
              value={viewVariants.PARTICIPANT}
            >
              {translate(TRANSLATIONS.CG_LIST__FILTER_PARTICIPANT_TAB_LABEL.key)}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <CustomerGroupsTable
        user={user}
        tableStateChanged={(newPageNumber, newPageSize, newOrderBy) => {
          setPageNumber(newPageNumber);
          setPageSize(newPageSize);
          setOrderBy(newOrderBy);
          setQueryParams("/customer-groups", {
            page: newPageNumber,
            size: newPageSize,
            order_by: newOrderBy,
            name_contains: filters.name_contains,
            variant: viewVariant,
          });
        }}
        options={{
          pagination,
          pageSize,
          items: customerGroups,
        }}
        loading={loading}
      />
    </>
  );
}
