import { Col, Form, Input, Row, Select } from "antd";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";
import api from "../../../infra/api";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {object} props.formFields
 * @param {string} props.formFields.NAME
 * @param {string} props.formFields.LEADER
 * @returns {JSX.Element}
 * */
export default function CreateAgencyTeamFormSection({ form, formHelpers, formFields }) {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    fetchAgents({ search: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAgents = useMemo(() => debounce((filters) => {
    filters = { ...filters, user_role: ["SYS_ADMIN_AGENT", "AGENT_LIDER", "AGENT"] };
    const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`).join("&");
    api.personalDatas
      .getPersonalData(query)
      .then((result) => setAgents(result.data.results));
  }, 500), []);

  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <>
      <Form.Item id="agent_team_data">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              id={formFields.NAME}
              name={formFields.NAME}
              label={
                <label
                  id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key}
                  style={{ fontWeight: "bold", fontSize: 20 }}
                >
                  {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_NAME_LABEL.key)}
                </label>
              }
              required
              rules={[
                {
                  validator: (_, value) => {
                    return formHelpers.validateRequiredGuiField(
                      formFields.NAME,
                      value,
                      "Pole wymagane"
                    );
                  },
                },
              ]}
              validateStatus={
                formHelpers.errorFields.includes(formFields.NAME)
                  ? "error"
                  : null
              }
              help={
                formHelpers.errorFields.includes(formFields.NAME)
                  ? formHelpers.getFieldErrors(formFields.NAME).join(", ")
                  : null
              }
            >
              <Input
                id={formFields.NAME}
                name={formFields.NAME}
                size="large"
                allowClear
                onChange={(e) => {
                  formHelpers.resetFieldApiError(formFields.NAME);
                  form.setFieldValue(formFields.NAME, e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              id={formFields.LEADER}
              name={formFields.LEADER}
              label={
                <label
                  id={TRANSLATIONS.AGENT_TEAM_CREATE__FORM_LEADER_LABEL.key}
                  style={{ fontWeight: "bold", fontSize: 20 }}
                >
                  {translate(TRANSLATIONS.AGENT_TEAM_CREATE__FORM_LEADER_LABEL.key)}
                </label>
              }
              required
              rules={[
                {
                  validator: (_, value) => {
                    return formHelpers.validateRequiredGuiField(
                      formFields.LEADER,
                      value,
                      "Pole wymagane"
                    );
                  },
                },
              ]}
              validateStatus={
                formHelpers.errorFields.includes(formFields.LEADER) ? "error" : null
              }
              help={
                formHelpers.errorFields.includes(formFields.LEADER)
                  ? formHelpers.getFieldErrors(formFields.LEADER).join(", ")
                  : null
              }
            >
              <Select
                allowClear
                showSearch
                onSearch={(value) => fetchAgents({ search: value })}
                size="large"
                placeholder="Wybierz lidera zespołu"
                options={agents.map((agent) => ({
                  value: agent.id,
                  label: `${agent.first_name} ${agent.last_name} (${agent.email})`,
                }))}
                filterOption={handleFilterOption}
                onChange={(value) => fetchAgents(value)}
                onClear={() => fetchAgents({ search: "" })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}
