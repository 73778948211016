import React from "react";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import { List, Card, Typography, Avatar, Empty } from "antd";
import Meta from "antd/es/card/Meta";

export default function TeamMemberListCard({ membersData, ...props }) {
	return (
		<>
			<Typography.Title
				id={TRANSLATIONS.TEAM_MEMBERS_CARD__TITLE.key}
				level={3}
				style={{ fontWeight: "bold" }}
			>
				{translate(TRANSLATIONS.TEAM_MEMBERS_CARD__TITLE.key)}
			</Typography.Title>
			<List
				{...props}
				grid={{ gutter: 16, column: 4 }}
				dataSource={membersData}
				locale={{
					emptyText: <Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={`${translate(TRANSLATIONS.TEAM_MEMBERS_CARD__EMPTY_LIST_TITLE.key)}`}
					/>
				}}
				renderItem={(member) => (
					<List.Item>
						<Card
							id={member.id}
							key={member.key}
							actions={member.actions}
						>
							<Meta
								avatar={<Avatar src={member.avatar} />}
								title={member.title}
								description={member.description}
							/>
						</Card>
					</List.Item>
				)}
			/>
		</>
	);
}
