import React, { useEffect, useCallback, useState } from "react";
import userHelper from "../../infra/userHelper";
import { Button, Col } from "antd";
import { Row } from "antd";
import api from "../../infra/api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ROLES from "../../infra/roles";
import InsurersTable from "../../components/administration/insurers/InsurersTable";
import useQueryParams from "../../hooks/useQueryParams.hook";
import InsureresFilters from "../../components/administration/insurers/InsurersFilters";

export default function AdministrationInsurers({ user }) {
  const navigate = useNavigate();
  const hasAdminRole = useCallback(() => {
    return userHelper.hasRole(user, ROLES.SYS_ADMIN);
  }, [user]);
  const [searchParams] = useSearchParams();
  const { setQueryParams } = useQueryParams();
  const [filters, setFilters] = useState({
    name_contains: " ",
    insurer: null,
  });
  const [agentTeams, setAgentTeams] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    current: 1,
    next: null,
    prev: null,
  });
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState("-id");

  useEffect(() => {
    console.log("Adminka insurers monuted ...");
  }, []);

  useEffect(() => {
    console.log("check permission... 2 access admin page");
    if (!hasAdminRole()) {
      console.log("no permission");
      navigate("/403");
    }
  }, [hasAdminRole, navigate]);

  const fetchInsurerDepots = async ({ page, size, order_by, filters }) => {
    setLoading(true);
    const queryFilters = `name_contains=${filters.name_contains ?? ""}${
      filters.insurer ? `&insurer=${filters.insurer}` : ""
    }`;
    api.insurers
      .getDepots({
        page,
        size,
        order_by,
        queryFilters,
      })
      .then((response) => {
        const data = response.data;
        const pag = data.pagination;
        const res = data.results.map((result) => ({
          ...result,
        }));

        setPagination(pag);
        setAgentTeams(res);
        setPageNumber(pag.current);
        setLoading(false);
      })
      .catch((error) => {
        console.log("agent teams page error", error);
      });
  };

  useEffect(() => {
    // NOTE: apply filters
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setFilters((oldFilters) => {
      console.log("oldFilters", oldFilters);
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains = paramsFromUrl.name_contains ?? "";
      newFilters.insurer = paramsFromUrl.insurer
        ? parseInt(paramsFromUrl.insurer)
        : null;
      const data = {
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      console.log("newFilters", newFilters);
      fetchInsurerDepots(data);
      return newFilters;
    });
    // NOTE: tu powinno tylko reagowac na search paramy
    // ....  jak beda inne np page, size to dostanie pierdolca
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div>
      <h1>Administracja - Ubezpieczyciele</h1>
      <Row>
        <Col span={24}>
          <InsureresFilters
            user={user}
            options={{
              pageNumber,
              pageSize,
              orderBy,
              filters,
              setFilters,
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <h2 style={{ marginBottom: 0 }}>Oddziały ubezpieczycieli</h2>
        </Col>
        <Col span={12}>
          <Link to="/admin/insurers/create-insurer">
            <Button>Dodaj ubezpieczyciela</Button>
          </Link>
          <span> </span>
          <Link to="/admin/insurers/create-insurer-depot">
            <Button>Dodaj oddział ubezpieczyciela</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <InsurersTable
            user={user}
            tableStateChanged={(newPageNumber, newPageSize, newOrderBy) => {
              setPageNumber(newPageNumber);
              setPageSize(newPageSize);
              setOrderBy(newOrderBy);
              setQueryParams("/admin/insurers", {
                page: newPageNumber,
                size: newPageSize,
                order_by: newOrderBy,
                name_contains: filters.name_contains ?? "",
                insurer: filters.insurer ?? "",
              });
            }}
            options={{
              pagination,
              pageSize,
              items: agentTeams,
            }}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
}
