import { Form } from "antd";
import { useMemo, useState } from "react";

/**
 * Custom hook for form handling
 * @param {Object} formFields - Object with form fields
 * @returns {Object} - Form object, form helpers object, and form fields object
 *
 * @example
 * const { form, formHelpers, formFields } = UseForm({
 *  EMAIL: "email",
 *  PASSWORD: "password",
 * });
 */
const UseForm = (formFields) => {
  const [form] = Form.useForm();
  const [apiErrors, setApiErrors] = useState({});
  const [guiErrors, setGuiErrors] = useState({});

  /**
   * Get list of errors for a form field
   * @param {string} field
   * @returns {Array<string>} - List of errors for a form field
   */
  const getFieldErrors = (field) => {
    const getGuiErrors = (field) => {
      return guiErrors[field] ?? [];
    };
    const getApiErrors = (field) => {
      return apiErrors[field] ?? [];
    };
    return getGuiErrors(field).concat(getApiErrors(field));
  };

  /**
   * Set GUI error for a form field
   * @param {string} field
   * @param {string} message
   */
  const setFieldGuiError = (field, message) => {
    setGuiErrors((oldState) => {
      const newState = Object.assign({}, oldState);
      newState[field] = [message];
      return newState;
    });
  };

  /**
   * Reset GUI errors for a form field
   * @param {string} field
   */
  const resetFieldGuiError = (field) => {
    setGuiErrors((oldState) => {
      const newState = Object.assign({}, oldState);
      newState[field] = [];
      return newState;
    });
  };

  /**
   * Reset API errors for a form field
   * @param {string} field
   *
   */
  const resetFieldApiError = (field) => {
    setApiErrors((oldState) => {
      const newState = Object.assign({}, oldState);
      newState[field] = [];
      return newState;
    });
  };

  /**
   * Memoized list of fields with errors
   * @returns {Array<string>} - List of fields with errors
   */
  const errorFields = useMemo(() => {
    const result = [];
    for (const field of Object.keys(formFields).map((key) => formFields[key])) {
      if (apiErrors[field]?.length > 0 || guiErrors[field]?.length > 0) {
        result.push(field);
      }
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrors, guiErrors]);

  // for use in Form.Item rules prop
  const validateRequiredGuiField = (field, value, message) => {
    if (!value) {
      formHelpers.setFieldGuiError(field, message);
      return Promise.reject();
    }
    formHelpers.resetFieldGuiError(field);
    return Promise.resolve();
  };

  const validateAgainstRegex = (regex, field, value, message) => {
    if (!regex.test(value)) {
      formHelpers.setFieldGuiError(field, message);
      return Promise.reject();
    }
    formHelpers.resetFieldGuiError(field);
    return Promise.resolve();
  };

  // proxy object for form helpers
  const formHelpers = {
    apiErrors,
    guiErrors,
    setApiErrors,
    setGuiErrors,
    getFieldErrors,
    setFieldGuiError,
    resetFieldGuiError,
    resetFieldApiError,
    errorFields,
    validateRequiredGuiField,
    validateAgainstRegex,
  };
  return { form, formHelpers, formFields };
};

export default UseForm;
