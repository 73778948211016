import "./App.css";
import "./styles/Header.css";
import "./styles/Buttons.css";
import "./styles/Modals.css";

import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";

import api from "./infra/api";
import useBus from "use-bus";

import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import SettingsPage from "./pages/SettingsPage";
import UsersPage from "./pages/UsersPage";

import CustomerGroupsPage from "./pages/customerGroup/CustomerGroupsPage";
import CustomerGroupCreatePage from "./pages/customerGroup/CustomerGroupCreatePage";

import CustomersPage from "./pages/customer/CustomersPage";
import CustomerCreatePage from "./pages/customer/CustomerCreatePage";
import CustomerDetailPage from "./pages/customer/CustomerDetailPage";

import GeneralContractsPage from "./pages/generalContract/GeneralContractsPage";
import GeneralContractCreatePage from "./pages/generalContract/GeneralContractCreatePage";
import GeneralContractDetailPage from "./pages/generalContract/GeneralContractDetailPage";

import BrokerTeamsPage from "./pages/brokerTeam/BrokerTeamsPage";
import BrokerTeamCreatePage from "./pages/brokerTeam/BrokerTeamCreatePage";
import BrokerTeamDetailPage from "./pages/brokerTeam/BrokerTeamDetailPage";
import BrokerTeamEditPage from "./pages/brokerTeam/BrokerTeamEditPage";

import AgentTeamsPage from "./pages/agentTeam/AgentTeamsPage";
import AgentTeamCreatePage from "./pages/agentTeam/AgentTeamCreatePage";
import AgentTeamDetailPage from "./pages/agentTeam/AgentTeamDetailPage";
import AgentTeamEditPage from "./pages/agentTeam/AgentTeamEditPage";

import DebugPageSas from "./pages/DebugPageSas";

import AdminPageInsurers from "./pages/admin/AdminPageInsurers";
import InsurerCreatePage from "./pages/admin/InsurerCreatePage";
import InsurerDepotCreatePage from "./pages/admin/InsurerDepotCreatePage";


import Page500 from "./pages/errors/Page500";
import Page404 from "./pages/errors/Page404";
import Page403 from "./pages/errors/Page403";

import TopNavbar from "./components/TopNavbar";
import SideNavbar from "./components/SideNavbar";

import { Layout, theme, notification } from "antd";
import UserProfilePage from "./pages/user/UserProfilePage";
const { Content } = Layout;


// TODO: te nawiagcje (navbary sidebary wyjac do komponentow)
// ! ^^^ powinny miec przekazanego user'a
// ! ^^^ ustawic juz jakis pseudo routing po widokach + pokazywac
// ! ^^^  chowac route w zalezonsci od uprawnien

// TODO: lista userow z paginacja i filtrami
// ! ^^^ paginacja i filtry jako url paramy
// ! narazie filtry chcem email__icontains=, username__icontains, email=, username=
function App() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const cleanStorage = () => {
    localStorage.removeItem("tokens");
    localStorage.removeItem("user");
  };

  // dla errorów warto wyświetlić wiadomość dla usera co nie pyknęło: https://ant.design/components/message
  // loading state'y tez by się przydały?
  useEffect(() => {
    const loadUserData = async () => {
      console.log("check for user and tokens in local storage");
      try {
        console.log("fetch tokens from local storage");
        const storedTokens = JSON.parse(localStorage.getItem("tokens"));
        const refresh = storedTokens.refresh;
        const refreshTokenResponse = await api.auth.refreshToken();
        const access = refreshTokenResponse.data.access;
        const tokens = JSON.stringify({
          access,
          refresh,
        });
        localStorage.setItem("tokens", tokens);
      } catch (error) {
        console.warn("must relogin:");
        cleanStorage();
        setUser(null);
        navigate("/login");
      }

      try {
        const storedTokens = JSON.parse(localStorage.getItem("tokens"));
        const access = storedTokens.access;
        const userResponse = await api.users.getMe(access);
        const user = userResponse.data;
        localStorage.setItem("user", JSON.stringify({ user }));
        setUser(user);
      } catch (error) {
        console.error("refresh user error ...", error);
        setUser(null);
        cleanStorage();
        navigate("/login");
      }
    };
    console.log("App mounted ...");
    loadUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBus("SHOW_NOTIFICATION", (event) => {
    openNotification(
      event.payload.notification.type,
      event.payload.notification.title,
      event.payload.notification.description
    );
  });

  const openNotification = (type, title, description) => {
    notificationApi[type]({
      message: title,
      description: description,
      closeIcon: false,
      duration: 5,
    });
  };


  return (
    <div>
      {!user && (
        <Routes>
          <Route path="/login" element={<LoginPage setUser={setUser} />} />
        </Routes>
      )}

      {user && (
        <Layout style={{ minHeight: "100vh" }}>
          {contextHolder}

          <TopNavbar
            navigate={navigate}
            user={user}
            setUser={setUser}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />

          <Layout>
            <SideNavbar
              collapsed={collapsed}
              navigate={navigate}
              user={user}
              setUser={setUser}
            />

            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Routes>
                <Route
                  index
                  path="/debugsas"
                  element={<DebugPageSas user={user} setUser={setUser} />}
                />
                <Route
                  index
                  path="/dashboard"
                  element={<DashboardPage user={user} setUser={setUser} />}
                />
                <Route
                  index
                  path="/"
                  element={
                    <Navigate to="/dashboard" user={user} setUser={setUser} />
                  }
                />
                <Route
                  path="/admin/insurers"
                  element={
                    <AdminPageInsurers user={user} setUser={setUser} />
                  }
                />
                <Route
                  path="/admin/insurers/create-insurer"
                  element={
                    <InsurerCreatePage user={user} setUser={setUser} />
                  }
                />

                <Route
                  path="/admin/insurers/create-insurer-depot"
                  element={
                    <InsurerDepotCreatePage user={user} setUser={setUser} />
                  }
                />
                {/* customer-groups */}
                <Route
                  path="/customer-groups"
                  element={
                    <CustomerGroupsPage
                      user={user}
                      setUser={setUser}
                      navigate={navigate}
                    />
                  }
                />
                <Route
                  path="/customer-groups/create"
                  element={
                    <CustomerGroupCreatePage user={user} setUser={setUser} />
                  }
                />

                {/* customers */}
                <Route
                  path="/customers"
                  element={<CustomersPage user={user} setUser={setUser} />}
                />
                <Route
                  path="/customers/create"
                  element={
                    <CustomerCreatePage user={user} setUser={setUser} />
                  }
                />

                <Route
                  path="/customers/:id/*"
                  element={
                    <CustomerDetailPage user={user} setUser={setUser} />
                  }
                />

                {/* general-contracts */}
                <Route
                  path="/general-contracts"
                  element={<GeneralContractsPage user={user} setUser={setUser} />}
                />
                <Route
                  path="/general-contracts/create"
                  element={
                    <GeneralContractCreatePage user={user} setUser={setUser} />
                  }
                />

                <Route
                  path="/general-contracts/:id/*"
                  element={
                    <GeneralContractDetailPage user={user} setUser={setUser} />
                  }
                />

                {/* broker-teams */}
                <Route
                  path="/broker-teams"
                  element={<BrokerTeamsPage user={user} setUser={setUser} />}
                />
                <Route
                  path="/broker-teams/:id"
                  element={
                    <BrokerTeamDetailPage user={user} setUser={setUser} />
                  }
                />
                <Route
                  path="/broker-teams/create"
                  element={
                    <BrokerTeamCreatePage user={user} setUser={setUser} />
                  }
                />
                <Route
                  path="/broker-teams/:id/update"
                  element={
                    <BrokerTeamEditPage user={user} setUser={setUser} />
                  }
                />

                {/* agent-teams */}
                <Route
                  path="/agent-teams"
                  element={<AgentTeamsPage user={user} setUser={setUser} />}
                />
                <Route
                  path="/agent-teams/:id"
                  element={
                    <AgentTeamDetailPage user={user} setUser={setUser} />
                  }
                />
                <Route
                  path="/agent-teams/create"
                  element={
                    <AgentTeamCreatePage user={user} setUser={setUser} />
                  }
                />
                <Route
                  path="/agent-teams/:id/update"
                  element={
                    <AgentTeamEditPage user={user} setUser={setUser} />
                  }
                />

                <Route
                  path="/profile/me"
                  element={<UserProfilePage user={user} setUser={setUser} />}
                />

                {/* TODO: Zrobic z tych page'ów wspólnyc komponent (?) to są tak małe komponenty że chyba nie warto */}
                <Route
                  path="/404"
                  element={<Page404 navigate={navigate} />}
                />
                <Route
                  path="/403"
                  element={<Page403 navigate={navigate} />}
                />
                <Route
                  path="/500"
                  element={<Page500 navigate={navigate} />}
                />
                <Route
                  path="/users"
                  element={<UsersPage user={user} setUser={setUser} />}
                />
                <Route
                  path="/settings"
                  element={<SettingsPage user={user} setUser={setUser} />}
                />
                <Route path="*" element={<Page404 navigate={navigate} />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
}

export default App;
