import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import { Button, Modal, Tooltip, Divider } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";

export default function BrokerTeamDeactivationModal({ record }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const submit = async (payload) => {
    startLoading();
    api.brokerTeams
      .updateBrokerTeam(payload.id, {
        status: "INACTIVE",
        leader: payload.leader,
        name: payload.name,
      })
      .then(response => {
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie dezaktywowano zespół "${payload.name}"`
        );
        dispatch({ type: "BROKER_TEAM_DEACTIVATED" });

        stopLoading();
        closeModal();
      })
      .catch(error => {
        stopLoading();
        if (error?.response.status === 400) {
          showNotification(
            "error",
            "Niepoprawne dane",
            `Wystąpił błąd podczas dezaktywacji zespołu "${payload.name}"`
          );
        } else if (error?.response.status >= 500) {
          showNotification(
            "error",
            "Błąd serwera",
            `Wystąpił błąd podczas dezaktywacji zespołu "${payload.name}"`
          );
          navigate("/500");
        } else if (error?.response.status === 403) {
          showNotification(
            "error",
            "Brak uprawnień",
            `Wystąpił błąd podczas dezaktywacji zespołu "${payload.name}"`
          );
          navigate("/403");
        }
      });
  };

  const showNotification = (type, title, description) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        notification: {
          type,
          title,
          description,
        }
      }
    });
  };

  return (
    <>
      <Tooltip
        title={translate(TRANSLATIONS.BROKER_TEAM_LIST_TABLE__ACTION_DEACTIVATION_BUTTON_LABEL.key)}
        destroyTooltipOnHide
        mouseEnterDelay={0.5}
      >
        <Button
          id={TRANSLATIONS.BROKER_TEAM_LIST_TABLE__ACTION_DEACTIVATION_BUTTON_LABEL.key}
          type="text"
          icon={<CloseCircleTwoTone twoToneColor="#ff4d4f" />}
          onClick={openModal}
        />
      </Tooltip>

      <Modal
        id="deactivate-broker-team-modal"
        title={
          <h3 id={TRANSLATIONS.BROKER_TEAM_DEACTIVATION__MODAL_TITLE.key}>
            {translate(TRANSLATIONS.BROKER_TEAM_DEACTIVATION__MODAL_TITLE.key)}
            <br />
            "{record.name}"?
          </h3>
        }
        style={{ textAlign: "center" }}
        centered
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        keyboard={true}
        open={+open}
        okText={
          loading
            ? translate(TRANSLATIONS.BROKER_TEAM_DEACTIVATION__MODAL_DEACTIVATE_BUTTON_LABEL.key)
            : translate(TRANSLATIONS.BROKER_TEAM_DEACTIVATION__MODAL_CONFIRMATION_BUTTON_LABEL.key)
        }
        okButtonProps={{
          type: "primary",
          danger: true,
          size: "large",
          htmlType: "submit",
          loading: loading,
          disabled: loading
        }}
        onOk={() => submit({
          id: record.id,
          leader: record.leader.id,
          name: record.name,
        })}
        cancelText={translate(TRANSLATIONS.BROKER_TEAM_DEACTIVATION__MODAL_CANCEL_BUTTON_LABEL.key)}
        cancelButtonProps={{
          type: "lightdark",
          ghost: true,
          size: "large",
          disabled: loading
        }}
        onCancel={closeModal}
      >
        <Divider />
      </Modal>
    </>
  );
}
