import React, { useState, useEffect, useMemo } from "react";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  PoweroffOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Layout, Button, Image, Badge, Space, Divider, Select } from "antd";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;

const PopoverButton = React.lazy(() => import("./PopoverButton"));

export default function TopNavbar({ user, setUser, collapsed, setCollapsed }) {
  const navigate = useNavigate();

  const [userMenuActive, setUserMenuActive] = useState(false);
  const [messagesActive, setMessagesActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [language, setLanguage] = useState("PL");
  const languages = [
    {
      value: "PL",
      label: "Polski"
    },
    {
      value: "EN",
      label: "Angielski"
    }
  ];

  const logout = () => {
    console.log("logout ...");
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    setUser(null);
    navigate("/login");
  };

  const messageCount = useMemo(() => {
    return messages.length;
  }, [messages]);

  useEffect(() => {
    console.log("TopNavbar mounted ...");
    console.log("generate messages ...");
    const msgs = [];
    for (let i = 1; i < 5 + 1; i++) {
      msgs.push(<p key={i}>MessageItem {i}</p>);
    }
    setMessages((oldMessages) => oldMessages.concat(msgs));
    setLanguage(localStorage.getItem("lang") ?? language);
  }, [language]);

  // TODO: MessageItem jako komponent - popover będzie wyświetlać pierwsze 5 wiadomości i będzie przycisk "Show more" 
  // ^ (czy jakoś tak), kótry będzie przenosił do widoku wiadomości
  return (
    <Header style={{ padding: 0, margin: 0, lineHeight: 0 }} theme="dark">
      <Space direction="horizontal" style={{ position: "absolute", left: 5 }}>
        <Space size="middle">
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: "24px", color: "white" }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontSize: "24px", color: "white" }}
                />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{ width: 64, height: 64 }}
          />

          {/* <Image
            style={{ width: 64, height: 64, padding: 5 }}
            src="/logo192.png"
            preview={false}
          /> */}
        </Space>
      </Space>

      <Space direction="horizontal" style={{ position: "absolute", right: 50 }}>
        <Space size="middle">

          {/* <Select
            labelInValue
            showSearch
            defaultValue={language}
            value={language}
            size="large"
            options={languages}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            onChange={(option) => {
              localStorage.setItem("lang", option.value);
              setLanguage(option.value);
              navigate(0);
            }}
            style={{ minWidth: 160 }}
          />

          <Divider className="divider-custom" type="vertical" />

          <Badge
            count={messageCount}
            overflowCount={99}
            showZero={true}
            style={{ top: 15, right: 10 }}
          >
            <PopoverButton
              title="Message List"
              subtitle=""
              content={
                <div>
                  {messages.length > 0 && messages}
                  {messages && messages.length === 0 && <p>No new messages</p>}
                </div>
              }
              onOpenChange={() => {
                setMessagesActive(!messagesActive);
              }}
              popoverButton={{
                icon: (
                  <MailOutlined
                    className={
                      messagesActive ? "icon-messages-active" : "icon-messages"
                    }
                    style={{ fontSize: "28px" }}
                  />
                ),
              }}
              actions={{
                showButton: true,
                buttonType: "link",
                isBlockButton: true,
                isDangerButton: false,
                buttonSize: "large",
                buttonIcon: <></>,
                onClick: () => {
                  console.log("Show more needs implementation!");
                },
                buttonText: "Show more",
              }}
            />
          </Badge>

          <Divider className="divider-custom" type="vertical" /> */}

          <PopoverButton
            title={user.first_name.concat(" ", user.last_name)}
            subtitle={user.username.concat(" (", user.email, ")")}
            content={
              <div>
                <Button
                  type="link"
                  size="large"
                  // disabled={true}
                  onClick={() => {
                    navigate("/profile/me");
                  }}
                >
                  Profile
                </Button>
              </div>
            }
            onOpenChange={() => {
              setUserMenuActive(!userMenuActive);
            }}
            popoverButton={{
              icon: (
                <UserOutlined
                  className={
                    userMenuActive
                      ? "icon-user-profile-active"
                      : "icon-user-profile"
                  }
                  style={{ fontSize: "24px" }}
                />
              ),
            }}
            actions={{
              showButton: true,
              buttonType: "default",
              isBlockButton: true,
              isDangerButton: true,
              buttonSize: "large",
              buttonIcon: <PoweroffOutlined />,
              onClick: () => logout(),
              buttonText: "Logout",
            }}
          />
        </Space>
      </Space>
    </Header>
  );
};
