import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

export const CUSTOMER_ROLES = [
  {
    value: "LEASING",
    label: `${translate(TRANSLATIONS.CUSTOMER_ROLE__LEASING_LABEL.key)}`
  },
  {
    value: "BANK",
    label: `${translate(TRANSLATIONS.CUSTOMER_ROLE__BANK_LABEL.key)}`
  },
  {
    value: "INSURANCE_PERSON",
    label: `${translate(
      TRANSLATIONS.CUSTOMER_ROLE__INSURANCE_PERSON_LABEL.key
    )}`
  },
  {
    value: "OWNER",
    label: `${translate(TRANSLATIONS.CUSTOMER_ROLE__OWNER_LABEL.key)}`
  },
  {
    value: "CO_OWNER",
    label: `${translate(TRANSLATIONS.CUSTOMER_ROLE__COOWNER_LABEL.key)}`
  },
  {
    value: "USER",
    label: `${translate(TRANSLATIONS.CUSTOMER_ROLE__USER_LABEL.key)}`
  },
  {
    value: "INSURED_IN_NNW_MAX",
    label: `${translate(
      TRANSLATIONS.CUSTOMER_ROLE__INSURED_IN_NNW_MAX_LABEL.key
    )}`
  },
];
