import React from "react";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import { Row, Col, Card, Typography, Avatar } from "antd";
import Meta from "antd/es/card/Meta";

export default function TeamLeaderCard({leaderData}) {
	return (
		<>
			<Typography.Title
				id={TRANSLATIONS.TEAM_LEADER_CARD__TITLE.key}
				level={3}
				style={{ fontWeight: "bold" }}
			>
				{translate(TRANSLATIONS.TEAM_LEADER_CARD__TITLE.key)}
			</Typography.Title>
			<Row gutter={16}>
				<Col span={6}>
					<Card
						id={leaderData.id}
						key={leaderData.key}
						actions={leaderData.actions}
					>
						<Meta
							avatar= {<Avatar src={leaderData.avatar} />}
							title={leaderData.title}
							description={leaderData.description}
						/>
					</Card>
				</Col>
			</Row>
		</>
  );
}
