import { useEffect, useMemo } from "react";

import { Row, Col, Form, Input, Select, Checkbox } from "antd";
import { MaskedInput } from "antd-mask-input";

import api from "../../../infra/api";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import debounce from "lodash/debounce";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {function} props.formHelpers.validateAgainstRegex
 * @param {object} props.formFields
 * @param {string} props.formFields.TYPE
 * @param {string} props.formFields.ROLE
 * @param {string} props.formFields.NAME
 * @param {string} props.formFields.REGON
 * @param {string} props.formFields.NIP
 * @param {string} props.formFields.PESEL
 * @param {string} props.formFields.FIRST_NAME
 * @param {string} props.formFields.LAST_NAME
 * @param {string} props.formFields.ADDRESS
 * @param {string} props.formFields.POSTAL_CODE
 * @param {string} props.formFields.CITY
 * @param {string} props.formFields.PHONE_1
 * @param {string} props.formFields.PHONE_2
 * @param {string} props.formFields.EMAIL
 * @param {string} props.formFields.CUSTOMER_GROUP
 * @param {string} props.formFields.AGREEMENT_ALL
 * @param {string} props.formFields.AGREEMENT_1
 * @param {string} props.formFields.AGREEMENT_2
 * @param {string} props.formFields.AGREEMENT_3
 * @param {object} props.config
 * @param {object} props.config.customerGroups
 * @param {object} props.config.setCustomerGroups
 * @param {object} props.config.customerTypes
 * @param {object} props.config.customerRoles
 * @param {object} props.config.customerRequiredFields
 * @param {object} props.config.disabledField
 * @returns {JSX.Element}
 * */
export default function CustomerDataFormSection({
  form,
  formHelpers,
  formFields,
  config,
}) {
  useEffect(() => {
    if (!form.getFieldValue(formFields.CUSTOMER_GROUP)) {
      fetchCustomerGroups({ name_contains: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomerGroups = useMemo(
    () =>
      debounce((filters) => {
        const query = Object.keys(filters).map(
          (key) => `${key}=${filters[key]}`
        );
        api.customerGroups.searchCustomerGroups(query).then((result) => {
          config.setCustomerGroups(result.data.results);
        });
      }, 500),
    [config]
  );

  // TODO: do helpera wyciągnąć, można wrzucić do helpera z max lengthem
  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const fieldIsRequired = (field) => {
    return config.customerRequiredFields.includes(field);
  };

  return (
    <Form.Item id="customer_data">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.TYPE}
            name={formFields.TYPE}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_TYPE__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_TYPE__LABEL.key)}
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.TYPE,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.TYPE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.TYPE)
                ? formHelpers.getFieldErrors(formFields.TYPE).join(", ")
                : null
            }
          >
            <Select
              disabled
              readOnly
              allowClear
              showSearch
              size="large"
              placeholder="Wybierz typ klienta"
              options={config.customerTypes}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.ROLE}
            name={formFields.ROLE}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_ROLE__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_ROLE__LABEL.key)}
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.ROLE,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.ROLE) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.ROLE)
                ? formHelpers.getFieldErrors(formFields.ROLE).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              onChange={(value) =>
                form.setFieldsValue({ [formFields.ROLE]: value })
              }
              options={config.customerRoles}
              placeholder="Wybierz rolę klienta"
              filterOption={handleFilterOption}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.NAME}
            name={formFields.NAME}
            initialValue={""}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_NAME__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_NAME__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.NAME)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.NAME)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.NAME,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.NAME);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.NAME) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.NAME)
                ? formHelpers.getFieldErrors(formFields.NAME).join(", ")
                : null
            }
            normalize={(value) => value.trimStart()}
          >
            <Input
              id={formFields.NAME}
              name={formFields.NAME}
              allowClear
              size="large"
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.NAME);
                form.setFieldsValue({ [formFields.NAME]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.REGON}
            name={formFields.REGON}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_REGON__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_REGON__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.REGON)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.REGON)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.REGON,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.REGON);
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    return formHelpers.validateAgainstRegex(
                      /^\d{9}$|^\d{14}$/g,
                      formFields.REGON,
                      value,
                      "REGON musi składać się z 9 lub 14 cyfr"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            help={
              formHelpers.errorFields.includes(formFields.REGON)
                ? formHelpers.getFieldErrors(formFields.REGON).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.REGON}
              name={formFields.REGON}
              allowClear
              disabled={config.disabledField === formFields.REGON}
              readOnly={config.disabledField === formFields.REGON}
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.REGON)
                  ? "error"
                  : null
              }
              count={{
                show: true,
                max: 14,
              }}
              maskOptions={{
                mask: "00000000000000",
                lazy: true,
                max: 14,
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.REGON);
                form.setFieldsValue({ [formFields.REGON]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.NIP}
            name={formFields.NIP}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_NIP__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_NIP__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.NIP)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.NIP)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.NIP,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.NIP);
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    return formHelpers.validateAgainstRegex(
                      /^\d{10}$/g,
                      formFields.NIP,
                      value,
                      "NIP musi składać się z 10 cyfr"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            help={
              formHelpers.errorFields.includes(formFields.NIP)
                ? formHelpers.getFieldErrors(formFields.NIP).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.NIP}
              name={formFields.NIP}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.NIP)
                  ? "error"
                  : null
              }
              count={{
                show: true,
                max: 10,
              }}
              maskOptions={{
                mask: "0000000000",
                lazy: true,
                max: 10,
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.NIP);
                form.setFieldsValue({ [formFields.NIP]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.PESEL}
            name={formFields.PESEL}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_PESEL__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_PESEL__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.PESEL)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.PESEL)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.PESEL,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.PESEL);
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    return formHelpers.validateAgainstRegex(
                      /^\d{11}$/g,
                      formFields.PESEL,
                      value,
                      "PESEL musi składać się z 11 cyfr"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            help={
              formHelpers.errorFields.includes(formFields.PESEL)
                ? formHelpers.getFieldErrors(formFields.PESEL).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.PESEL}
              name={formFields.PESEL}
              allowClear
              disabled={config.disabledField === formFields.PESEL}
              readOnly={config.disabledField === formFields.PESEL}
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.PESEL)
                  ? "error"
                  : null
              }
              count={{
                show: true,
                max: 11,
              }}
              maskOptions={{
                mask: "00000000000",
                lazy: true,
                max: 11,
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.PESEL);
                form.setFieldsValue({ [formFields.PESEL]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.FIRST_NAME}
            name={formFields.FIRST_NAME}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_FIRST_NAME__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_FIRST_NAME__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.FIRST_NAME)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.FIRST_NAME)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.FIRST_NAME,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.FIRST_NAME);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.FIRST_NAME)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.FIRST_NAME)
                ? formHelpers.getFieldErrors(formFields.FIRST_NAME).join(", ")
                : null
            }
            normalize={(value) => value.trimStart()}
          >
            <Input
              id={formFields.FIRST_NAME}
              name={formFields.FIRST_NAME}
              allowClear
              size="large"
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.FIRST_NAME);
                form.setFieldsValue({
                  [formFields.FIRST_NAME]: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.LAST_NAME}
            name={formFields.LAST_NAME}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_LAST_NAME__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_LAST_NAME__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.LAST_NAME)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.LAST_NAME)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.LAST_NAME,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.LAST_NAME);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.LAST_NAME)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.LAST_NAME)
                ? formHelpers.getFieldErrors(formFields.LAST_NAME).join(", ")
                : null
            }
            normalize={(value) => value.trimStart()}
          >
            <Input
              id={formFields.LAST_NAME}
              name={formFields.LAST_NAME}
              allowClear
              size="large"
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.LAST_NAME);
                form.setFieldsValue({ [formFields.LAST_NAME]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.ADDRESS}
            name={formFields.ADDRESS}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_ADDRESS__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_ADDRESS__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.ADDRESS)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.ADDRESS)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.ADDRESS,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.ADDRESS);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.ADDRESS)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.ADDRESS)
                ? formHelpers.getFieldErrors(formFields.ADDRESS).join(", ")
                : null
            }
            normalize={(value) => value.trimStart()}
          >
            <Input
              id={formFields.ADDRESS}
              name={formFields.ADDRESS}
              allowClear
              size="large"
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.ADDRESS);
                form.setFieldsValue({ [formFields.ADDRESS]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.POSTAL_CODE}
            name={formFields.POSTAL_CODE}
            initialValue={null}
            required={fieldIsRequired(formFields.POSTAL_CODE)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.POSTAL_CODE)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.POSTAL_CODE,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.POSTAL_CODE);
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    return formHelpers.validateAgainstRegex(
                      /^\d{2}-\d{3}$/g,
                      formFields.POSTAL_CODE,
                      value,
                      "Kod pocztowy musi być w formacie 00-000"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_POSTAL_CODE__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_POSTAL_CODE__LABEL.key)}
              </label>
            }
            help={
              formHelpers.errorFields.includes(formFields.POSTAL_CODE)
                ? formHelpers.getFieldErrors(formFields.POSTAL_CODE).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.POSTAL_CODE}
              name={formFields.POSTAL_CODE}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.POSTAL_CODE)
                  ? "error"
                  : null
              }
              count={{
                show: true,
                max: 6,
              }}
              maskOptions={{
                mask: "00-000",
                lazy: true,
                max: 6,
              }}
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.POSTAL_CODE);
                form.setFieldsValue({
                  [formFields.POSTAL_CODE]: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.CITY}
            name={formFields.CITY}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_CITY__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_CITY__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.CITY)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.CITY)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.CITY,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.CITY);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.CITY) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.CITY)
                ? formHelpers.getFieldErrors(formFields.CITY).join(", ")
                : null
            }
            normalize={(value) => value.trimStart()}
          >
            <Input
              id={formFields.CITY}
              name={formFields.CITY}
              allowClear
              size="large"
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.CITY);
                form.setFieldsValue({ [formFields.CITY]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.PHONE_1}
            name={formFields.PHONE_1}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_PHONE_1__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_PHONE_1__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.PHONE_1)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.PHONE_1)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.PHONE_1,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.PHONE_1);
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    const finalValue = value.replace(/\s/g, "");
                    return formHelpers.validateAgainstRegex(
                      /^\d{9}$/g,
                      formFields.PHONE_1,
                      finalValue,
                      "Telefon musi być w formacie 000 000 000"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.PHONE_1) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.PHONE_1)
                ? formHelpers.getFieldErrors(formFields.PHONE_1).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.PHONE_1}
              name={formFields.PHONE_1}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.PHONE_1)
                  ? "error"
                  : null
              }
              count={{
                show: true,
                max: 11,
              }}
              maskOptions={{
                mask: "000 000 000",
                lazy: true,
                max: 11,
              }}
              onChange={(e) => {
                const finalValue = e.target.value.replace(/\s/g, "");
                formHelpers.resetFieldApiError(formFields.PHONE_1);
                form.setFieldsValue({ [formFields.PHONE_1]: finalValue });
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.PHONE_2}
            name={formFields.PHONE_2}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_PHONE_2__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_PHONE_2__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.PHONE_2)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.PHONE_2)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.PHONE_2,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.PHONE_2);
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    const finalValue = value.replace(/\s/g, "");
                    return formHelpers.validateAgainstRegex(
                      /^\d{9}$/g,
                      formFields.PHONE_2,
                      finalValue,
                      "Telefon musi być w formacie 000 000 000"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.PHONE_2) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.PHONE_2)
                ? formHelpers.getFieldErrors(formFields.PHONE_2).join(", ")
                : null
            }
          >
            <MaskedInput
              id={formFields.PHONE_2}
              name={formFields.PHONE_2}
              allowClear
              size="large"
              status={
                formHelpers.errorFields.includes(formFields.PHONE_2)
                  ? "error"
                  : null
              }
              count={{
                show: true,
                max: 11,
              }}
              maskOptions={{
                mask: "000 000 000",
                lazy: true,
                max: 11,
              }}
              onChange={(e) => {
                const finalValue = e.target.value.replace(/\s/g, "");
                formHelpers.resetFieldApiError(formFields.PHONE_2);
                form.setFieldsValue({ [formFields.PHONE_2]: finalValue });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.EMAIL}
            name={formFields.EMAIL}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_EMAIL__LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_EMAIL__LABEL.key)}
              </label>
            }
            required={fieldIsRequired(formFields.EMAIL)}
            rules={[
              {
                validator: (_, value) => {
                  if (fieldIsRequired(formFields.EMAIL)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.EMAIL,
                      value,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.EMAIL);
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value) {
                    return formHelpers.validateAgainstRegex(
                      /^[a-zA-Z\d._-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/g,
                      formFields.EMAIL,
                      value,
                      "Należy wprowadzić poprawny adres e-mail"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.EMAIL)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.EMAIL)
                ? formHelpers.getFieldErrors(formFields.EMAIL).join(", ")
                : null
            }
            normalize={(value) => value.trimStart()}
          >
            <Input
              id={formFields.EMAIL}
              name={formFields.EMAIL}
              allowClear
              size="large"
              onChange={(e) => {
                formHelpers.resetFieldApiError(formFields.EMAIL);
                form.setFieldsValue({ [formFields.EMAIL]: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Form.Item
            id={formFields.CUSTOMER_GROUP}
            name={formFields.CUSTOMER_GROUP}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_CREATE__FORM_CUSTOMER_GROUP_LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(
                  TRANSLATIONS.CUSTOMER_CREATE__FORM_CUSTOMER_GROUP_LABEL.key
                )}
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.CUSTOMER_GROUP)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.CUSTOMER_GROUP)
                ? formHelpers
                  .getFieldErrors(formFields.CUSTOMER_GROUP)
                  .join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              onSearch={(value) =>
                fetchCustomerGroups({ name_contains: value })
              }
              size="large"
              placeholder="Wybierz grupę klienta"
              options={config.customerGroups.map((customerGroup) => ({
                value: customerGroup.id,
                label: customerGroup.name,
              }))}
              filterOption={handleFilterOption}
              onClear={() => {
                form.setFieldsValue({
                  [formFields.CUSTOMER_GROUP]: null,
                });
                fetchCustomerGroups({ name_contains: "" });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
          <Form.Item
            id={formFields.AGREEMENT_ALL}
            name={formFields.AGREEMENT_ALL}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              checked={form.getFieldValue([formFields.AGREEMENT_ALL])}
              onChange={(e) => {
                const checked = e.target.checked;
                form.setFieldsValue({
                  [formFields.AGREEMENT_ALL]: checked,
                  [formFields.AGREEMENT_1]: checked,
                  [formFields.AGREEMENT_2]: checked,
                  [formFields.AGREEMENT_3]: checked,
                });
              }}
            >
              {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_AGREEMENT_ALL_LABEL.key)}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
          <Form.Item
            id={formFields.AGREEMENT_1}
            name={formFields.AGREEMENT_1}
            valuePropName="checked"
            initialValue={false}
            required={form.getFieldValue(formFields.AGREEMENT_1)}
            rules={[
              {
                validator: (_, checked) => {
                  if (fieldIsRequired(formFields.AGREEMENT_1)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.AGREEMENT_1,
                      checked,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.AGREEMENT_1);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.AGREEMENT_1) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.AGREEMENT_1)
                ? formHelpers.getFieldErrors(formFields.AGREEMENT_1).join(", ")
                : null
            }
          >
            <Checkbox
              checked={form.getFieldValue([formFields.AGREEMENT_1])}
              onChange={(e) => form.setFieldsValue({ [formFields.AGREEMENT_1]: e.target.checked })}
            >
              {fieldIsRequired(formFields.AGREEMENT_1) &&
                <span style={{ color: "red", fontWeight: "bold", paddingRight: 5 }}>
                  *
                </span>
              }
              <span>
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_AGREEMENT1_LABEL.key)}
              </span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
          <Form.Item
            id={formFields.AGREEMENT_2}
            name={formFields.AGREEMENT_2}
            valuePropName="checked"
            initialValue={false}
            required={form.getFieldValue(formFields.AGREEMENT_2)}
            rules={[
              {
                validator: (_, checked) => {
                  if (fieldIsRequired(formFields.AGREEMENT_2)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.AGREEMENT_2,
                      checked,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.AGREEMENT_2);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.AGREEMENT_2) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.AGREEMENT_2)
                ? formHelpers.getFieldErrors(formFields.AGREEMENT_2).join(", ")
                : null
            }
          >
            <Checkbox
              checked={form.getFieldValue([formFields.AGREEMENT_2])}
              onChange={(e) => form.setFieldsValue({ [formFields.AGREEMENT_2]: e.target.checked })}
            >
              {fieldIsRequired(formFields.AGREEMENT_2) &&
                <span style={{ color: "red", fontWeight: "bold", paddingRight: 5 }}>
                  *
                </span>
              }
              <span>
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_AGREEMENT2_LABEL.key)}
              </span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
          <Form.Item
            id={formFields.AGREEMENT_3}
            name={formFields.AGREEMENT_3}
            valuePropName="checked"
            required={fieldIsRequired(formFields.AGREEMENT_3)}
            rules={[
              {
                validator: (_, checked) => {
                  if (fieldIsRequired(formFields.AGREEMENT_3)) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.AGREEMENT_3,
                      checked,
                      "Pole wymagane"
                    );
                  }
                  formHelpers.resetFieldGuiError(formFields.AGREEMENT_3);
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.AGREEMENT_3) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.AGREEMENT_3)
                ? formHelpers.getFieldErrors(formFields.AGREEMENT_3).join(", ")
                : null
            }
          >
            <Checkbox
              checked={form.getFieldValue([formFields.AGREEMENT_3])}
              onChange={(e) => form.setFieldsValue({ [formFields.AGREEMENT_3]: e.target.checked })}
            >
              {fieldIsRequired(formFields.AGREEMENT_3) &&
                <span style={{ color: "red", fontWeight: "bold", paddingRight: 5 }}>
                  *
                </span>
              }
              <span>
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_AGREEMENT3_LABEL.key)}
              </span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
}
