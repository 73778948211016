import React, { useEffect } from "react";
import { Empty, Table } from "antd";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

// insurer depot includes insurer
export default function InsurersTable({ tableStateChanged, options, loading }) {
  const pageSizeOptions = [10, 15, 25, 50, 100, 500];
  const sortTooltipDescription = {
    asc: `${translate(
      TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL.key
    )}`,
    desc: `${translate(
      TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL.key
    )}`,
    cancel: `${translate(
      TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL.key
    )}`,
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 5,
      hidden: true,
    },
    {
      // TODO: translate l8r
      //   title: `${translate(
      //     TRANSLATIONS.AGENT_TEAM_LIST_TABLE__HEADERS_NAME_LABEL.key
      //   )}`,
      title: "Kod oddziału",
      width: 10,
      render: (insurerDepot) => {
        return <span>{insurerDepot.key}</span>;
      },
    },
    {
      title: "Ubezpieczyciel",
      width: 20,
      render: (insurerDepot) => {
        // TODO: component do renderowania ubezpieczyciela
        return (
          <span>
            ({insurerDepot.insurer.key}) {insurerDepot.insurer.name}
            <br /> NIP: {insurerDepot.insurer.nip}
            <br /> REGON: {insurerDepot.insurer.regon}
          </span>
        );
      },
    },
    {
      title: "Oddział",
      width: 20,
      render: (insurerDepot) => {
        return <span>{insurerDepot.name}</span>;
      },
    },
    {
      title: "Adres",
      width: 25,
      render: (insurerDepot) => {
        return (
          // TODO: component do renderowania adresu
          <span>
            {insurerDepot.address},{insurerDepot.postal_code},
            {insurerDepot.address}
          </span>
        );
      },
    },
    {
      title: "Kontakty",
      width: 35,
      render: (insurerDepot) => {
        // TODO: component do renderowania kontaktów
        const result = [];
        if (insurerDepot.contact_person_1)
          result.push(
            <span>
              {insurerDepot.contact_person_1?.first_name} {""}
              {insurerDepot.contact_person_1?.last_name}
              <br />
              {insurerDepot.contact_person_1?.email}
              <br />
              {insurerDepot.contact_person_1?.phone}
              <hr />
            </span>
          );
        if (insurerDepot.contact_person_2)
          result.push(
            <span>
              {insurerDepot.contact_person_2?.first_name} {""}
              {insurerDepot.contact_person_2?.last_name}
              <br />
              {insurerDepot.contact_person_2?.email}
              <br />
              {insurerDepot.contact_person_2?.phone}
              <hr />
            </span>
          );
        if (insurerDepot.contact_person_3)
          result.push(
            <span>
              {insurerDepot.contact_person_3?.first_name} {""}
              {insurerDepot.contact_person_3?.last_name}
              <br />
              {insurerDepot.contact_person_3?.email}
              <br />
              {insurerDepot.contact_person_3?.phone}
              <hr />
            </span>
          );
        if (insurerDepot.contact_person_4)
          result.push(
            <span>
              {insurerDepot.contact_person_4?.first_name} {""}
              {insurerDepot.contact_person_4?.last_name}
              <br />
              {insurerDepot.contact_person_4?.email}
              <br />
              {insurerDepot.contact_person_4?.phone}
              <hr />
            </span>
          );
        if (insurerDepot.contact_person_5)
          result.push(
            <span>
              {insurerDepot.contact_person_5?.first_name} {""}
              {insurerDepot.contact_person_5?.last_name}
              <br />
              {insurerDepot.contact_person_5?.email}
              <br />
              {insurerDepot.contact_person_5?.phone}
            </span>
          );
        return result;
      },
    },
  ];

  useEffect(() => {
    console.log("AgentTeamsTable mounted ...");
  }, []);

  return (
    <Table
      id="agent-teams"
      size="large"
      virtual
      columns={columns}
      dataSource={options.items ?? []}
      pagination={{
        position: ["topRight", "bottomRight"],
        current: options.pagination.current,
        defaultCurrent: 1,
        defaultPageSize: options.pageSize,
        pageSize: options.pageSize,
        total: options.pagination.count,
        pageSizeOptions: pageSizeOptions,
        showTotal: (total) => `Total ${total} items`,
        showSizeChanger: true,
        // onChange: (newPageNumber, newPageSize) => {
        //   tableStateChanged(newPageNumber, newPageSize);
        // },
      }}
      loading={loading}
      showSorterTooltip={true}
      locale={{
        triggerAsc: sortTooltipDescription.asc,
        triggerDesc: sortTooltipDescription.desc,
        cancelSort: sortTooltipDescription.cancel,
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={`${translate(
              TRANSLATIONS.TEAM_MEMBERS_CARD__EMPTY_LIST_TITLE.key
            )}`}
          />
        ),
      }}
      rowKey="id"
      bordered
      scroll={{
        y: 1000,
        x: 1300,
      }}
      onChange={(pagination, filters, sorter) => {
        // TODO: nałożenie aktualnego starnu sortowania z urla
        // ... kolumna ma atrubyt sortOrder, zapewne trzeba bedzie go ustawić po wejściu na urla
        // ... wartości przyjmowane przez sortOrder - "ascend", "descend", null
        const sortedOrder = sorter.order ?? "";
        const sortedField = sorter.columnKey ?? "";
        const orderBy =
          sortedOrder === "ascend"
            ? `-${sortedField}`
            : sortedOrder === "descend"
            ? `${sortedField}`
            : "-id";
        tableStateChanged(pagination.current, pagination.pageSize, orderBy);
      }}
    />
  );
}
