import { useEffect, useMemo, useState } from "react";

import { Form, Row, Col, Select, Button, Input, Checkbox, Space, Divider, Tooltip, Spin } from "antd";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";

import api from "../../../infra/api";

import debounce from "lodash/debounce";
import useBus from "use-bus";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {function} props.formHelpers.validateAgainstRegex
 * @param {object} props.config
 * @param {object} props.config.customerContactPersonsElements
 * @param {object} props.config.setCustomerContactPersonsElements
 * @param {object} props.config.createContactPersonFormActive
 * @param {object} props.config.setCreateContactPersonFormActive
 * @param {object} props.config.contactPersonElementNum
 * @param {object} props.config.setContactPersonElementNum
 * @param {object} props.config.customerContactPersons
 * @param {object} props.config.setCustomerContactPersons
 * @returns {JSX.Element}
 * */
export default function ContactPersonsSection({
	form,
	formHelpers,
	formFields,
	config,
}) {
	const [fetching, setFetching] = useState(false);

	useEffect(() => {
		if (config.customerContactPersons.length === 0) {
			fetchPersons({ search: "" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchPersons = useMemo(
		() =>
			debounce((filters) => {
				setFetching(true);
				const query = Object.keys(filters)
					.map((key) => `${key}=${filters[key]}`)
					.join("&");
				api.personalDatas
					.getPersonalData(query)
					.then((result) => {
						config.setCustomerContactPersons(result.data.results);
						setFetching(false);
					});
			}, 500),
		[config]
	);

	useBus("CUSTOMER_EMPLOYEE_CONTACT_PERSON_CREATED", (event) => {
		config.setCustomerContactPersons((oldState) => {
			return [...oldState, event.payload.data];
		});
		form.setFieldValue(`pd_id_${event.payload.num}`, event.payload.data.id);
	});

	const handleFilterOption = (input, option) => {
		return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
	};

	return (
		<>

			{config.customerContactPersonsElements.map((num) => (
				<div key={num}>
					<Row gutter={8}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Space className="full-width-space" direction="horizontal" size="small" style={{ width: "100%" }}>
								<Form.Item
									id={`pd_id_${num}`}
									name={`pd_id_${num}`}
									initialValue={null}
									label={
										<label
											id={`pd_id_${num}`}
											style={{ fontWeight: "bold" }}
										>
											Osoba kontaktowa po stronie klienta {num}
										</label>
									}
									required
									rules={[
										{
											validator: (_, value) => {
												return formHelpers.validateRequiredGuiField(
													`pd_id_${num}`,
													value,
													"Pole wymagane"
												);
											},
										},
									]}
									validateStatus={
										formHelpers.errorFields.includes(`pd_id_${num}`)
											? "error"
											: null
									}
									help={
										formHelpers.errorFields.includes(`pd_id_${num}`)
											? formHelpers.getFieldErrors(`pd_id_${num}`).join(", ")
											: null
									}
								>
									<Select
										allowClear
										showSearch
										onSearch={(value) => fetchPersons({ search: value })}
										size="large"
										placeholder="Wybierz osobę kontaktową po stronie klienta"
										options={config.customerContactPersons.map((person) => ({
											value: person.id,
											label: `${person.first_name} ${person.last_name} (${person.email})`,
										}))}
										filterOption={handleFilterOption}
										notFoundContent={fetching ? <Spin /> : null}
										onChange={(value) => {
											if (value) {
												form.setFieldValue(`pd_id_${num}`, value);
											}
										}}
										onClear={() => {
											form.setFieldValue(`pd_id_${num}`, null);
											fetchPersons({ search: "" });
										}}
									/>
								</Form.Item>

								<Form.Item label>
									<Tooltip title="Dodaj nowy kontakt" destroyTooltipOnHide>
										<Button
											id={`add_contact_${num}`}
											type="link"
											htmlType="button"
											size="large"
											shape="circle"
											disabled={config.createContactPersonFormActive || form.getFieldValue(`pd_id_${num}`)}
											icon={<PlusCircleFilled />}
											onClick={() => {
												config.setCreateContactPersonFormActive(true);
												config.setContactPersonElementNum(num);
											}}
										/>
									</Tooltip>
								</Form.Item>

								{config.customerContactPersonsElements.length > 1 && (
									<Form.Item label>
										<Tooltip title="Usuń pozycję" destroyTooltipOnHide>
											<Button
												id={`remove_contact_${num}`}
												type="link"
												danger
												htmlType="button"
												size="large"
												shape="circle"
												disabled={config.createContactPersonFormActive}
												icon={<MinusCircleFilled />}
												onClick={(e) => {
													// update form fields values, according to removed num
													for (let i = num; i <= config.customerContactPersonsElements.length; i++) {
														const nextFieldIndex = i + 1;
														form.setFieldValue(`pd_id_${i}`, form.getFieldValue(`pd_id_${nextFieldIndex}`));
														form.setFieldValue(`description_${i}`, form.getFieldValue(`description_${nextFieldIndex}`));
														form.setFieldValue(`is_signable_${i}`, form.getFieldValue(`is_signable_${nextFieldIndex}`));
													}

													// reset errors for removed num
													formHelpers.resetFieldApiError(`pd_id_${num}`);
													formHelpers.resetFieldGuiError(`pd_id_${num}`);

													// remove num and set new fields
													config.customerContactPersonsElements.splice(config.customerContactPersonsElements.indexOf(num), 1);
													config.setCustomerContactPersonsElements(Array.from(config.customerContactPersonsElements, (_, index) => index + 1));
												}}
											/>
										</Tooltip>
									</Form.Item>
								)}
							</Space>

							<Form.Item
								id={`description_${num}`}
								name={`description_${num}`}
								initialValue={null}
								label={
									<label
										id={`description_${num}`}
										style={{ fontWeight: "bold" }}
									>
										Opis
									</label>
								}
								rules={[
									{
										validator: (_, value) => {
											if (value) {
												return formHelpers.validateAgainstRegex(
													/^.{0,100}$/,
													`description_${num}`,
													value,
													"Opis może zawierać maksymalnie 100 znaków"
												);
											}
											return Promise.resolve();
										},
									},
								]}
								normalize={(value) => value.trimStart()}
							>
								<Input
									id={`description_${num}`}
									name={`description_${num}`}
									allowClear
									size="large"
									count={{
										show: true,
										max: 100,
									}}
									onChange={(e) => {
										const finalValue = e.target.value.length > 100 ? e.target.value.trim().slice(0, 100) : e.target.value;
										form.setFieldValue(`description_${num}`, finalValue);
									}}
								/>
							</Form.Item>

							<Form.Item
								id={`is_signable_${num}`}
								name={`is_signable_${num}`}
								initialValue={false}
								valuePropName="checked"
							>
								<Checkbox
									checked={form.getFieldValue(`is_signable_${num}`)}
									onChange={(e) => {
										form.setFieldValue(`is_signable_${num}`, e.target.checked);
									}}
								>
									<span style={{ fontWeight: "bold" }}>
										Może zalogować się do systemu
									</span>
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>

					<Divider />
				</div>
			))}

			{config.customerContactPersonsElements.length <= 9 && (
				<Form.Item>
					<Button
						type="dashed"
						size="large"
						block
						onClick={() => {
							if (config.customerContactPersonsElements.length < 10) {
								config.setCustomerContactPersonsElements([...config.customerContactPersonsElements, config.customerContactPersonsElements.length + 1]);
							}
						}}
					>
						Dodaj kolejną pozycję
					</Button>
				</Form.Item>
			)}
		</>
	);
}
