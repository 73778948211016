import debounce from "lodash/debounce";
import React, { useState, useMemo } from "react";
import { Select, Spin } from "antd";

export default function DebounceSelectPerson({ fetchOptions, debounceTimeout = 500, ...props }) {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			setFetching(true);
			setOptions([]);

			fetchOptions(value).then((newOptions) => {
				const opt = newOptions.map((option) => ({
					label: option.name
						? `${option.name} (${option.name})`
						: option.company_name
							? `${option.company_name} (${option.email})`
							: `${option.first_name} ${option.last_name} (${option.email})`,
					value: option.id,
				}));
				setOptions(opt);
				setFetching(false);
			});
		};
		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	return (
		<Select
			labelInValue
			allowClear
			size="large"
			showSearch={{ single: true }}
			filterOption={false}
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin /> : null}
			{...props}
			options={options}
		/>
	);
}
