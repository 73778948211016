import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom'

import api from "../../infra/api";
import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import useBus from "use-bus";

import { Tag, Divider, Spin, Card, Space, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

import AgentTeamMemberAddModal from "../../components/agentTeam/member/AgentTeamMemberAddModal";
import TeamLeaderCard from "../../components/commons/TeamLeaderCard";
import TeamMemberListCard from "../../components/commons/TeamMemberListCard";
import AgentTeamMemberEditModal from "../../components/agentTeam/member/AgentTeamMemberEditModal";
import AgentTeamMemberRemoveModal from "../../components/agentTeam/member/AgentTeamMemberRemoveModal";

export default function AgentTeamDetailPage({ user }) {
	let { id } = useParams();
	const [agentTeam, setAgentTeam] = useState(null);
	const [leader, setLeader] = useState({
		key: null,
		id: null,
		title: "",
		description: "",
		actions: [],
	});
	const [agents, setAgents] = useState([{
		key: null,
		id: null,
		title: "",
		description: "",
		actions: [],
	}])
	const [loading, setLoading] = useState(false);
	const statusesDict = {
		active: {
			text: "ACTIVE",
			translation: translate(TRANSLATIONS.AGENT_TEAM_DETAILS__STATUSES_ACTIVE_LABEL.key),
		},
		inactive: {
			text: "INACTIVE",
			translation: translate(TRANSLATIONS.AGENT_TEAM_DETAILS__STATUSES_INACTIVE_LABEL.key),
		},
	};

	const fetchAgentTeam = async (id) => {
		setLoading(true);
		api.agentTeams
			.getAgentTeam(id)
			.then(response => {
				const data = response.data;
				console.log(data);
				setAgentTeam(data);

				const leaderData = data.leader;
				setLeader((oldState) => {
					const newState = Object.assign({}, oldState);
					newState.key = leaderData.id;
					newState.id = leaderData.id;
					newState.title = `${leaderData.first_name} ${leaderData.last_name}`;
					newState.description = <>
						<span>{leaderData.email}</span>
						{leaderData.phone &&
							<>
								<br />
								<span>{leaderData.phone}</span>
							</>
						}
					</>;
					newState.avatar = leaderData.first_name.slice(-1).toLowerCase() === "a" ? "/avatar2.svg" : "/avatar.svg";
					newState.actions = [<AgentTeamMemberEditModal teamId={Number(id)} personalData={leaderData} />];
					return newState;
				});

				setAgents((oldState) => {
					const state = Object.assign({}, oldState[0]);
					const nState = data.agents
						.filter((agent) => agent.personal_data.id !== leaderData.id)
						.map((agent) => {
							const newState = Object.assign({}, state);
							const agentId = agent.id;
							const personalData = agent.personal_data;
							personalData.agent_id = agentId;
							newState.key = agentId;
							newState.id = agentId;
							newState.title = `${personalData.first_name} ${personalData.last_name}`;
							newState.description = <>
								<span>{personalData.email}</span>
								{personalData.phone &&
									<>
										<br />
										<span>{personalData.phone}</span>
									</>
								}
							</>;
							newState.avatar = personalData.first_name.slice(-1).toLowerCase() === "a" ? "/avatar2.svg" : "/avatar.svg";
							newState.actions = [
								<AgentTeamMemberEditModal teamId={Number(id)} personalData={personalData} />,
								<AgentTeamMemberRemoveModal member={newState} />
							];
							return newState;
						});
					return nState;
				});
				setLoading(false);
			})
			.catch(error => {
				console.log("agent team details page error", error);
				setLoading(false);
			});
	};

	useBus("AGENT_ADDED", (event) => {
		fetchAgentTeam(id);
	});

	useBus("AGENT_REMOVED", (event) => {
		fetchAgentTeam(id);
	});

	useBus("AGENT_UPDATED", (event) => {
		fetchAgentTeam(id);
	});

	useEffect(() => {
		console.log("AgentTeamDetailsPage mounted ...");
		fetchAgentTeam(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!agentTeam &&
				<Spin tip="Wczytywanie danych..." spinning={loading}>
					<div />
				</Spin>
			}

			{agentTeam &&
				<>
					<h1>
						<span>{agentTeam.name}</span>
						<Tag
							color={agentTeam.status === statusesDict.active.text ? "success" : "error"}
							style={{ marginLeft: 15, marginTop: 10, position: "absolute" }}
						>
							{statusesDict[agentTeam.status.toLowerCase()].translation}
						</Tag>
					</h1>

					<Divider />

					<Card
						style={{ marginBottom: 10, marginTop: 15 }}
						type="inner"
						title={
							<h3 id={TRANSLATIONS.AGENT_TEAM_DETAILS__ACTIONS_TILE.key}>
								{translate(TRANSLATIONS.AGENT_TEAM_DETAILS__ACTIONS_TILE.key)}
							</h3>
						}
					>
						<Space direction="horizontal" size="middle">
							<AgentTeamMemberAddModal agentTeamId={Number(id)} />
							<Link to={`/agent-teams/${id}/update`}>
								<Button type="primary" htmlType="button" size="large" icon={<EditOutlined />}>
									Edytuj dane zespołu
								</Button>
							</Link>
						</Space>
					</Card>

					<TeamLeaderCard leaderData={leader} />

					<TeamMemberListCard membersData={agents} loading={loading} />
				</>
			}
		</>
	)
}
