const customerConfig = {
	PHISICAL_PERSON_WITHOUT_ECONOMIC_ACTIVITY: {
		avaiable_roles: [
			"INSURANCE_PERSON",
			"OWNER",
			"CO_OWNER",
			"USER",
			"INSURED_IN_NNW_MAX",
		],
		required_fields: [
			"pesel",
			"first_name",
			"last_name",
			"address",
			"postal_code",
			"city",
			"agreement_1",
			"agreement_2",
			"agreement_3",
		],
	},
	PHISICAL_PERSON_WITH_ECONOMIC_ACTIVITY: {
		avaiable_roles: [
			"INSURANCE_PERSON",
			"OWNER",
			"CO_OWNER",
			"USER",
			"INSURED_IN_NNW_MAX",
		],
		required_fields: [
			"name",
			"regon",
			"pesel",
			"first_name",
			"last_name",
			"address",
			"postal_code",
			"city",
			"email",
			"agreement_1",
			"agreement_2",
			"agreement_3",
		],
	},
	LEGAL_PERSON: {
		avaiable_roles: [
			"LEASING",
			"BANK",
			"INSURANCE_PERSON",
			"OWNER",
			"CO_OWNER",
			"USER",
			"INSURED_IN_NNW_MAX",
		],
		required_fields: [
			"name",
			"regon",
			"address",
			"postal_code",
			"city",
			"email",
		],
	},
	PARTNERSHIP: {
		avaiable_roles: [
			"USER",
		],
		required_fields: [
			"name",
			"regon",
			"address",
			"postal_code",
			"city",
			"email",
		],
	}
};

// A helper function to get available roles for a given customer type
function getAvailableRoles(customerType) {
	return customerConfig[customerType]?.avaiable_roles ?? [];
};

// A helper function to get required fields for a given customer type
function getRequiredFields(customerType) {
	return customerConfig[customerType]?.required_fields ?? [];
};

export { getAvailableRoles, getRequiredFields };
