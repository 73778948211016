import React, { useEffect, useState } from "react";
import api from "../infra/api";

import { Divider, Table, Space, Button, Card, Input, Typography } from "antd";
import { PlusOutlined } from '@ant-design/icons';

export default function UsersPage({ user, setUser }) {
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [urlParams] = useState(new URLSearchParams(window.location.search));
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 50
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      fixed: "left",
      width: 150
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 150
    },
    {
      title: "Kolumna 1",
      width: 120
    },
    {
      title: "Kolumna 2",
      width: 120
    },
    {
      title: "Kolumna 3",
      width: 120
    },
    {
      title: "Kolumna 4",
      width: 120
    },
    {
      title: "Kolumna 5",
      width: 120
    },
    {
      title: "Kolumna 6",
      width: 120
    },
    {
      title: "Kolumna 7",
      width: 120
    },
    {
      title: "Kolumna 8",
      width: 120
    },
    {
      title: "Kolumna 9",
      width: 120
    },
    {
      title: "Kolumna 10",
      width: 120
    },
    {
      title: "Kolumna 11",
      width: 120
    },
    {
      title: "Kolumna 12",
      width: 120
    },
    {
      title: "Kolumna 13",
      width: 120
    },
    {
      title: "Kolumna 14",
      width: 120
    },
    {
      title: "Kolumna 15",
      width: 120
    },
    {
      title: "Action",
      key: "action",
      render: (
        _,
        record // record = user data, have access to obj attributes
      ) =>
        <Space size="middle">
          <Button type="link">Edit</Button>
          <Button type="link">Delete</Button>
        </Space>,
      fixed: "right",
      width: 200
    }
  ];

  const getUsersData = async (pageNumber = 1, pageSize = 10) => {
    console.log("Fetching users data");
    setLoading(true);
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const usersResponse = await api.users.getUsers(
      tokens.access,
      pageNumber,
      pageSize
    );
    const usersData = usersResponse.data;
    console.log("users data:", usersData);
    const pag = usersData.pagination;
    const res = usersData.results.map(result => ({
      ...result,
      key: result.id
    }));
    console.log("pagination:", pag);
    console.log("result:", res);

    setPagination(pag);
    setUsers(res);
    setLoading(false);
  };

  const setUrlParam = (key, value) => {
    urlParams.set(key, value);
    // ugly AF but it wotks somehow - change it l8tr
    let newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      urlParams.toString();
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  useEffect(
    () => {
      console.log("Users view mounted...");
      // TODO: here we need to check na pass url param if exists
      // ^^ checing all params sound wierd (switch -> case?)
      // iterowanie się i wstawianie do urla - moze byc puste
      if (urlParams.has("page")) {
        setPageNumber(urlParams.get("page"));
      }

      if (urlParams.has("size")) {
        setPageSize(urlParams.get("size"));
      }

      getUsersData(pageNumber, pageSize);
    },
    [user, setUser, pageNumber, pageSize, urlParams]
  );

  const [activeTabKey, setActiveTabKey] = useState("filters");
  const contentList = {
    filters: <span>
      <Typography.Title level={5}>Filter field label</Typography.Title>
      <Input placeholder="Some filter" />
      <br />
      <br />
      <Input addonBefore="Filter label" placeholder="value to search" />
    </span>,
    actions: <Button type="primary" icon={<PlusOutlined />}>Add user</Button>
  };
  return (
    <div>
      <h1>Users</h1>

      <Divider />

      <Card
        style={{ marginBottom: 10 }}
        type="inner"
        tabList={[
          {
            key: "filters",
            label: "Filters",
          },
          {
            key: "actions",
            label: "Actions",
          },
        ]}
        activeTabKey={activeTabKey}
        onTabChange={(tabKey) => {
          console.log("tab changed. key is:", tabKey);
          setActiveTabKey(tabKey);
        }}
        tabProps={{
          size: "middle",
        }}
      >
        {contentList[activeTabKey]}
      </Card>

      <div>
        {selectedRowKeys.length > 0
          ? `Selected ${selectedRowKeys.length} items`
          : ""}
      </div>
      <div>
        {selectedRowKeys.length > 0
          ? `Selected items keys: ${selectedRowKeys}`
          : ""}
      </div>
      {/* TODO: need to add filters and sorters */}
      <Table
        size="large"
        columns={columns}
        dataSource={!users ? [] : users}
        pagination={{
          hideOnSinglePage: true,
          position: ["topRight", "bottomRight"],
          current: pagination.current,
          defaultCurrent: 1,
          defaultPageSize: 10,
          pageSize: pageSize,
          total: pagination.count,
          showTotal: total => `Total ${total} items`,
          showSizeChanger: true,
          onShowSizeChange: (_, size) => {
            // Called when pageSize is changed
            setUrlParam("page", 1);
            setUrlParam("size", size);
            setPageNumber(1);
            setPageSize(size);
            getUsersData(1, size);
          },
          onChange: (newPageNumber, newPageSize) => {
            // Called when the page number or pageSize is changed, and it takes the resulting page number and pageSize as its arguments
            setUrlParam("page", newPageNumber);
            setUrlParam("size", newPageSize);
            getUsersData(newPageNumber, newPageSize);
          },
          simple: false // Change to true, to use simple pagination
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: newSelectedRowKeys => {
            setSelectedRowKeys(
              Array.from(new Set(selectedRowKeys.concat(newSelectedRowKeys)))
            );
          }
        }}
        loading={loading}
        bordered={true}
        scroll={{
          y: 400,
          x: 1000
        }}
      />
    </div>
  );
}
