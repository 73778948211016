import React, { useEffect } from "react";
import translate from "../../translate";

import { Button, Card, Input as Imput, Form, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import useQueryParams from "../../hooks/useQueryParams.hook";

import { TRANSLATIONS } from "../../translate/translations";

export default function AgentTeamsFilters({ user, options }) {
  const { setQueryParams } = useQueryParams();

  useEffect(() => {
    console.log("AgentTeamsFilters mounted ...");
  }, []);

  return (
    <Card
      style={{ marginBottom: 10, marginTop: 15 }}
      type="inner"
      title={
        <h3 id={TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_TILE.key}>
          {translate(TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_TILE.key)}
        </h3>
      }
    >
      <Form
        id="agent-teams-filter-form"
        name="agent-teams-filter-form"
        layout="inline"
        autoComplete="off"
        onFinish={() => {
          setQueryParams(`/broker-teams`, {
            page: options.pageNumber,
            size: options.pageSize,
            order_by: options.orderBy,
            name_contains: options.filters.name_contains,
            agent_last_name_contains: options.filters.agent_last_name_contains,
          });
        }}
      >
        <Form.Item initialValue={options.filters.name_contains}>
          <Form.Item hidden={true}>
            <input value={options.filters.name_contains} readOnly={true}/>
          </Form.Item>

          <Form.Item>
            <Imput
              id={TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_NAME_LABEL.key}
              name="name_contains"
              addonBefore={translate(TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_NAME_LABEL.key)}
              value={options.filters.name_contains}
              onChange={e =>
                options.setFilters(oldFilters => {
                  const newFilters = Object.assign({}, oldFilters);
                  newFilters.name_contains = e.target.value;
                  return newFilters;
                })}
              size="large"
            />
          </Form.Item>
        </Form.Item>

        <Form.Item initialValue={options.filters.agent_last_name_contains}>
          <Form.Item hidden={true}>
            <input value={options.filters.agent_last_name_contains} readOnly={true}/>
          </Form.Item>

          <Form.Item>
            <Imput
              id={TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_LASTNAME_LABEL.key}
              name="lastname_contains"
              addonBefore={translate(TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_LASTNAME_LABEL.key)}
              value={options.filters.agent_last_name_contains}
              onChange={e =>
                options.setFilters(oldFilters => {
                  const newFilters = Object.assign({}, oldFilters);
                  newFilters.agent_last_name_contains = e.target.value;
                  return newFilters;
                })}
              size="large"
            />
          </Form.Item>
        </Form.Item>

        <Form.Item name="search">
          <Tooltip
            title={translate(TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL.key)}
            destroyTooltipOnHide
            mouseEnterDelay={0.5}
          >
            <Button
              id={TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL.key}
              htmlType="submit"
              type="button"
              shape="circle"
              icon={<SearchOutlined />}
              size="large"
            />
          </Tooltip>
        </Form.Item>
      </Form>
    </Card>
  );
}
