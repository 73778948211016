import { useEffect } from "react";

import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";

import { Table, Space, Tooltip } from "antd";

import CustomerDetailsButton from "./action/CustomerDetailsButton";
import CustomerDeleteModal from "./action/CustomerDeleteModal";

export default function CustomersTable({
  user,
  tableStateChanged,
  options,
  loading,
}) {
  const pageSizeOptions = [10, 15, 25, 50, 100, 500];
  const sortTooltipDescription = {
    asc: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL.key)}`,
    desc: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL.key)}`,
    cancel: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL.key)}`,
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 50,
      hidden: true,
    },
    {
      title: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_NAME_LABEL.key)}`,
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      fixed: "left",
      width: 350,
      render: (name) => {
        if (name.length > 60) {
          return (
            <Tooltip
              placement="topLeft"
              title={name}
              destroyTooltipOnHide={true}
              overlayStyle={{ maxWidth: "525px" }}
              mouseEnterDelay={0.5}
            >
              {name}
            </Tooltip>
          );
        } else {
          return <span>{name}</span>;
        }
      },
    },
    {
      title: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_GROUP_LABEL.key)}`,
      dataIndex: "customer_group",
      key: "customer_group",
      ellipsis: {
        showTitle: false,
      },
      width: 350,
      render: (customer_group) => {
        if (customer_group?.name.length > 60) {
          return (
            <Tooltip
              placement="topLeft"
              title={customer_group.name}
              destroyTooltipOnHide={true}
              overlayStyle={{ maxWidth: "525px" }}
              mouseEnterDelay={0.5}
            >
              {customer_group.name}
            </Tooltip>
          );
        } else {
          return <span>{customer_group?.name}</span>;
        }
      },
    },
    {
      title: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_REGON_LABEL.key)}`,
      dataIndex: "regon",
      key: "regon",
      width: 150,
    },
    {
      title: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_KEEPER_AGENCY_SIDE_LABEL.key)}`,
      dataIndex: "keeper_agency_side",
      key: "keeper_agency_side",
      width: 200,
      render: (keeper_agency_side) => {
        if (!keeper_agency_side) {
          return <span></span>;
        }
        return (
          <span>
            <span>{keeper_agency_side.first_name} {keeper_agency_side.last_name}</span>
            <br />
            <span>{keeper_agency_side.email}</span>
            <br />
            <span>{keeper_agency_side.phone}</span>
          </span>
        );
      },
    },
    {
      title: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_KEEPER_BROKER_SIDE_LABEL.key)}`,
      dataIndex: "keeper_broker_side",
      key: "keeper_broker_side",
      width: 200,
      render: (keeper_broker_side) => {
        if (!keeper_broker_side) {
          return <span></span>;
        }
        const finalName =
          keeper_broker_side.company_name ??
          `${keeper_broker_side.first_name} ${keeper_broker_side.last_name}`;
        return (
          <span>
            <span>{finalName}</span>
            <br />
            <span>{keeper_broker_side.email}</span>
            <br />
            <span>{keeper_broker_side.phone}</span>
          </span>
        );
      },
    },
    {
      title: `${translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__HEADERS_ACTIONS_LABEL.key)}`,
      key: "actions",
      render: (_, record) => (
        // record = user data, have access to obj attributes
        <Space size="middle">
          {/* <Button
            id={TRANSLATIONS.CUSTOMER_LIST_TABLE__ACTION_EDIT_BUTTON_LABEL.key}
            type="link"
          >
            {translate(TRANSLATIONS.CUSTOMER_LIST_TABLE__ACTION_EDIT_BUTTON_LABEL.key)}
          </Button> */}
          <CustomerDetailsButton record={record} />
          <CustomerDeleteModal record={record} />
        </Space>
      ),
      fixed: "right",
      width: 200,
    },
  ];

  useEffect(() => {
    console.log("CustomersTable mounted ...");
    console.log("options items", options.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      id="customers"
      size="large"
      virtual
      columns={columns}
      dataSource={options.items ?? []}
      pagination={{
        position: ["topRight", "bottomRight"],
        current: options.pagination.current,
        defaultCurrent: 1,
        defaultPageSize: options.pageSize,
        pageSize: options.pageSize,
        total: options.pagination.count,
        pageSizeOptions: pageSizeOptions,
        showTotal: (total) => `Total ${total} items`,
        showSizeChanger: true,
        // onChange: (newPageNumber, newPageSize) => {
        //   tableStateChanged(newPageNumber, newPageSize);
        // },
      }}
      loading={loading}
      showSorterTooltip={true}
      locale={{
        triggerAsc: sortTooltipDescription.asc,
        triggerDesc: sortTooltipDescription.desc,
        cancelSort: sortTooltipDescription.cancel,
      }}
      rowKey="id"
      bordered
      scroll={{
        y: 1000,
        x: 1300,
      }}
      onChange={(pagination, filters, sorter) => {
        // TODO: nałożenie aktualnego starnu sortowania z urla
        // ... kolumna ma atrubyt sortOrder, zapewne trzeba bedzie go ustawić po wejściu na urla
        // ... wartości przyjmowane przez sortOrder - "ascend", "descend", null
        const sortedOrder = sorter.order ?? "";
        const sortedField = sorter.columnKey ?? "";
        const orderBy =
          sortedOrder === "ascend"
            ? `-${sortedField}`
            : sortedOrder === "descend"
              ? `${sortedField}`
              : "-id";
        tableStateChanged(pagination.current, pagination.pageSize, orderBy);
      }}
    />
  );
}
