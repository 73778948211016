import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button, Typography, Image } from "antd";
import { MailTwoTone, LockTwoTone } from "@ant-design/icons";

import api from "../infra/api";

export default function LoginPage({ setUser }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const { Title } = Typography;

  const submit = async (email, password) => {
    setLoading(true);
    setErrors([]);
    try {
      const token = await api.auth.getToken(email, password);
      const access = token.data.access;
      const refresh = token.data.refresh;
      console.log("ACCESSTOKEN:", access);
      console.log("REFRESHTOKEN:", refresh);
      const tokens = JSON.stringify({
        access,
        refresh,
      });
      console.log("LoginScreen:save tokens", tokens);
      localStorage.setItem("tokens", tokens);

      const userResponse = await api.users.getMe(access);
      const user = userResponse.data;
      console.log("user data:", user);
      localStorage.setItem("user", JSON.stringify({ user }));
      setUser(user);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      if (error.response) {
        console.error("axios err!", error);
        console.info("api error!", error.response.data.detail);
        setErrors([error.response.data.detail]);
      } else {
        console.error("LoginPage:submit other error =>", error);
      }
    }
  };

  return (
    <section
      style={{
        alignItems: "start",
        display: "flex",
        height: "100vh",
        padding: "0px",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/background-image.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          margin: "10% auto",
          width: "380px",
          borderRadius: "6px",
          padding: "32px",
          backgroundColor: "rgba(235, 235, 235, 0.5)",
          backdropFilter: "blur(3px)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{
              width: "56px",
              height: "56px",
              marginInlineEnd: "16px",
              verticalAlign: "top",
            }}
            src="/logo192.png"
            preview={false}
          />
          <Title
            style={{
              fontWeight: "bold",
              fontSize: "33px",
              position: "relative",
              textAlign: "center",
              verticalAlign: "middle",
              marginBottom: "0.75em",
            }}
          >
            Fleet Web
          </Title>
        </div>

        <Form
          name="login-form"
          onFinish={(formFieldsValues) => {
            console.log("form submitted");
            console.log("fields values:", formFieldsValues);
            submit(formFieldsValues.email, formFieldsValues.password);
          }}
          autoComplete="off"
          autofocusfirstinput="true"
          size="large"
        >
          <div
            style={{
              color: "crimson",
              textAlign: "center",
              margin: 17,
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            {errors.length > 0 &&
              errors.map((err, i) => (
                <span id={i} key={i}>
                  {err}
                </span>
              ))}
          </div>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Field is required!",
              },
            ]}
            normalize={(value) => value.trim()}
          >
            <Input
              autoFocus
              type="email"
              prefix={<MailTwoTone />}
              placeholder="E-mail"
              allowClear="true"
              disabled={+loading}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Field is required!",
              },
            ]}
            normalize={(value) => value.trim()}
          >
            <Input.Password
              prefix={<LockTwoTone />}
              placeholder="Password"
              allowClear="true"
              disabled={+loading}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={+loading}
              disabled={+loading}
            >
              {loading ? "Logging in" : "Login"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
