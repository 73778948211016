export const TRANSLATIONS = {
  COMMONS__EMPTY_LIST_TITLE: {
    key: "COMMONS__EMPTY_LIST_TITLE",
    val: {
      EN: "No data",
      PL: "Brak danych",
    },
  },
  CG_LIST__TITLE: {
    key: "CG_LIST__TITLE",
    val: {
      EN: "Customer Groups",
      PL: "Grupy Klientów",
    },
  },
  CG_LIST__FILTERS_TILE: {
    key: "CG_LIST__FILTERS_TILE",
    val: {
      EN: "Filters",
      PL: "Filtry",
    },
  },
  CG_LIST__FILTERS_NAME_LABEL: {
    key: "CG_LIST__FILTERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  CG_LIST__FILTERS_REGON_LABEL: {
    key: "CG_LIST__FILTERS_REGON_LABEL",
    val: {
      EN: "REGON",
      PL: "REGON",
    },
  },
  CG_LIST__FILTERS_SEARCH_BUTTON_LABEL: {
    key: "CG_LIST__FILTERS_SEARCH_BUTTON_LABEL",
    val: {
      EN: "Search",
      PL: "Szukaj",
    },
  },
  CG_LIST__ADD_NEW_GROUP_BUTTON_LABEL: {
    key: "CG_LIST__ADD_NEW_GROUP_BUTTON_LABEL",
    val: {
      EN: "Add new group",
      PL: "Dodaj nową grupę",
    },
  },
  CG_LIST__FILTER_ALL_TAB_LABEL: {
    key: "CG_LIST__FILTER_ALL_TAB_LABEL",
    val: {
      EN: "All",
      PL: "Wszystkie",
    },
  },
  CG_LIST__FILTER_MINE_TAB_LABEL: {
    key: "CG_LIST__FILTER_MINE_TAB_LABEL",
    val: {
      EN: "Mine",
      PL: "Moje",
    },
  },
  CG_LIST__FILTER_PARTICIPANT_TAB_LABEL: {
    key: "CG_LIST__FILTER_PARTICIPANT_TAB_LABEL",
    val: {
      EN: "Where am I a participant",
      PL: "Gdzie jestem uczestnikiem",
    },
  },
  CG_LIST_TABLE__HEADERS_NAME_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  CG_LIST_TABLE__HEADERS_CUSTOMER_COUNT_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_CUSTOMER_COUNT_LABEL",
    val: {
      EN: "Customer count",
      PL: "Licza klientów",
    },
  },
  CG_LIST_TABLE__HEADERS_POLICES_COUNT_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_POLICES_COUNT_LABEL",
    val: {
      EN: "Active and planned polices count",
      PL: "Liczba aktywnych i zaplanowanych polis",
    },
  },
  CG_LIST_TABLE__HEADERS_TOTAL_NOTE_CONTRIBUTON_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_TOTAL_NOTE_CONTRIBUTON_LABEL",
    val: {
      EN: "Total Note Contributon",
      PL: "Przypis składki",
    },
  },
  CG_LIST_TABLE__HEADERS_KEEPER_AGENCY_SIDE_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_KEEPER_AGENCY_SIDE_LABEL",
    val: {
      EN: "Keeper Agency side",
      PL: "Opiekun po stronie agencji",
    },
  },
  CG_LIST_TABLE__HEADERS_KEEPER_BROKER_SIDE_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_KEEPER_BROKER_SIDE_LABEL",
    val: {
      EN: "Keeper Broker side",
      PL: "Opiekun po stronie brokerskiej",
    },
  },
  CG_LIST_TABLE__HEADERS_ACTIONS_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_ACTIONS_LABEL",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  CG_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL",
    val: {
      EN: "Sort ascending",
      PL: "Sortuj rosnąco",
    },
  },
  CG_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL",
    val: {
      EN: "Sort descensing",
      PL: "Sortuj malejąco",
    },
  },
  CG_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL: {
    key: "CG_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL",
    val: {
      EN: "Cancel sort",
      PL: "Usuń sortowanie",
    },
  },
  CG_LIST_TABLE__ACTION_EDIT_BUTTON_LABEL: {
    key: "CG_LIST_TABLE__ACTION_EDIT_BUTTON_LABEL",
    val: {
      EN: "Edit",
      PL: "Edycja",
    },
  },
  CG_LIST_TABLE__ACTION_DELETE_BUTTON_LABEL: {
    key: "CG_LIST_TABLE__ACTION_DELETE_BUTTON_LABEL",
    val: {
      EN: "Delete",
      PL: "Usuń",
    },
  },
  CG_CREATE__TITLE: {
    key: "CG_CREATE__TITLE",
    val: {
      EN: "Customer Group data",
      PL: "Dane Grupy Klientów",
    },
  },
  CG_CREATE__FORM_NAME_LABEL: {
    key: "CG_CREATE__FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  CG_CREATE__FORM_KEEPER_AGENCY_SIDE_LABEL: {
    key: "CG_CREATE__FORM_KEEPER_AGENCY_SIDE_LABEL",
    val: {
      EN: "Keeper Agency side",
      PL: "Opiekun po stronie agencji",
    },
  },
  CG_CREATE__FORM_KEEPER_BROKER_SIDE_LABEL: {
    key: "CG_CREATE__FORM_KEEPER_BROKER_SIDE_LABEL",
    val: {
      EN: "Keeper Broker side",
      PL: "Opiekun po stronie brokerskiej",
    },
  },
  CG_CREATE__FORM_CREATE_BUTTON_LABEL: {
    key: "CG_CREATE__FORM_CREATE_BUTTON_LABEL",
    val: {
      EN: "Create",
      PL: "Utwórz",
    },
  },
  CG_DELETE__MODAL_TITLE: {
    key: "CG_DELETE__MODAL_TITLE",
    val: {
      EN: "Are you sure you want to delete customer group",
      PL: "Czy napewno chcesz usunąć grupę klientów",
    },
  },
  CG_DELETE__MODAL_SUBTITLE: {
    key: "CG_DELETE__MODAL_SUBTITLE",
    val: {
      EN: `Operation will only delete the customer group.\nCustomers associated with the group will remain unchanged.`,
      PL: `Operacja ta spowoduje usunięcie tylko grupy.\nKlienci powiązani z grupą pozostaną bez zmian.`,
    },
  },
  CG_DELETE__MODAL_CONFIRM_BUTTON_LABEL: {
    key: "CG_DELETE__MODAL_CONFIRM_BUTTON_LABEL",
    val: {
      EN: "Confirm",
      PL: "Zatwierdź",
    },
  },
  CG_DELETE__MODAL_DELETION_BUTTON_LABEL: {
    key: "CG_DELETE__MODAL_DELETION_BUTTON_LABEL",
    val: {
      EN: "Deletion",
      PL: "Usuwanie",
    },
  },
  CG_DELETE__MODAL_CANCEL_BUTTON_LABEL: {
    key: "CG_DELETE__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },
  CUSTOMER_LIST__TITLE: {
    key: "CUSTOMER_LIST__TITLE",
    val: {
      EN: "Customer Cards",
      PL: "Karty Klientów",
    },
  },
  CUSTOMER_LIST__FILTERS_TILE: {
    key: "CUSTOMER_LIST__FILTERS_TILE",
    val: {
      EN: "Filters",
      PL: "Filtry",
    },
  },
  CUSTOMER_LIST__FILTERS_NAME_LABEL: {
    key: "CUSTOMER_LIST__FILTERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  CUSTOMER_LIST__FILTERS_REGON_LABEL: {
    key: "CUSTOMER_LIST__FILTERS_REGON_LABEL",
    val: {
      EN: "REGON",
      PL: "REGON",
    },
  },
  CUSTOMER_LIST__FILTERS_SEARCH_BUTTON_LABEL: {
    key: "CUSTOMER_LIST__FILTERS_SEARCH_BUTTON_LABEL",
    val: {
      EN: "Search",
      PL: "Szukaj",
    },
  },
  CUSTOMER_LIST__ADD_NEW_CUSTOMER_BUTTON_LABEL: {
    key: "CUSTOMER_LIST__ADD_NEW_CUSTOMER_BUTTON_LABEL",
    val: {
      EN: "Add new customer",
      PL: "Dodaj nowego klienta",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_NAME_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_GROUP_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_GROUP_LABEL",
    val: {
      EN: "Group",
      PL: "Grupa",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_REGON_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_REGON_LABEL",
    val: {
      EN: "REGON",
      PL: "REGON",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_KEEPER_AGENCY_SIDE_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_KEEPER_AGENCY_SIDE_LABEL",
    val: {
      EN: "Keeper Agency side",
      PL: "Opiekun po stronie agencji",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_KEEPER_BROKER_SIDE_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_KEEPER_BROKER_SIDE_LABEL",
    val: {
      EN: "Keeper Broker side",
      PL: "Opiekun po stronie brokerskiej",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_ACTIONS_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_ACTIONS_LABEL",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL",
    val: {
      EN: "Sort ascending",
      PL: "Sortuj rosnąco",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL",
    val: {
      EN: "Sort descensing",
      PL: "Sortuj malejąco",
    },
  },
  CUSTOMER_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL: {
    key: "CUSTOMER_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL",
    val: {
      EN: "Cancel sort",
      PL: "Usuń sortowanie",
    },
  },
  CUSTOMER_LIST_TABLE__ACTION_DETAILS_BUTTON_LABEL: {
    key: "CUSTOMER_LIST_TABLE__ACTION_DETAILS_BUTTON_LABEL",
    val: {
      EN: "Details",
      PL: "Szczegóły",
    },
  },
  CUSTOMER_LIST_TABLE__ACTION_EDIT_BUTTON_LABEL: {
    key: "CUSTOMER_LIST_TABLE__ACTION_EDIT_BUTTON_LABEL",
    val: {
      EN: "Edit",
      PL: "Edycja",
    },
  },
  CUSTOMER_LIST_TABLE__ACTION_DELETE_BUTTON_LABEL: {
    key: "CUSTOMER_LIST_TABLE__ACTION_DELETE_BUTTON_LABEL",
    val: {
      EN: "Delete",
      PL: "Usuń",
    },
  },
  CUSTOMER_CREATE__TITLE: {
    key: "CUSTOMER_CREATE__TITLE",
    val: {
      EN: "New customer addition",
      PL: "Dodawanie nowego klienta",
    },
  },
  CUSTOMER__CHECK_REGON_LABEL: {
    key: "CUSTOMER__CHECK_REGON_LABEL",
    val: {
      EN: "Check",
      PL: "Sprawdź",
    },
  },
  CUSTOMER_CREATE__FORM_CUSTOMER_DATA_LABEL: {
    key: "CUSTOMER_CREATE__FORM_CUSTOMER_DATA_LABEL",
    val: {
      EN: "Customer data",
      PL: "Dane klienta",
    },
  },
  CUSTOMER_CREATE__FORM_CUSTOMER_CONTACT_PERSONS_LABEL: {
    key: "CUSTOMER_CREATE__FORM_CUSTOMER_CONTACT_PERSONS_LABEL",
    val: {
      EN: "Customer contact data",
      PL: "Dane kontaktowe po stronie klienta",
    },
  },
  CUSTOMER_ROLE__LABEL: {
    key: "CUSTOMER_ROLE__LABEL",
    val: {
      EN: "Customer role on policy",
      PL: "Rola Klienta na polisach",
    },
  },
  CUSTOMER_ROLE__LEASING_LABEL: {
    key: "CUSTOMER_ROLE__LEASING_LABEL",
    val: {
      EN: "Leasing",
      PL: "Leasing",
    },
  },
  CUSTOMER_ROLE__BANK_LABEL: {
    key: "CUSTOMER_ROLE__BANK_LABEL",
    val: {
      EN: "Bank",
      PL: "Bank",
    },
  },
  CUSTOMER_ROLE__INSURANCE_PERSON_LABEL: {
    key: "CUSTOMER_ROLE__INSURANCE_PERSON_LABEL",
    val: {
      EN: "Insurance Person",
      PL: "Ubezpieczający",
    },
  },
  CUSTOMER_ROLE__OWNER_LABEL: {
    key: "CUSTOMER_ROLE__OWNER_LABEL",
    val: {
      EN: "Owner",
      PL: "Właściciel",
    },
  },
  CUSTOMER_ROLE__COOWNER_LABEL: {
    key: "CUSTOMER_ROLE__COOWNER_LABEL",
    val: {
      EN: "Co-Owner",
      PL: "Współwłaściciel",
    },
  },
  CUSTOMER_ROLE__USER_LABEL: {
    key: "CUSTOMER_ROLE__USER_LABEL",
    val: {
      EN: "User",
      PL: "Użytkownik",
    },
  },
  CUSTOMER_ROLE__INSURED_IN_NNW_MAX_LABEL: {
    key: "CUSTOMER_ROLE__INSURED_IN_NNW_MAX_LABEL",
    val: {
      EN: "Insured in NNW Max",
      PL: "Ubezpieczony w NNW Max",
    },
  },
  CUSTOMER_TYPE__LABEL: {
    key: "CUSTOMER_TYPE__LABEL",
    val: {
      EN: "Customer type",
      PL: "Typ Klienta",
    },
  },
  CUSTOMER_TYPE__PHISICAL_PERSON_WITHOUT_ECONOMIC_ACTIVITY_LABEL: {
    key: "CUSTOMER_TYPE__PHISICAL_PERSON_WITHOUT_ECONOMIC_ACTIVITY_LABEL",
    val: {
      EN: "Phisical Person without economic activity",
      PL: "Osoba fizyczna",
    },
  },
  CUSTOMER_TYPE__PHISICAL_PERSON_WITH_ECONOMIC_ACTIVITY_LABEL: {
    key: "CUSTOMER_TYPE__PHISICAL_PERSON_WITH_ECONOMIC_ACTIVITY_LABEL",
    val: {
      EN: "Phisical Person with economic activity",
      PL: "Osoba fizyczna prowadząca działalność gospodarczą",
    },
  },
  CUSTOMER_TYPE__LEGAL_PERSON_LABEL: {
    key: "CUSTOMER_TYPE__LEGAL_PERSON_LABEL",
    val: {
      EN: "Legal Person",
      PL: "Osoba prawna",
    },
  },
  CUSTOMER_TYPE__PARTNERSHIP_LABEL: {
    key: "CUSTOMER_TYPE__PARTNERSHIP_LABEL",
    val: {
      EN: "Partnership",
      PL: "Spółka Cywilna",
    },
  },
  CUSTOMER_NAME__LABEL: {
    key: "CUSTOMER_NAME__LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  CUSTOMER_REGON__LABEL: {
    key: "CUSTOMER_REGON__LABEL",
    val: {
      EN: "REGON",
      PL: "REGON",
    },
  },
  CUSTOMER_NIP__LABEL: {
    key: "CUSTOMER_NIP__LABEL",
    val: {
      EN: "TIN",
      PL: "NIP",
    },
  },
  CUSTOMER_PESEL__LABEL: {
    key: "CUSTOMER_PESEL__LABEL",
    val: {
      EN: "PESEL",
      PL: "PESEL",
    },
  },
  CUSTOMER_FIRST_NAME__LABEL: {
    key: "CUSTOMER_FIRST_NAME__LABEL",
    val: {
      EN: "First name",
      PL: "Imię",
    },
  },
  CUSTOMER_LAST_NAME__LABEL: {
    key: "CUSTOMER_LAST_NAME__LABEL",
    val: {
      EN: "Last name",
      PL: "Nazwisko",
    },
  },
  CUSTOMER_ADDRESS__LABEL: {
    key: "CUSTOMER_ADDRESS__LABEL",
    val: {
      EN: "Address (street name, building number, flat number)",
      PL: "Adres (nazwa ulicy, numer budynku, numer lokalu)",
    },
  },
  CUSTOMER_POSTAL_CODE__LABEL: {
    key: "CUSTOMER_POSTAL_CODE__LABEL",
    val: {
      EN: "Postal code",
      PL: "Kod pocztowy",
    },
  },
  CUSTOMER_CITY__LABEL: {
    key: "CUSTOMER_CITY__LABEL",
    val: {
      EN: "City",
      PL: "Miasto",
    },
  },
  CUSTOMER_PHONE_1__LABEL: {
    key: "CUSTOMER_PHONE_1__LABEL",
    val: {
      EN: "Contact phone 1",
      PL: "Telefon kontaktowy 1",
    },
  },
  CUSTOMER_PHONE_2__LABEL: {
    key: "CUSTOMER_PHONE_2__LABEL",
    val: {
      EN: "Contact phone 2",
      PL: "Telefon kontaktowy 2",
    },
  },
  CUSTOMER_EMAIL__LABEL: {
    key: "CUSTOMER_EMAIL__LABEL",
    val: {
      EN: "Contact e-mail address",
      PL: "Kontaktowy adres e-mail",
    },
  },
  CUSTOMER_CREATE__FORM_BROKER_TEAM_LABEL: {
    key: "CUSTOMER_CREATE__FORM_BROKER_TEAM_LABEL",
    val: {
      EN: "Broker service team",
      PL: "Zespół brokerski obsługowy",
    },
  },
  CUSTOMER_CREATE__FORM_BROKER_TEAM_NAME_LABEL: {
    key: "CUSTOMER_CREATE__FORM_BROKER_TEAM_NAME_LABEL",
    val: {
      EN: "Team",
      PL: "Zespół",
    },
  },
  CUSTOMER_CREATE__FORM_BROKER_TEAM_KEEPER_BROKER_SIDE_LABEL: {
    key: "CUSTOMER_CREATE__FORM_BROKER_TEAM_KEEPER_BROKER_SIDE_LABEL",
    val: {
      EN: "Keeper",
      PL: "Opiekun",
    },
  },
  CUSTOMER_CREATE__FORM_BROKER_TEAM_CORRESPONDENT_BROKER_SIDE_LABEL: {
    key: "CUSTOMER_CREATE__FORM_BROKER_TEAM_CORRESPONDENT_BROKER_SIDE_LABEL",
    val: {
      EN: "Correspondent",
      PL: "Korespondent",
    },
  },
  CUSTOMER_CREATE__FORM_AGENT_TEAM_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGENT_TEAM_LABEL",
    val: {
      EN: "Agancy service team",
      PL: "Zespół agencyjny obsługowy",
    },
  },
  CUSTOMER_CREATE__FORM_AGENT_TEAM_NAME_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGENT_TEAM_NAME_LABEL",
    val: {
      EN: "Team",
      PL: "Zespół",
    },
  },
  CUSTOMER_CREATE__FORM_AGENT_TEAM_KEEPER_AGENT_SIDE_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGENT_TEAM_KEEPER_AGENT_SIDE_LABEL",
    val: {
      EN: "Keeper",
      PL: "Opiekun",
    },
  },
  CUSTOMER_CREATE__FORM_AGREEMENTS_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGREEMENTS_LABEL",
    val: {
      EN: "RODO and marketing agreements",
      PL: "Zgody RODO i marketingowe",
    },
  },
  CUSTOMER_CREATE__FORM_AGREEMENT_ALL_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGREEMENT_ALL_LABEL",
    val: {
      EN: "Check all agreements",
      PL: "Zaznacz wszystkie zgody",
    },
  },
  CUSTOMER_CREATE__FORM_AGREEMENT1_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGREEMENT1_LABEL",
    val: {
      EN: "Agreement to process personal data (RODO)",
      PL: "Zgoda na przetwarzanie danych osobowych (RODO)",
    },
  },
  CUSTOMER_CREATE__FORM_AGREEMENT2_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGREEMENT2_LABEL",
    val: {
      EN: "Agreement to marketing by phone",
      PL: "Zgoda na marketing telefoniczny",
    },
  },
  CUSTOMER_CREATE__FORM_AGREEMENT3_LABEL: {
    key: "CUSTOMER_CREATE__FORM_AGREEMENT3_LABEL",
    val: {
      EN: "Agreement to marketing by e-mail",
      PL: "Zgoda na marketing mailowy",
    },
  },
  CUSTOMER_CREATE__FORM_CUSTOMER_GROUP_LABEL: {
    key: "CUSTOMER_CREATE__FORM_CUSTOMER_GROUP_LABEL",
    val: {
      EN: "Customer Group",
      PL: "Grupa Klienta",
    },
  },
  CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL: {
    key: "CUSTOMER_CREATE__FORM_PREVIOUS_BUTTON_LABEL",
    val: {
      EN: "Previous",
      PL: "Wróć",
    },
  },
  CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL: {
    key: "CUSTOMER_CREATE__FORM_NEXT_BUTTON_LABEL",
    val: {
      EN: "Next",
      PL: "Dalej",
    },
  },
  CUSTOMER_CREATE__FORM_CREATE_BUTTON_LABEL: {
    key: "CUSTOMER_CREATE__FORM_CREATE_BUTTON_LABEL",
    val: {
      EN: "Create",
      PL: "Utwórz",
    },
  },
  CUSTOMER_DELETE__MODAL_TITLE: {
    key: "CUSTOMER_DELETE__MODAL_TITLE",
    val: {
      EN: "Are you sure you want to delete customer",
      PL: "Czy napewno chcesz usunąć klienta",
    },
  },
  CUSTOMER_DELETE__MODAL_CONFIRM_BUTTON_LABEL: {
    key: "CUSTOMER_DELETE__MODAL_CONFIRM_BUTTON_LABEL",
    val: {
      EN: "Confirm",
      PL: "Zatwierdź",
    },
  },
  CUSTOMER_DELETE__MODAL_DELETION_BUTTON_LABEL: {
    key: "CUSTOMER_DELETE__MODAL_DELETION_BUTTON_LABEL",
    val: {
      EN: "Deletion",
      PL: "Usuwanie",
    },
  },
  CUSTOMER_DELETE__MODAL_CANCEL_BUTTON_LABEL: {
    key: "CUSTOMER_DELETE__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },
  COBROKER_CREATE__OPEN_MODAL_BUTTON_LABEL: {
    key: "COBROKER_CREATE__OPEN_MODAL_BUTTON_LABEL",
    val: {
      EN: "Add CO-Broker",
      PL: "Dodaj CO-Brokera",
    },
  },
  COBROKER_CREATE__TOOLTIP_LABEL: {
    key: "COBROKER_CREATE__TOOLTIP_LABEL",
    val: {
      EN: "Use when no person is found on the list",
      PL: "Używać w przypadku, gdy nie znaleziono osoby na liście",
    },
  },
  COBROKER_CREATE__MODAL_TITLE: {
    key: "COBROKER_CREATE__MODAL_TITLE",
    val: {
      EN: "CO-Broker addition",
      PL: "Dodawanie CO-Brokera",
    },
  },
  COBROKER_CREATE__MODAL_FORM_NAME_LABEL: {
    key: "COBROKER_CREATE__MODAL_FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  COBROKER_CREATE__MODAL_FORM_EMAIL_LABEL: {
    key: "COBROKER_CREATE__MODAL_FORM_EMAIL_LABEL",
    val: {
      EN: "E-mail",
      PL: "E-mail",
    },
  },
  COBROKER_CREATE__MODAL_FORM_PHONE_LABEL: {
    key: "COBROKER_CREATE__MODAL_FORM_PHONE_LABEL",
    val: {
      EN: "Phone number",
      PL: "Numer kontaktowy",
    },
  },
  COBROKER_CREATE__MODAL_CONFIRM_BUTTON_LABEL: {
    key: "COBROKER_CREATE__MODAL_CONFIRM_BUTTON_LABEL",
    val: {
      EN: "Confirm",
      PL: "Zatwierdź",
    },
  },
  COBROKER_CREATE__MODAL_CREATION_BUTTON_LABEL: {
    key: "COBROKER_CREATE__MODAL_CREATION_BUTTON_LABEL",
    val: {
      EN: "Creating",
      PL: "Dodawanie",
    },
  },
  COBROKER_CREATE__MODAL_CANCEL_BUTTON_LABEL: {
    key: "COBROKER_CREATE__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },
  BROKER_LIST__TITLE: {
    key: "BROKER_LIST__TITLE",
    val: {
      EN: "Brokers",
      PL: "Brokerzy",
    },
  },
  BROKER_LIST__FILTERS_TILE: {
    key: "BROKER_LIST__FILTERS_TILE",
    val: {
      EN: "Filters",
      PL: "Filtry",
    },
  },
  BROKER_LIST__FILTERS_BROKER_TEAM_NAME_LABEL: {
    key: "BROKER_LIST__FILTERS_BROKER_TEAM_NAME_LABEL",
    val: {
      EN: "Broker team",
      PL: "Zespół brokerski",
    },
  },
  BROKER_LIST__FILTERS_LAST_NAME_LABEL: {
    key: "BROKER_LIST__FILTERS_LAST_NAME_LABEL",
    val: {
      EN: "Lastname",
      PL: "Nazwisko",
    },
  },
  BROKER_LIST__FILTERS_SEARCH_BUTTON_LABEL: {
    key: "BROKER_LIST__FILTERS_SEARCH_BUTTON_LABEL",
    val: {
      EN: "Search",
      PL: "Szukaj",
    },
  },
  BROKER_LIST__ADD_NEW_BROKER_BUTTON_LABEL: {
    key: "BROKER_LIST__ADD_NEW_BROKER_BUTTON_LABEL",
    val: {
      EN: "Add new Broker",
      PL: "Dodaj Brokera",
    },
  },
  BROKER_LIST_TABLE__HEADERS_ACTIONS_LABEL: {
    key: "BROKER_LIST_TABLE__HEADERS_ACTIONS_LABEL",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  BROKER_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL: {
    key: "BROKER_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL",
    val: {
      EN: "Sort ascending",
      PL: "Sortuj rosnąco",
    },
  },
  BROKER_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL: {
    key: "BROKER_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL",
    val: {
      EN: "Sort descensing",
      PL: "Sortuj malejąco",
    },
  },
  BROKER_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL: {
    key: "BROKER_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL",
    val: {
      EN: "Cancel sort",
      PL: "Usuń sortowanie",
    },
  },

  BROKER_CREATE__TITLE: {
    key: "BROKER_CREATE__TITLE",
    val: {
      EN: "Broker data",
      PL: "Dane Brokera",
    },
  },
  BROKER_CREATE__FORM_NAME_LABEL: {
    key: "BROKER_CREATE__FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  BROKER_CREATE__FORM_CREATE_BUTTON_LABEL: {
    key: "BROKER_CREATE__FORM_CREATE_BUTTON_LABEL",
    val: {
      EN: "Create",
      PL: "Utwórz",
    },
  },
  BROKER_TEAM_LIST__TITLE: {
    key: "BROKER_TEAM_LIST__TITLE",
    val: {
      EN: "Broker teams",
      PL: "Zespoły Brokerskie",
    },
  },
  BROKER_TEAM_LIST__FILTERS_TILE: {
    key: "BROKER_TEAM_LIST__FILTERS_TILE",
    val: {
      EN: "Filters",
      PL: "Filtry",
    },
  },
  BROKER_TEAM_LIST__FILTERS_NAME_LABEL: {
    key: "BROKER_TEAM_LIST__FILTERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  BROKER_TEAM_LIST__FILTERS_LASTNAME_LABEL: {
    key: "BROKER_TEAM_LIST__FILTERS_LASTNAME_LABEL",
    val: {
      EN: "Lastname",
      PL: "Nazwisko",
    },
  },
  BROKER_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL: {
    key: "BROKER_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL",
    val: {
      EN: "Search",
      PL: "Szukaj",
    },
  },
  BROKER_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL: {
    key: "BROKER_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL",
    val: {
      EN: "Add new team",
      PL: "Dodaj nowy zespół",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_NAME_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_STATUS_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_STATUS_LABEL",
    val: {
      EN: "Status",
      PL: "Status",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_LEADER_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_LEADER_LABEL",
    val: {
      EN: "Team leader",
      PL: "Lider zespołu",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_TEAM_SIZE_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_TEAM_SIZE_LABEL",
    val: {
      EN: "Team size",
      PL: "Ilość osób",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_POLICIES_COUNT_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_POLICIES_COUNT_LABEL",
    val: {
      EN: "Policies count",
      PL: "Liczba polis",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_TEAM_NOTE_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_TEAM_NOTE_LABEL",
    val: {
      EN: "Team note",
      PL: "Przypis zespołu",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_ACTIONS_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_ACTIONS_LABEL",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL",
    val: {
      EN: "Sort ascending",
      PL: "Sortuj rosnąco",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL",
    val: {
      EN: "Sort descensing",
      PL: "Sortuj malejąco",
    },
  },
  BROKER_TEAM_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL",
    val: {
      EN: "Cancel sort",
      PL: "Usuń sortowanie",
    },
  },
  BROKER_TEAM_LIST_TABLE__ACTION_DETAILS_BUTTON_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__ACTION_DETAILS_BUTTON_LABEL",
    val: {
      EN: "Details",
      PL: "Szczegóły",
    },
  },
  BROKER_TEAM_LIST_TABLE__ACTION_UPDATE_BUTTON_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__ACTION_UPDATE_BUTTON_LABEL",
    val: {
      EN: "Update",
      PL: "Aktualizacja",
    },
  },
  BROKER_TEAM_LIST_TABLE__ACTION_DEACTIVATION_BUTTON_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__ACTION_DEACTIVATION_BUTTON_LABEL",
    val: {
      EN: "Deactivation",
      PL: "Dezaktywacja",
    },
  },
  BROKER_TEAM_LIST_TABLE__STATUSES_ACTIVE_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__STATUSES_ACTIVE_LABEL",
    val: {
      EN: "Active",
      PL: "Aktywny",
    },
  },
  BROKER_TEAM_LIST_TABLE__STATUSES_INACTIVE_LABEL: {
    key: "BROKER_TEAM_LIST_TABLE__STATUSES_INACTIVE_LABEL",
    val: {
      EN: "Inactive",
      PL: "Nieaktywny",
    },
  },
  BROKER_TEAM_CREATE__TITLE: {
    key: "BROKER_TEAM_CREATE__TITLE",
    val: {
      EN: "Broker team data",
      PL: "Dane zespołu brokerskiego",
    },
  },
  BROKER_TEAM_CREATE__FORM_NAME_LABEL: {
    key: "BROKER_TEAM_CREATE__FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  BROKER_TEAM_CREATE__FORM_LEADER_LABEL: {
    key: "BROKER_TEAM_CREATE__FORM_LEADER_LABEL",
    val: {
      EN: "Team Leader",
      PL: "Lider zespołu",
    },
  },
  BROKER_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL: {
    key: "BROKER_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL",
    val: {
      EN: "Create",
      PL: "Utwórz",
    },
  },
  BROKER_TEAM_EDIT__TITLE: {
    key: "BROKER_TEAM_EDIT__TITLE",
    val: {
      EN: "Broker team data update",
      PL: "Aktualizacja danych zespołu brokerskiego",
    },
  },
  BROKER_TEAM_EDIT__FORM_NAME_LABEL: {
    key: "BROKER_TEAM_EDIT__FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  BROKER_TEAM_EDIT__FORM_LEADER_LABEL: {
    key: "BROKER_TEAM_EDIT__FORM_LEADER_LABEL",
    val: {
      EN: "Team Leader",
      PL: "Lider zespołu",
    },
  },
  BROKER_TEAM_CREATE__FORM_SAVE_BUTTON_LABEL: {
    key: "BROKER_TEAM_CREATE__FORM_SAVE_BUTTON_LABEL",
    val: {
      EN: "Save",
      PL: "Zapisz",
    },
  },
  BROKER_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL: {
    key: "BROKER_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL",
    val: {
      EN: "Save",
      PL: "Zapisz",
    },
  },
  BROKER_TEAM_DEACTIVATION__MODAL_TITLE: {
    key: "BROKER_TEAM_DEACTIVATION__MODAL_TITLE",
    val: {
      EN: "Are you sure you want to deactivate broker team",
      PL: "Czy napewno chcesz dezaktywować zespół brokerski",
    },
  },
  BROKER_TEAM_DEACTIVATION__MODAL_CONFIRMATION_BUTTON_LABEL: {
    key: "BROKER_TEAM_DEACTIVATION__MODAL_CONFIRMATION_BUTTON_LABEL",
    val: {
      EN: "Confirm",
      PL: "Zatwierdź",
    },
  },
  BROKER_TEAM_DEACTIVATION__MODAL_DEACTIVATE_BUTTON_LABEL: {
    key: "BROKER_TEAM_DEACTIVATION__MODAL_DEACTIVATE_BUTTON_LABEL",
    val: {
      EN: "Deletion",
      PL: "Dezaktywowanie",
    },
  },
  BROKER_TEAM_DEACTIVATION__MODAL_CANCEL_BUTTON_LABEL: {
    key: "BROKER_TEAM_DEACTIVATION__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },
  BROKER_TEAM_DETAILS__STATUSES_ACTIVE_LABEL: {
    key: "BROKER_TEAM_DETAILS__STATUSES_ACTIVE_LABEL",
    val: {
      EN: "Active",
      PL: "Aktywny",
    },
  },
  BROKER_TEAM_DETAILS__STATUSES_INACTIVE_LABEL: {
    key: "BROKER_TEAM_DETAILS__STATUSES_INACTIVE_LABEL",
    val: {
      EN: "Inactive",
      PL: "Nieaktywny",
    },
  },
  BROKER_TEAM_DETAILS__ACTIONS_TILE: {
    key: "BROKER_TEAM_DETAILS__ACTIONS_TILE",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  BROKER_TEAM_DETAILS__ACTION_ADD_LABEL: {
    key: "BROKER_TEAM_DETAILS__ACTION_ADD_LABEL",
    val: {
      EN: "Add member",
      PL: "Dodaj członka",
    },
  },
  TEAM_LEADER_CARD__TITLE: {
    key: "TEAM_LEADER_CARD__TITLE",
    val: {
      EN: "Team Leader",
      PL: "Lider zespołu",
    },
  },
  TEAM_MEMBERS_CARD__TITLE: {
    key: "TEAM_MEMBERS_CARD__TITLE",
    val: {
      EN: "Team members",
      PL: "Członkowie zespołu",
    },
  },
  TEAM_MEMBERS_CARD__EMPTY_LIST_TITLE: {
    key: "TEAM_MEMBERS_CARD__EMPTY_LIST_TITLE",
    val: {
      EN: "No data",
      PL: "Brak danych",
    },
  },
  TEAM_MEMBERS_ADD__MODAL_TITLE: {
    key: "TEAM_MEMBERS_ADD__MODAL_TITLE",
    val: {
      EN: "Team member addtion",
      PL: "Dodawanie członka zespołu",
    },
  },
  TEAM_MEMBERS_ADD__MODAL_FORM_PERSON_LABEL: {
    key: "TEAM_MEMBERS_ADD__MODAL_FORM_PERSON_LABEL",
    val: {
      EN: "Person",
      PL: "Osoba",
    },
  },
  TEAM_MEMBERS_ADD__MODAL_CONFIRM_BUTTON_LABEL: {
    key: "TEAM_MEMBERS_ADD__MODAL_CONFIRM_BUTTON_LABEL",
    val: {
      EN: "Confirm",
      PL: "Zatwierdź",
    },
  },
  TEAM_MEMBERS_ADD__MODAL_CREATION_BUTTON_LABEL: {
    key: "TEAM_MEMBERS_ADD__MODAL_CREATION_BUTTON_LABEL",
    val: {
      EN: "Creating",
      PL: "Dodawanie",
    },
  },
  TEAM_MEMBERS_ADD__MODAL_CANCEL_BUTTON_LABEL: {
    key: "TEAM_MEMBERS_ADD__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },
  BROKER_TEAM_MEMBERS__REMOVE_BUTTON_LABEL: {
    key: "BROKER_TEAM_MEMBERS__REMOVE_BUTTON_LABEL",
    val: {
      EN: "Remove from team",
      PL: "Usuń z zespołu",
    },
  },
  BROKER_TEAM_MEMBERS__EDIT_BUTTON_LABEL: {
    key: "BROKER_TEAM_MEMBERS__EDIT_BUTTON_LABEL",
    val: {
      EN: "Update data",
      PL: "Aktualizuj dane",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_TITLE: {
    key: "TEAM_MEMBER_EDIT__MODAL_TITLE",
    val: {
      EN: "Team member personal data update",
      PL: "Aktualizacja danych członka zespołu",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_FORM_NAME_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_FORM_FIRST_NAME_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_FORM_FIRST_NAME_LABEL",
    val: {
      EN: "First name",
      PL: "Imię",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_FORM_LAST_NAME_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_FORM_LAST_NAME_LABEL",
    val: {
      EN: "Last name",
      PL: "Nazwisko",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_FORM_PHONE_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_FORM_PHONE_LABEL",
    val: {
      EN: "Contact phone number",
      PL: "Numer kontaktowy",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_FORM_EMAIL_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_FORM_EMAIL_LABEL",
    val: {
      EN: "E-mail",
      PL: "E-mail",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_SAVE_BUTTON_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_SAVE_BUTTON_LABEL",
    val: {
      EN: "Save",
      PL: "Zapisz",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_UPDATING_BUTTON_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_UPDATING_BUTTON_LABEL",
    val: {
      EN: "Updating",
      PL: "Aktualizowanie",
    },
  },
  TEAM_MEMBER_EDIT__MODAL_CANCEL_BUTTON_LABEL: {
    key: "TEAM_MEMBER_EDIT__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },

  TEAM_MEMBER_REMOVE__MODAL_TITLE: {
    key: "TEAM_MEMBER_REMOVE__MODAL_TITLE",
    val: {
      EN: "Are you sure you want to remove member",
      PL: "Czy na pewno chcesz usunąć członka",
    },
  },
  TEAM_MEMBER_REMOVE__MODAL_CANCEL_BUTTON_LABEL: {
    key: "TEAM_MEMBER_REMOVE__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },
  TEAM_MEMBER_REMOVE__MODAL_CONFIRM_BUTTON_LABEL: {
    key: "TEAM_MEMBER_REMOVE__MODAL_CONFIRM_BUTTON_LABEL",
    val: {
      EN: "Confirm",
      PL: "Zatwierdź",
    },
  },
  TEAM_MEMBER_REMOVE__MODAL_REMOVING_BUTTON_LABEL: {
    key: "TEAM_MEMBER_REMOVE__MODAL_REMOVING_BUTTON_LABEL",
    val: {
      EN: "Removing",
      PL: "Usuwanie",
    },
  },
  AGENT_TEAM_LIST__TITLE: {
    key: "AGENT_TEAM_LIST__TITLE",
    val: {
      EN: "Agent teams",
      PL: "Zespoły Agencyjne",
    },
  },
  AGENT_TEAM_LIST__FILTERS_TILE: {
    key: "AGENT_TEAM_LIST__FILTERS_TILE",
    val: {
      EN: "Filters",
      PL: "Filtry",
    },
  },
  AGENT_TEAM_LIST__FILTERS_NAME_LABEL: {
    key: "AGENT_TEAM_LIST__FILTERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  AGENT_TEAM_LIST__FILTERS_LASTNAME_LABEL: {
    key: "AGENT_TEAM_LIST__FILTERS_LASTNAME_LABEL",
    val: {
      EN: "Lastname",
      PL: "Nazwisko",
    },
  },
  AGENT_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL: {
    key: "AGENT_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL",
    val: {
      EN: "Search",
      PL: "Szukaj",
    },
  },
  AGENT_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL: {
    key: "AGENT_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL",
    val: {
      EN: "Add new team",
      PL: "Dodaj nowy zespół",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_NAME_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_STATUS_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_STATUS_LABEL",
    val: {
      EN: "Status",
      PL: "Status",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_LEADER_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_LEADER_LABEL",
    val: {
      EN: "Team leader",
      PL: "Lider zespołu",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_TEAM_SIZE_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_TEAM_SIZE_LABEL",
    val: {
      EN: "Team size",
      PL: "Ilość osób",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_POLICIES_COUNT_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_POLICIES_COUNT_LABEL",
    val: {
      EN: "Policies count",
      PL: "Liczba polis",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_TEAM_NOTE_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_TEAM_NOTE_LABEL",
    val: {
      EN: "Team note",
      PL: "Przypis zespołu",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_ACTIONS_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_ACTIONS_LABEL",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_ASCENDING_LABEL",
    val: {
      EN: "Sort ascending",
      PL: "Sortuj rosnąco",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_DESCENDING_LABEL",
    val: {
      EN: "Sort descensing",
      PL: "Sortuj malejąco",
    },
  },
  AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__HEADERS_SORTING_CANCEL_LABEL",
    val: {
      EN: "Cancel sort",
      PL: "Usuń sortowanie",
    },
  },
  AGENT_TEAM_LIST_TABLE__ACTION_DETAILS_BUTTON_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__ACTION_DETAILS_BUTTON_LABEL",
    val: {
      EN: "Details",
      PL: "Szczegóły",
    },
  },
  AGENT_TEAM_LIST_TABLE__ACTION_UPDATE_BUTTON_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__ACTION_UPDATE_BUTTON_LABEL",
    val: {
      EN: "Update",
      PL: "Aktualizacja",
    },
  },
  AGENT_TEAM_LIST_TABLE__ACTION_DEACTIVATION_BUTTON_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__ACTION_DEACTIVATION_BUTTON_LABEL",
    val: {
      EN: "Deactivation",
      PL: "Dezaktywacja",
    },
  },
  AGENT_TEAM_LIST_TABLE__STATUSES_ACTIVE_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__STATUSES_ACTIVE_LABEL",
    val: {
      EN: "Active",
      PL: "Aktywny",
    },
  },
  AGENT_TEAM_LIST_TABLE__STATUSES_INACTIVE_LABEL: {
    key: "AGENT_TEAM_LIST_TABLE__STATUSES_INACTIVE_LABEL",
    val: {
      EN: "Inactive",
      PL: "Nieaktywny",
    },
  },
  AGENT_TEAM_CREATE__TITLE: {
    key: "AGENT_TEAM_CREATE__TITLE",
    val: {
      EN: "Broker team data",
      PL: "Dane zespołu brokerskiego",
    },
  },
  AGENT_TEAM_CREATE__FORM_NAME_LABEL: {
    key: "AGENT_TEAM_CREATE__FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  AGENT_TEAM_CREATE__FORM_LEADER_LABEL: {
    key: "AGENT_TEAM_CREATE__FORM_LEADER_LABEL",
    val: {
      EN: "Team Leader",
      PL: "Lider zespołu",
    },
  },
  AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL: {
    key: "AGENT_TEAM_CREATE__FORM_CREATE_BUTTON_LABEL",
    val: {
      EN: "Create",
      PL: "Utwórz",
    },
  },
  AGENT_TEAM_DEACTIVATION__MODAL_TITLE: {
    key: "AGENT_TEAM_DEACTIVATION__MODAL_TITLE",
    val: {
      EN: "Are you sure you want to deactivate broker team",
      PL: "Czy napewno chcesz dezaktywować zespół brokerski",
    },
  },
  AGENT_TEAM_DEACTIVATION__MODAL_CONFIRMATION_BUTTON_LABEL: {
    key: "AGENT_TEAM_DEACTIVATION__MODAL_CONFIRMATION_BUTTON_LABEL",
    val: {
      EN: "Confirm",
      PL: "Zatwierdź",
    },
  },
  AGENT_TEAM_DEACTIVATION__MODAL_DEACTIVATE_BUTTON_LABEL: {
    key: "AGENT_TEAM_DEACTIVATION__MODAL_DEACTIVATE_BUTTON_LABEL",
    val: {
      EN: "Deletion",
      PL: "Dezaktywowanie",
    },
  },
  AGENT_TEAM_DEACTIVATION__MODAL_CANCEL_BUTTON_LABEL: {
    key: "AGENT_TEAM_DEACTIVATION__MODAL_CANCEL_BUTTON_LABEL",
    val: {
      EN: "Cancel",
      PL: "Anuluj",
    },
  },
  AGENT_TEAM_DETAILS__STATUSES_ACTIVE_LABEL: {
    key: "AGENT_TEAM_DETAILS__STATUSES_ACTIVE_LABEL",
    val: {
      EN: "Active",
      PL: "Aktywny",
    },
  },
  AGENT_TEAM_DETAILS__STATUSES_INACTIVE_LABEL: {
    key: "AGENT_TEAM_DETAILS__STATUSES_INACTIVE_LABEL",
    val: {
      EN: "Inactive",
      PL: "Nieaktywny",
    },
  },
  AGENT_TEAM_DETAILS__ACTIONS_TILE: {
    key: "AGENT_TEAM_DETAILS__ACTIONS_TILE",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  AGENT_TEAM_DETAILS__ACTION_ADD_LABEL: {
    key: "AGENT_TEAM_DETAILS__ACTION_ADD_LABEL",
    val: {
      EN: "Add member",
      PL: "Dodaj członka",
    },
  },
  AGENT_TEAM_MEMBERS__REMOVE_BUTTON_LABEL: {
    key: "AGENT_TEAM_MEMBERS__REMOVE_BUTTON_LABEL",
    val: {
      EN: "Remove from team",
      PL: "Usuń z zespołu",
    },
  },
  AGENT_TEAM_MEMBERS__EDIT_BUTTON_LABEL: {
    key: "AGENT_TEAM_MEMBERS__EDIT_BUTTON_LABEL",
    val: {
      EN: "Update data",
      PL: "Aktualizuj dane",
    },
  },
  AGENT_TEAM_EDIT__TITLE: {
    key: "AGENT_TEAM_EDIT__TITLE",
    val: {
      EN: "Agent team data update",
      PL: "Aktualizacja danych zespołu agencyjnego",
    },
  },
  AGENT_TEAM_EDIT__FORM_NAME_LABEL: {
    key: "AGENT_TEAM_EDIT__FORM_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  AGENT_TEAM_EDIT__FORM_LEADER_LABEL: {
    key: "AGENT_TEAM_EDIT__FORM_LEADER_LABEL",
    val: {
      EN: "Team Leader",
      PL: "Lider zespołu",
    },
  },
  AGENT_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL: {
    key: "AGENT_TEAM_EDIT__FORM_SAVE_BUTTON_LABEL",
    val: {
      EN: "Save",
      PL: "Zapisz",
    },
  },
  GENERAL_CONTRACT_LIST__TITLE: {
    key: "GENERAL_CONTRACT_LIST__TITLE",
    val: {
      EN: "General Contracts",
      PL: "Umowy Generalne",
    },
  },
  LIST__FILTERS_TILE: {
    key: "LIST__FILTERS_TILE",
    val: {
      EN: "Filters",
      PL: "Filtry",
    },
  },
  LIST__FILTERS_NAME_LABEL: {
    key: "LIST__FILTERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },
  LIST__FILTERS_SEARCH_BUTTON_LABEL: {
    key: "LIST__FILTERS_SEARCH_BUTTON_LABEL",
    val: {
      EN: "Search",
      PL: "Szukaj",
    },
  },
  GENERAL_CONTRACT_LIST__ADD_NEW_GENERAL_CONTRACT_BUTTON_LABEL: {
    key: "GENERAL_CONTRACT_LIST__ADD_NEW_GENERAL_CONTRACT_BUTTON_LABEL",
    val: {
      EN: "Add new general contract",
      PL: "Dodaj nową umowę generalną",
    },
  },
  TABLE__HEADERS_SORTING_ASCENDING_LABEL: {
    key: "TABLE__HEADERS_SORTING_ASCENDING_LABEL",
    val: {
      EN: "Sort ascending",
      PL: "Sortuj rosnąco",
    },
  },
  TABLE__HEADERS_SORTING_DESCENDING_LABEL: {
    key: "TABLE__HEADERS_SORTING_DESCENDING_LABEL",
    val: {
      EN: "Sort descensing",
      PL: "Sortuj malejąco",
    },
  },
  TABLE__HEADERS_SORTING_CANCEL_LABEL: {
    key: "TABLE__HEADERS_SORTING_CANCEL_LABEL",
    val: {
      EN: "Cancel sort",
      PL: "Usuń sortowanie",
    },
  },
  TABLE__HEADERS_ACTIONS_LABEL: {
    key: "TABLE__HEADERS_ACTIONS_LABEL",
    val: {
      EN: "Actions",
      PL: "Akcje",
    },
  },
  TABLE__ACTION_DETAILS_BUTTON_LABEL: {
    key: "TABLE__ACTION_DETAILS_BUTTON_LABEL",
    val: {
      EN: "Details",
      PL: "Szczegóły",
    },
  },
  TABLE__ACTION_EDIT_BUTTON_LABEL: {
    key: "TABLE__ACTION_EDIT_BUTTON_LABEL",
    val: {
      EN: "Edit",
      PL: "Edycja",
    },
  },
  GENERAL_CONTRACT_LIST_TABLE__HEADERS_NAME_LABEL: {
    key: "GENERAL_CONTRACT_LIST_TABLE__HEADERS_NAME_LABEL",
    val: {
      EN: "Name",
      PL: "Nazwa",
    },
  },

  GENERAL_CONTRACT_CREATE__TITLE: {
    key: "GENERAL_CONTRACT_CREATE__TITLE",
    val: {
      EN: "New general contract addition",
      PL: "Dodawanie nowej umowy generalnej",
    },
  },

  GENERAL_CONTRACT_NUMBER__LABEL: {
    key: "GENERAL_CONTRACT_NUMBER__LABEL",
    val: {
      EN: "GC Number",
      PL: "Numer UG",
    },
  },
  GENERAL_CONTRACT_START_DATE__LABEL: {
    key: "GENERAL_CONTRACT_START_DATE__LABEL",
    val: {
      EN: "GC Start date",
      PL: "Data startu umowy",
    },
  },
  GENERAL_CONTRACT_INSURER__LABEL: {
    key: "GENERAL_CONTRACT_INSURER__LABEL",
    val: {
      EN: "Insurer",
      PL: "Ubezpieczyciel",
    },
  },
  GENERAL_CONTRACT_INSURER_DEPOT__LABEL: {
    key: "GENERAL_CONTRACT_INSURER_DEPOT__LABEL",
    val: {
      EN: "Insurer depot",
      PL: "Oddział ubezpieczyciela",
    },
  },
  GENERAL_CONTRACT_HANDLING_PERSON_INSURER__LABEL: {
    key: "GENERAL_CONTRACT_HANDLING_PERSON_INSURER__LABEL",
    val: {
      EN: "Handling person - Insurer",
      PL: "Osoba prowadząca - Ubezpieczyciel",
    },
  },
  GENERAL_CONTRACT__HANDLING_PERSON_INSURER_FIRST_NAME__LABEL: {
    key: "GENERAL_CONTRACT__HANDLING_PERSON_INSURER_FIRST_NAME__LABEL",
    val: {
      EN: "First name",
      PL: "Imię",
    },
  },
  GENERAL_CONTRACT__HANDLING_PERSON_INSURER_LAST_NAME__LABEL: {
    key: "GENERAL_CONTRACT__HANDLING_PERSON_INSURER_LAST_NAME__LABEL",
    val: {
      EN: "Last name",
      PL: "Nazwisko",
    },
  },
  GENERAL_CONTRACT__HANDLING_PERSON_INSURER_PHONE__LABEL: {
    key: "GENERAL_CONTRACT__HANDLING_PERSON_INSURER_PHONE__LABEL",
    val: {
      EN: "Contact phone",
      PL: "Telefon kontaktowy",
    },
  },
  GENERAL_CONTRACT__HANDLING_PERSON_INSURER_EMAIL__LABEL: {
    key: "GENERAL_CONTRACT__HANDLING_PERSON_INSURER_EMAIL__LABEL",
    val: {
      EN: "Contact e-mail address",
      PL: "Kontaktowy adres e-mail",
    },
  },
  GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL: {
    key: "GENERAL_CONTRACT_CREATE__FORM_PREVIOUS_BUTTON_LABEL",
    val: {
      EN: "Previous",
      PL: "Wróć",
    },
  },
  GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL: {
    key: "GENERAL_CONTRACT_CREATE__FORM_NEXT_BUTTON_LABEL",
    val: {
      EN: "Next",
      PL: "Dalej",
    },
  },
  GENERAL_CONTRACT_CREATE__FORM_CREATE_BUTTON_LABEL: {
    key: "GENERAL_CONTRACT_CREATE__FORM_CREATE_BUTTON_LABEL",
    val: {
      EN: "Create",
      PL: "Utwórz",
    },
  },

  FOO_BAR: {
    key: "FOO_BAR",
    val: {
      EN: "This is {foo} and {bar}",
      PL: "To jest {foo} oraz {bar}",
    },
  },
};
