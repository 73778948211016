import React, { useEffect, useState } from "react";
import api from "../../infra/api";
import translate from "../../translate";

import useQueryParams from "../../hooks/useQueryParams.hook";

import { useNavigate, useSearchParams } from "react-router-dom";
import useBus from "use-bus";

import { Divider, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import BrokerTeamsFilters from "../../components/brokerTeam/BrokerTeamsFilters";
import BrokerTeamsTable from "../../components/brokerTeam/BrokerTeamsTable";

import { TRANSLATIONS } from "../../translate/translations";

export default function BrokerTeamsPage({ user }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { queryParams, setQueryParams } = useQueryParams();
  const [filters, setFilters] = useState({
    name_contains: "",
    broker_last_name_contains: "",
  });
  const [brokerTeams, setBrokerTeams] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    current: 1,
    next: null,
    prev: null,
  });
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState("-id");

  const fetchBrokerTeams = async ({
    page,
    size,
    order_by,
    filters,
  }) => {
    setLoading(true);
    const queryFilters = `name_contains=${filters.name_contains ?? ""}&broker_last_name_contains=${filters.broker_last_name_contains ?? ""}`;
    api.brokerTeams
      .getBrokerTeams({
        page,
        size,
        order_by,
        queryFilters,
      })
      .then(response => {
        const data = response.data;
        const pag = data.pagination;
        const res = data.results.map((result) => ({
          ...result,
          key: result.id,
        }));

        setPagination(pag);
        setBrokerTeams(res);
        setPageNumber(pag.current);
        setLoading(false);
      })
      .catch(error => {
        console.log("broker teams page error", error);
      });
  };

  useEffect(() => {
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setFilters((oldFilters) => {
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains = paramsFromUrl.name_contains ?? "";
      newFilters.broker_last_name_contains = paramsFromUrl.broker_last_name_contains ?? "";
      const data = {
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      fetchBrokerTeams(data);
      return newFilters;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, searchParams]);

  useEffect(() => {
    console.log("BrokerTeamsPage mounted ...");
  }, []);

  // TODO: do przerobienia na jakieś lepsze rozwiązanie
  useBus("BROKER_TEAM_DEACTIVATED", (event) => {
    const paramsFromUrl = {};
    for (const [k, v] of searchParams) {
      paramsFromUrl[k] = v;
    }
    setPageNumber(paramsFromUrl.page ?? pageNumber);
    setPageSize(paramsFromUrl.size ?? pageSize);
    setOrderBy(paramsFromUrl.order_by ?? orderBy);
    setFilters((oldFilters) => {
      const newFilters = Object.assign({}, oldFilters);
      newFilters.name_contains = paramsFromUrl.name_contains ?? oldFilters.name_contains;
      newFilters.broker_last_name_contains = paramsFromUrl.broker_last_name_contains ?? oldFilters.broker_last_name_contains;
      const data = {
        page: paramsFromUrl.page ?? pageNumber,
        size: paramsFromUrl.size ?? pageSize,
        order_by: paramsFromUrl.order_by ?? orderBy,
        filters: newFilters,
      };
      fetchBrokerTeams(data);
      return newFilters;
    });
  });

  return (
    <>
      <h1 id={TRANSLATIONS.BROKER_TEAM_LIST__TITLE.key}>
        {translate(TRANSLATIONS.BROKER_TEAM_LIST__TITLE.key)}
      </h1>

      <Divider />

      <BrokerTeamsFilters
        user={user}
        options={{
          pageNumber,
          pageSize,
          orderBy,
          filters,
          setFilters,
        }}
      />

      <Row
        justify="space-between"
        style={{
          marginTop: 30,
          marginBottom: 15,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Col>
          <Button
            id={TRANSLATIONS.BROKER_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL.key}
            type="info"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => navigate("/broker-teams/create")}
          >
            {translate(TRANSLATIONS.BROKER_TEAM_LIST__ADD_NEW_BROKER_TEAM_BUTTON_LABEL.key)}
          </Button>
        </Col>
      </Row>

      <BrokerTeamsTable
        user={user}
        tableStateChanged={(newPageNumber, newPageSize, newOrderBy) => {
          setPageNumber(newPageNumber);
          setPageSize(newPageSize);
          setOrderBy(newOrderBy);
          setQueryParams("/broker-teams", {
            page: newPageNumber,
            size: newPageSize,
            order_by: newOrderBy,
            name_contains: filters.name_contains,
            broker_last_name_contains: filters.broker_last_name_contains,
          });
        }}
        options={{
          pagination,
          pageSize,
          items: brokerTeams,
        }}
        loading={loading}
      />
    </>
  );
}
