import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom'

import api from "../../infra/api";
import translate from "../../translate";
import { TRANSLATIONS } from "../../translate/translations";
import useBus from "use-bus";

import { Tag, Divider, Spin, Card, Button, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";

import BrokerTeamMemberAddModal from "../../components/brokerTeam/member/BrokerTeamMemberAddModal";
import TeamLeaderCard from "../../components/commons/TeamLeaderCard";
import TeamMemberListCard from "../../components/commons/TeamMemberListCard";
import BrokerTeamMemberBrokerEditModal from "../../components/brokerTeam/member/BrokerTeamMemberBrokerEditModal";
import BrokerTeamMemberCobrokerEditModal from "../../components/brokerTeam/member/BrokerTeamMemberCobrokerEditModal"
import BrokerTeamMemberRemoveModal from "../../components/brokerTeam/member/BrokerTeamMemberRemoveModal";

export default function BrokerTeamDetailPage({ user }) {
	let { id } = useParams();
	const [brokerTeam, setBrokerTeam] = useState(null);
	const [leader, setLeader] = useState({
		key: null,
		id: null,
		title: "",
		description: "",
		actions: [],
	});
	const [brokers, setBrokers] = useState([{
		key: null,
		id: null,
		title: "",
		description: "",
		actions: [],
	}])
	const [loading, setLoading] = useState(false);
	const statusesDict = {
		active: {
			text: "ACTIVE",
			translation: translate(TRANSLATIONS.BROKER_TEAM_DETAILS__STATUSES_ACTIVE_LABEL.key),
		},
		inactive: {
			text: "INACTIVE",
			translation: translate(TRANSLATIONS.BROKER_TEAM_DETAILS__STATUSES_INACTIVE_LABEL.key),
		},
	};

	const fetchBrokerTeam = async (id) => {
		setLoading(true);
		api.brokerTeams
			.getBrokerTeam(id)
			.then(response => {
				const data = response.data;
				console.log(data);
				setBrokerTeam(data);

				const leaderData = data.leader;
				setLeader((oldState) => {
					const newState = Object.assign({}, oldState);
					newState.key = leaderData.id;
					newState.id = leaderData.id;
					newState.title = `${leaderData.first_name} ${leaderData.last_name}`;
					newState.description = <>
						<span>{leaderData.email}</span>
						{leaderData.phone &&
							<>
								<br />
								<span>{leaderData.phone}</span>
							</>
						}
					</>;
					newState.avatar = leaderData.first_name.slice(-1).toLowerCase() === "a" ? "/avatar2.svg" : "/avatar.svg";
					newState.actions = [<BrokerTeamMemberBrokerEditModal teamId={Number(id)} personalData={leaderData} />];
					return newState;
				});

				setBrokers((oldState) => {
					const state = Object.assign({}, oldState[0]);
					const nState = data.brokers
						.filter((broker) => broker.personal_data.id !== leaderData.id)
						.map((broker) => {
							const newState = Object.assign({}, state);
							const brokerId = broker.id;
							const personalData = broker.personal_data;
							personalData.broker_id = brokerId;
							newState.key = brokerId;
							newState.id = brokerId;
							newState.title = personalData.company_name ?? `${personalData.first_name} ${personalData.last_name}`;
							newState.description = <>
								<span>{personalData.email}</span>
								{personalData.phone &&
									<>
										<br />
										<span>{personalData.phone}</span>
									</>
								}
							</>;
							newState.avatar = personalData.company_name
								? "/avatar3.svg"
								: personalData.first_name.slice(-1).toLowerCase() === "a"
									? "/avatar2.svg"
									: "/avatar.svg";
							newState.actions = [
								personalData.company_name
									? <BrokerTeamMemberCobrokerEditModal teamId={Number(id)} personalData={personalData} />
									: <BrokerTeamMemberBrokerEditModal teamId={Number(id)} personalData={personalData} />,
								<BrokerTeamMemberRemoveModal member={newState} />
							];
							return newState;
						});
					return nState;
				});
				setLoading(false);
			})
			.catch(error => {
				console.log("broker team details page error", error);
				setLoading(false);
			});
	};

	useBus("BROKER_ADDED", (event) => {
		fetchBrokerTeam(id);
	});

	useBus("BROKER_REMOVED", (event) => {
		fetchBrokerTeam(id);
	});

	useBus("BROKER_UPDATED", (event) => {
		fetchBrokerTeam(id);
	});

	useBus("COBROKER_UPDATED", (event) => {
		fetchBrokerTeam(id);
	});

	useEffect(() => {
		console.log("BrokerTeamDetailsPage mounted ...");
		fetchBrokerTeam(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!brokerTeam &&
				<Spin tip="Wczytywanie danych..." spinning={loading}>
					<div />
				</Spin>
			}

			{brokerTeam &&
				<>
					<h1>
						<span>{brokerTeam.name}</span>
						<Tag
							color={brokerTeam.status === statusesDict.active.text ? "success" : "error"}
							style={{ marginLeft: 15, marginTop: 10, position: "absolute" }}
						>
							{statusesDict[brokerTeam.status.toLowerCase()].translation}
						</Tag>
					</h1>

					<Divider />

					<Card
						style={{ marginBottom: 10, marginTop: 15 }}
						type="inner"
						title={
							<h3 id={TRANSLATIONS.BROKER_TEAM_DETAILS__ACTIONS_TILE.key}>
								{translate(TRANSLATIONS.BROKER_TEAM_DETAILS__ACTIONS_TILE.key)}
							</h3>
						}
					>
						<Space direction="horizontal" size="middle">
							<BrokerTeamMemberAddModal brokerTeamId={Number(id)} />
							<Link to={`/broker-teams/${id}/update`}>
								<Button type="primary" htmlType="button" size="large" icon={<EditOutlined />}>
									Edytuj dane zespołu
								</Button>
							</Link>
							{/* <Button type="default" htmlType="button">Dodaj cobrokera</Button> */}
						</Space>
					</Card>

					<TeamLeaderCard leaderData={leader} />

					<TeamMemberListCard membersData={brokers} loading={loading} />
				</>
			}
		</>
	)
}
