import React, { useState } from "react";

import api from "../../../infra/api";
import { dispatch } from "use-bus";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import { Button, Modal, Tooltip, Divider } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

export default function CustomerDeleteModal({ record }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const submit = async () => {
    startLoading();
    api.customers
      .deleteCustomer(record.id)
      .then(response => {
        stopLoading();
        closeModal();
        showNotification(
          "success",
          "Operacja zakończona powodzeniem",
          `Pomyślnie usunięto klienta "${record.name}"`
        );
        dispatch({ type: "CUSTOMER_DELETED" });
      })
      .catch(error => {
        stopLoading();
        showNotification(
          "error",
          "Operacja zakończona niepowodzeniem",
          `Wystąpił błąd podczas usuwania klienta "${record.name}"`
        );
      });
  };

  const showNotification = (type, title, description) => {
		dispatch({
			type: "SHOW_NOTIFICATION",
			payload: {
				notification: {
					type,
					title,
					description,
				}
			}
		});
  };

  return (
    <>
      <Tooltip
        title={translate(
          TRANSLATIONS.CUSTOMER_LIST_TABLE__ACTION_DELETE_BUTTON_LABEL.key
        )}
        destroyTooltipOnHide
        mouseEnterDelay={0.5}
      >
        <Button
          id={TRANSLATIONS.CUSTOMER_LIST_TABLE__ACTION_DELETE_BUTTON_LABEL.key}
          type="text"
          icon={<DeleteTwoTone twoToneColor="#ff4d4f" />}
          onClick={openModal}
        />
      </Tooltip>

      <Modal
        id="delete-customer-modal"
        title={
          <h3 id={TRANSLATIONS.CUSTOMER_DELETE__MODAL_TITLE.key}>
            {translate(TRANSLATIONS.CUSTOMER_DELETE__MODAL_TITLE.key)}
            <br />
            "{record.name}"?
          </h3>
        }
        style={{ textAlign: "center" }}
        centered
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        keyboard={true}
        open={+open}
        okText={
          loading
            ? translate(TRANSLATIONS.CUSTOMER_DELETE__MODAL_DELETION_BUTTON_LABEL.key)
            : translate(TRANSLATIONS.CUSTOMER_DELETE__MODAL_CONFIRM_BUTTON_LABEL.key)
        }
        okButtonProps={{
          type: "primary",
          danger: true,
          size: "large",
          htmlType: "submit",
          loading: loading,
          disabled: loading
        }}
        onOk={submit}
        cancelText={
          translate(TRANSLATIONS.CUSTOMER_DELETE__MODAL_CANCEL_BUTTON_LABEL.key)
        }
        cancelButtonProps={{
          type: "lightdark",
          ghost: true,
          size: "large",
          disabled: loading
        }}
        onCancel={closeModal}
      >
        <Divider />
      </Modal>
    </>
  );
}
