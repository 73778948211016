import React, { useEffect, useState } from "react";
import translate from "../../../translate";

import { Button, Card, Input as Imput, Form, Tooltip, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import useQueryParams from "../../../hooks/useQueryParams.hook";

import { TRANSLATIONS } from "../../../translate/translations";
import api from "../../../infra/api";

export default function InsureresFilters({ user, options }) {
  const { setQueryParams } = useQueryParams();

  useEffect(() => {
    console.log("AgentTeamsFilters mounted ...");
    api.insurers
      .getInsurers({
        page: 1,
        size: 100,
        order_by: "name",
      })
      .then((response) => {
        const data = response.data;
        const res = data.results.map((result) => ({
          value: result.id,
          label: result.name,
        }));
        setInsurers(res);
      });
  }, []);

  const [insurers, setInsurers] = useState([]);

  return (
    <Card
      style={{ marginBottom: 10, marginTop: 15 }}
      type="inner"
      title={
        <h3 id={TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_TILE.key}>
          {translate(TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_TILE.key)}
        </h3>
      }
    >
      <Form
        id="agent-teams-filter-form"
        name="agent-teams-filter-form"
        layout="inline"
        autoComplete="off"
        onFinish={() => {
          const qp = {
            page: options.pageNumber,
            size: options.pageSize,
            order_by: options.orderBy,
            name_contains: options.filters.name_contains,
          };
          if (options.filters.insurer) {
            qp.insurer = options.filters.insurer;
          }
          setQueryParams(`/admin/insurers`, qp);
        }}
      >
        <Form.Item initialValue={options.filters.name_contains}>
          <Form.Item hidden={true}>
            <input value={options.filters.name_contains} readOnly={true} />
          </Form.Item>

          <Form.Item>
            <Imput
              allowClear
              id={TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_NAME_LABEL.key}
              name="name_contains"
              addonBefore={translate(
                TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_NAME_LABEL.key
              )}
              value={options.filters.name_contains}
              onChange={(e) =>
                options.setFilters((oldFilters) => {
                  const newFilters = Object.assign({}, oldFilters);
                  newFilters.name_contains = e.target.value;
                  return newFilters;
                })
              }
              size="large"
            />
          </Form.Item>
        </Form.Item>

        <Form.Item initialValue={options.filters.insurer}>
          <Form.Item>
            <Select
              id="insurer"
              placeholder={"Ubezpieczyciel"}
              defaultValue={options.filters.insurer}
              style={{ width: 500 }}
              value={options.filters.insurer}
              onChange={(insurerId) =>
                options.setFilters((oldFilters) => {
                  const newFilters = Object.assign({}, oldFilters);
                  newFilters.insurer = insurerId;
                  return newFilters;
                })
              }
              allowClear
              options={insurers}
              size="large"
            />
          </Form.Item>
        </Form.Item>

        <Form.Item name="search">
          <Tooltip
            title={translate(
              TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL.key
            )}
            destroyTooltipOnHide
            mouseEnterDelay={0.5}
          >
            <Button
              id={TRANSLATIONS.AGENT_TEAM_LIST__FILTERS_SEARCH_BUTTON_LABEL.key}
              htmlType="submit"
              type="button"
              shape="circle"
              icon={<SearchOutlined />}
              size="large"
            />
          </Tooltip>
        </Form.Item>
      </Form>
    </Card>
  );
}
