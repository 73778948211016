import { useEffect, useMemo, useState } from "react";

import { Form, Row, Col, Select } from "antd";

import api from "../../../infra/api";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import debounce from "lodash/debounce";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {object} props.formFields
 * @param {string} props.formFields.BROKER_TEAM
 * @param {string} props.formFields.BROKER_KEEPER
 * @param {string} props.formFields.BROKER_CORRESPONDENT
 * @param {object} props.config
 * @param {object} props.config.brokerTeams
 * @param {object} props.config.setBrokerTeams
 * @param {object} props.config.brokers
 * @param {object} props.config.setBrokers
 * @returns {JSX.Element}
 * */
export default function BrokerTeamFormSection({ form, formHelpers, formFields, config }) {
  const [isBrokerTeamSelected, setIsBrokerTeamSelected] = useState(false);

  useEffect(() => {
    const brokerTeam = form.getFieldValue(formFields.BROKER_TEAM);
    if (!brokerTeam) {
      fetchBrokerTeams({ name_contains: "" });
    }

    if (brokerTeam) {
      setIsBrokerTeamSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBrokerTeams = useMemo(() => debounce((filters) => {
    filters = { ...filters, status: "active" };
    const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`).join("&");
    api.brokerTeams
      .searchBrokerTeam(query)
      .then((result) => {
        config.setBrokerTeams(result.data.results);
      });
  }, 500), [config]);

  const fetchBrokers = (brokerTeam) => {
    api.brokers.searchBrokers(`broker_team=${brokerTeam}`).then((res) => {
      config.setBrokers(
        res.data.results.map((broker) => ({
          value: broker.id,
          label: broker.personal_data.company_name
            ? `${broker.personal_data.company_name} (${broker.personal_data.email})`
            : `${broker.personal_data.first_name} ${broker.personal_data.last_name} (${broker.personal_data.email})`,
        }))
      );
    });
  };

  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form.Item id="broker_service_team">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.BROKER_TEAM}
            name={formFields.BROKER_TEAM}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_CREATE__FORM_BROKER_TEAM_NAME_LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_BROKER_TEAM_NAME_LABEL.key)}
              </label>
            }
            validateStatus={
              formHelpers.errorFields.includes(formFields.BROKER_TEAM)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.BROKER_TEAM)
                ? formHelpers.getFieldErrors(formFields.BROKER_TEAM).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              onSearch={(value) => fetchBrokerTeams({ name_contains: value })}
              size="large"
              placeholder="Wybierz zespol brokerski"
              options={config.brokerTeams.map((brokerTeam) => ({
                value: brokerTeam.id,
                label: brokerTeam.name,
              }))}
              filterOption={handleFilterOption}
              onChange={(value) => {
                form.setFieldsValue({
                  [formFields.BROKER_KEEPER]: null,
                  [formFields.BROKER_CORRESPONDENT]: null,
                });
                if (value) {
                  fetchBrokers(value);
                  setIsBrokerTeamSelected(true);
                }
              }}
              onClear={() => {
                form.setFieldsValue({
                  [formFields.BROKER_TEAM]: null,
                  [formFields.BROKER_KEEPER]: null,
                  [formFields.BROKER_CORRESPONDENT]: null,
                });
                fetchBrokerTeams({ name_contains: "" });
                config.setBrokers([]);
                setIsBrokerTeamSelected(false);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.BROKER_KEEPER}
            name={formFields.BROKER_KEEPER}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_CREATE__FORM_BROKER_TEAM_KEEPER_BROKER_SIDE_LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_BROKER_TEAM_KEEPER_BROKER_SIDE_LABEL.key)}
              </label>
            }
            required={isBrokerTeamSelected}
            rules={[
              {
                validator: (_, value) => {
                  if (isBrokerTeamSelected) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.BROKER_KEEPER,
                      value,
                      "Pole wymagane"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.BROKER_KEEPER)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.BROKER_KEEPER)
                ? formHelpers.getFieldErrors(formFields.BROKER_KEEPER).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isBrokerTeamSelected}
              onClear={() => form.setFieldsValue({ [formFields.BROKER_KEEPER]: null })}
              placeholder="Wybierz Brokera obsługującego"
              options={config.brokers}
              filterOption={handleFilterOption}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.BROKER_CORRESPONDENT}
            name={formFields.BROKER_CORRESPONDENT}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_CREATE__FORM_BROKER_TEAM_CORRESPONDENT_BROKER_SIDE_LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_BROKER_TEAM_CORRESPONDENT_BROKER_SIDE_LABEL.key)}
              </label>
            }
            required={isBrokerTeamSelected}
            rules={[
              {
                validator: (_, value) => {
                  if (isBrokerTeamSelected) {
                    return formHelpers.validateRequiredGuiField(
                      formFields.BROKER_CORRESPONDENT,
                      value,
                      "Pole wymagane"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.BROKER_CORRESPONDENT)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.BROKER_CORRESPONDENT)
                ? formHelpers.getFieldErrors(formFields.BROKER_CORRESPONDENT).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isBrokerTeamSelected}
              onClear={() => form.setFieldsValue({ [formFields.BROKER_CORRESPONDENT]: null })}
              placeholder="Wybierz Brokera korespondencyjnego"
              options={config.brokers}
              filterOption={handleFilterOption}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
}
