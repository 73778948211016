import { useEffect, useMemo, useState } from "react";

import { Form, Row, Col, Select } from "antd";

import api from "../../../infra/api";

import translate from "../../../translate";
import { TRANSLATIONS } from "../../../translate/translations";

import debounce from "lodash/debounce";

/**
 * @param {object} props
 * @param {import("antd").FormInstance} props.form
 * @param {object} props.formHelpers
 * @param {object} props.formHelpers.apiErrors
 * @param {object} props.formHelpers.guiErrors
 * @param {function} props.formHelpers.setApiErrors
 * @param {function} props.formHelpers.setGuiErrors
 * @param {function} props.formHelpers.getFieldErrors
 * @param {function} props.formHelpers.setFieldGuiError
 * @param {function} props.formHelpers.resetFieldGuiError
 * @param {function} props.formHelpers.resetFieldApiError
 * @param {string[]} props.formHelpers.errorFields
 * @param {function} props.formHelpers.validateRequiredGuiField
 * @param {object} props.formFields
 * @param {string} props.formFields.AGENT_TEAM
 * @param {string} props.formFields.AGENT_KEEPER
 * @param {object} props.config
 * @param {object} props.config.agentTeams
 * @param {object} props.config.setAgentTeams
 * @param {object} props.config.agents
 * @param {object} props.config.setAgents
 * @returns {JSX.Element}
 * */
export default function AgencyTeamFormSection({ form, formHelpers, formFields, config }) {
  const [isAgentTeamSelected, setIsAgentTeamSelected] = useState(false);

  useEffect(() => {
    const agentTeam = form.getFieldValue(formFields.AGENT_TEAM);
    if (!agentTeam) {
      fetchAgentTeams({ name_contains: "" });
    }

    if (agentTeam) {
      setIsAgentTeamSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAgentTeams = useMemo(() => debounce((filters) => {
    filters = { ...filters, status: "active" };
    const query = Object.keys(filters).map((key) => `${key}=${filters[key]}`).join("&");
    api.agentTeams
      .searchAgentTeam(query)
      .then((result) => {
        config.setAgentTeams(result.data.results);
      });
  }, 500), [config]);

  const fetchAgents = (agentTeam) => {
    api.agents
      .searchAgents(`agent_team=${agentTeam}`)
      .then((res) => {
        config.setAgents(
          res.data.results.map((agent) => ({
            value: agent.id,
            label: `${agent.personal_data.first_name} ${agent.personal_data.last_name} (${agent.personal_data.email})`,
          }))
        );
      });
  };

  const handleFilterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  }

  return (
    <Form.Item id="agency_service_team">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.AGENT_TEAM}
            name={formFields.AGENT_TEAM}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_CREATE__FORM_AGENT_TEAM_NAME_LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_AGENT_TEAM_NAME_LABEL.key)}
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.AGENT_TEAM,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.AGENT_TEAM) ? "error" : null
            }
            help={
              formHelpers.errorFields.includes(formFields.AGENT_TEAM)
                ? formHelpers.getFieldErrors(formFields.AGENT_TEAM).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              onSearch={(value) => fetchAgentTeams({ name_contains: value })}
              size="large"
              placeholder="Wybierz zespół agencyjny"
              options={config.agentTeams.map((agentTeam) => ({
                value: agentTeam.id,
                label: agentTeam.name,
              }))}
              filterOption={handleFilterOption}
              onChange={(value) => {
                form.setFieldsValue({
                  [formFields.AGENT_KEEPER]: null,
                });
                if (value) {
                  fetchAgents(value);
                  setIsAgentTeamSelected(true);
                }
              }}
              onClear={() => {
                form.setFieldsValue({
                  [formFields.AGENT_TEAM]: null,
                  [formFields.AGENT_KEEPER]: null,
                });
                fetchAgentTeams({ name_contains: "" });
                config.setAgents([]);
                setIsAgentTeamSelected(false);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            id={formFields.AGENT_KEEPER}
            name={formFields.AGENT_KEEPER}
            initialValue={null}
            label={
              <label
                id={TRANSLATIONS.CUSTOMER_CREATE__FORM_AGENT_TEAM_KEEPER_AGENT_SIDE_LABEL.key}
                style={{ fontWeight: "bold" }}
              >
                {translate(TRANSLATIONS.CUSTOMER_CREATE__FORM_AGENT_TEAM_KEEPER_AGENT_SIDE_LABEL.key)}
              </label>
            }
            required
            rules={[
              {
                validator: (_, value) => {
                  return formHelpers.validateRequiredGuiField(
                    formFields.AGENT_KEEPER,
                    value,
                    "Pole wymagane"
                  );
                },
              },
            ]}
            validateStatus={
              formHelpers.errorFields.includes(formFields.AGENT_KEEPER)
                ? "error"
                : null
            }
            help={
              formHelpers.errorFields.includes(formFields.AGENT_KEEPER)
                ? formHelpers.getFieldErrors(formFields.AGENT_KEEPER).join(", ")
                : null
            }
          >
            <Select
              allowClear
              showSearch
              size="large"
              disabled={!isAgentTeamSelected}
              onClear={() => form.setFieldsValue({ [formFields.AGENT_KEEPER]: null })}
              placeholder="Wybierz Agenta obsługującego"
              options={config.agents}
              filterOption={handleFilterOption}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};
